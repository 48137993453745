// Copyright 2021
// ThatWorks.xyz Limited

import { useMutation, useQuery } from '@apollo/client';
import { Colors } from '@thatworks/colors';
import { QueryParams } from '@thatworks/shared-frontend/pages';
import { Box, Layer, Spinner, Text } from 'grommet';
import { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { gql } from '../../../../__generated__';
import { WorkspaceUserAbilities } from '../../../../__generated__/graphql';
import { CtaButtonSpinnerV2 } from '../../../../components/CtaButtonSpinner';
import { useTelemetryContext } from '../../../../components/TelemetryContext';
import { useUserStateContext } from '../../../../components/UserContext';
import { MagicComposerEditPost } from '../magic-composer/components/MagicComposerEditPost';
import { WorkspacePostErrorBox } from './components/WorkspacePostErrorBox';
import { WorkspacePost } from './WorkspacePost';
import { WorkspacePostExpansionModal } from './WorkspacePostExpansion';

const GET_WORKSPACE_POSTS = gql(/* GraphQL */ `
    query WorkspacePosts($organizationId: ID!, $workspaceId: ID!, $offset: Int, $limit: Int) {
        workspacePosts(organizationId: $organizationId, workspaceId: $workspaceId, offset: $offset, limit: $limit) {
            posts {
                id
                content
                title
                userAbilities
                creators {
                    name
                }
                created
                updated
                visibility
                template {
                    templateId
                    templateName
                    automation {
                        fromDateIso
                        toDateIso
                    }
                }
            }
            permissionError
        }
    }
`);

const DELETE_POST = gql(/* GraphQL */ `
    mutation WorkspacePostDelete($organizationId: ID!, $postId: ID!) {
        workspacePostDelete(organizationId: $organizationId, postId: $postId)
    }
`);

const WORKSPACE_POST_FETCH_LIMIT = 5;

export function WorkspacePosts(props: {
    workspaceId: string;
    organizationId: string;
    workspaceUserAbilities: WorkspaceUserAbilities[];
    createPost: () => Promise<void>;
}): JSX.Element {
    const { logger } = useTelemetryContext();
    const { postErrorMessage } = useUserStateContext();
    const { data, loading, fetchMore } = useQuery(GET_WORKSPACE_POSTS, {
        fetchPolicy: 'network-only',
        variables: {
            organizationId: props.organizationId,
            workspaceId: props.workspaceId,
            offset: 0,
            limit: WORKSPACE_POST_FETCH_LIMIT,
        },
        onCompleted(data) {
            if (data.workspacePosts.posts.length === WORKSPACE_POST_FETCH_LIMIT) {
                setFetchButtonEnabled(true);
            }
        },
        onError: (err) => {
            postErrorMessage({ title: `Error`, shortDesc: 'Failed to get posts' });
            logger.error(err.message);
        },
    });
    const [deletePost] = useMutation(DELETE_POST, {
        onError: (err) => {
            postErrorMessage({ title: `Error`, shortDesc: 'Failed to delete post' });
            logger.error(err.message);
        },
    });
    const [contentToEdit, setContentToEdit] = useState<{ title: string; content: string; postId: string } | undefined>(
        undefined,
    );
    const [searchParams, setSearchParams] = useSearchParams();
    const [expandedPostId, setExpandedPostId] = useState<string | undefined>();
    const [fetchButtonEnabled, setFetchButtonEnabled] = useState(false);

    useEffect(() => {
        const expand = searchParams.get(QueryParams.PostExpansion);
        if (expand) {
            setExpandedPostId(expand);
        }
    }, [searchParams]);

    const deletePostWrapper = useCallback(
        async (postId: string) => {
            await deletePost({
                variables: {
                    organizationId: props.organizationId,
                    postId,
                },
                update: (cache) => {
                    const normalizedId = cache.identify({
                        id: postId,
                        __typename: 'WorkspacePost',
                    });
                    cache.evict({
                        id: normalizedId,
                    });
                    cache.gc();
                },
            });
        },
        [deletePost, props.organizationId],
    );

    if (loading || !data) {
        return (
            <Box width="min-content" alignSelf="center">
                <Spinner />
            </Box>
        );
    }

    return (
        <Box height={{ min: 'fit-content' }} gap="small">
            {data.workspacePosts.permissionError && (
                <WorkspacePostErrorBox
                    message={
                        '😔 You have no permissions to view posts in this topic. Please contact the admin of this topic.'
                    }
                />
            )}
            {!data.workspacePosts.permissionError && data.workspacePosts.posts.length === 0 && (
                <Box
                    pad="small"
                    background={{ color: Colors.background_back }}
                    round={'10px'}
                    direction="row"
                    gap="xsmall"
                    align="center"
                    elevation="xsmall"
                >
                    {!props.workspaceUserAbilities.includes(WorkspaceUserAbilities.CanCreatePost) && (
                        <Box direction="row" gap="xsmall" align="center">
                            <Text>No posts yet!</Text>
                        </Box>
                    )}
                    {props.workspaceUserAbilities.includes(WorkspaceUserAbilities.CanCreatePost) && (
                        <Box direction="row" gap="xsmall" align="center">
                            <Text>No posts yet. Create your first post in this topic!</Text>
                            <CtaButtonSpinnerV2
                                label="New Post"
                                style={{ padding: '1px 14px' }}
                                onClick={props.createPost}
                            />
                        </Box>
                    )}
                </Box>
            )}
            {expandedPostId && (
                <WorkspacePostExpansionModal
                    workspaceId={props.workspaceId}
                    postId={expandedPostId}
                    onClose={() => {
                        searchParams.delete(QueryParams.PostExpansion);
                        setSearchParams(searchParams);
                        setExpandedPostId(undefined);
                    }}
                    onDelete={async () => {
                        await deletePostWrapper(expandedPostId);
                    }}
                    onEdit={(title, content) => {
                        setContentToEdit({ title, content, postId: expandedPostId });
                    }}
                />
            )}
            {data.workspacePosts.posts.length > 0 &&
                data.workspacePosts.posts.map((post) => (
                    <WorkspacePost
                        workspaceId={props.workspaceId}
                        post={post}
                        key={post.id}
                        onDelete={async () => {
                            await deletePostWrapper(post.id);
                        }}
                        onEdit={() => {
                            setContentToEdit({ title: post.title, content: post.content, postId: post.id });
                        }}
                    />
                ))}

            {fetchButtonEnabled && (
                <CtaButtonSpinnerV2
                    label="Fetch more Posts"
                    style={{ padding: '1px 14px' }}
                    onClick={async () => {
                        await fetchMore({
                            variables: { offset: data.workspacePosts.posts.length },
                            updateQuery(previousData, { fetchMoreResult, variables: { offset } }) {
                                // Slicing is necessary because the existing data is
                                // immutable, and frozen in development.
                                const updatedPosts = previousData.workspacePosts.posts.slice(0);
                                for (let i = 0; i < fetchMoreResult.workspacePosts.posts.length; ++i) {
                                    updatedPosts[offset + i] = fetchMoreResult.workspacePosts.posts[i];
                                }

                                if (updatedPosts.length < WORKSPACE_POST_FETCH_LIMIT + offset) {
                                    setFetchButtonEnabled(false);
                                }

                                return {
                                    workspacePosts: {
                                        permissionError: previousData.workspacePosts.permissionError,
                                        posts: updatedPosts,
                                    },
                                };
                            },
                        });
                    }}
                />
            )}

            {/* Edit post modal */}
            {contentToEdit && (
                <Layer
                    onEsc={() => setContentToEdit(undefined)}
                    onClickOutside={() => setContentToEdit(undefined)}
                    background={{ color: 'unset' }}
                >
                    <MagicComposerEditPost
                        title={contentToEdit.title}
                        content={contentToEdit.content}
                        postId={contentToEdit.postId}
                        onCloseModal={() => setContentToEdit(undefined)}
                    />
                </Layer>
            )}
        </Box>
    );
}
