// Copyright 2021
// ThatWorks.xyz Limited

import { Colors } from '@thatworks/colors';
import { Gates } from '@thatworks/shared-frontend/metrics';
import { Box, CheckBox, Drop, Grid, RadioButtonGroup, Text, TextArea } from 'grommet';
import { Close } from 'grommet-icons';
import isEqual from 'lodash.isequal';
import { useMemo, useRef, useState } from 'react';
import { useGate } from 'statsig-react';
import {
    ChangesSummaryField,
    CommentsSummarizationLength,
    SummarizationSettingsFormat,
    SummarizationSettingsInput,
    SummarizationStyle,
} from '../../../../../__generated__/graphql';
import { CtaButtonSpinnerV2, CtaTextButtonSpinner } from '../../../../../components/CtaButtonSpinner';
import { IconButtonV2 } from '../../../../../components/IconButton';
import { ToolbarButton } from '../../../../../components/prosemirror/ToolbarButton';
import { Palette } from '../../../../../icons/Palette';
import { FontFamily } from '../../../../../theme';

const SummarizationStyleArray = [
    SummarizationStyle.Highlights,
    SummarizationStyle.ReleaseNotes,
    SummarizationStyle.Discussions,
];
const SummarizationSettingsFormatArray = [
    SummarizationSettingsFormat.Highlights,
    SummarizationSettingsFormat.DetailedList,
];
const CommentsSummarizationLengthArray = [CommentsSummarizationLength.Short, CommentsSummarizationLength.Long];

function getLabelForSummarizationStyle(style: SummarizationStyle): string {
    switch (style) {
        case SummarizationStyle.Highlights:
            return 'Highlights';
        case SummarizationStyle.ReleaseNotes:
            return 'Release Notes';
        case SummarizationStyle.Discussions:
            return 'Discussions';
    }
}

function getLabelForSummarizationSettingsFormat(format: SummarizationSettingsFormat): string {
    switch (format) {
        case SummarizationSettingsFormat.DetailedList:
            return 'Themes';
        case SummarizationSettingsFormat.Highlights:
            return 'Overview';
    }
}

function getLabelForCommentsSummarizationLength(length: CommentsSummarizationLength): string {
    switch (length) {
        case CommentsSummarizationLength.Short:
            return 'Brief';
        case CommentsSummarizationLength.Long:
            return 'Standard';
    }
}

enum LevelOfDetailOption {
    Brief = 'Brief',
    Standard = 'Standard',
    Everything = 'Everything',
}

const LOD_STANDARD = 0.7;
const LOD_EVERYTHING = 1;

function getLevelOfDetailFromOption(option: LevelOfDetailOption): number {
    // using these presets so the changes are more obviously to users
    switch (option) {
        case LevelOfDetailOption.Brief:
            return 0;
        case LevelOfDetailOption.Standard:
            return LOD_STANDARD;
        case LevelOfDetailOption.Everything:
            return LOD_EVERYTHING;
    }
}

function getOptionFromLevelOfDetail(levelOfDetail: number): LevelOfDetailOption {
    if (levelOfDetail < LOD_STANDARD) {
        return LevelOfDetailOption.Brief;
    }
    if (levelOfDetail < LOD_EVERYTHING) {
        return LevelOfDetailOption.Standard;
    }
    return LevelOfDetailOption.Everything;
}

export function getSummarySettingsForSummaryStyle(style: SummarizationStyle): SummarizationSettingsInput {
    switch (style) {
        case SummarizationStyle.Highlights:
            return {
                changesSummaryEnabled: true,
                changesLevelOfDetail: 0,
                changesSummaryFormat: SummarizationSettingsFormat.Highlights,
                changesSummaryFields: [
                    ChangesSummaryField.Changes,
                    ChangesSummaryField.Description,
                    ChangesSummaryField.Comments,
                ],
                changesAlsoGroupByItemType: true,
                customFormattingPrompt: undefined,

                commentsSummaryEnabled: true,
                commentsSummaryLength: CommentsSummarizationLength.Short,
            };
        case SummarizationStyle.ReleaseNotes:
            return {
                changesSummaryEnabled: true,
                changesLevelOfDetail: 1,
                changesSummaryFormat: SummarizationSettingsFormat.DetailedList,
                changesSummaryFields: [ChangesSummaryField.Description],
                changesAlsoGroupByItemType: false,
                customFormattingPrompt: undefined,

                commentsSummaryEnabled: false,
                commentsSummaryLength: CommentsSummarizationLength.Short,
            };
        case SummarizationStyle.Discussions:
            return {
                changesSummaryEnabled: false,
                changesLevelOfDetail: 0,
                changesSummaryFormat: SummarizationSettingsFormat.Highlights,
                changesSummaryFields: [],
                changesAlsoGroupByItemType: false,
                customFormattingPrompt: undefined,

                commentsSummaryEnabled: true,
                commentsSummaryLength: CommentsSummarizationLength.Short,
            };
    }
}

function areSettingsEqual(a: SummarizationSettingsInput, b: SummarizationSettingsInput): boolean {
    const aCopy: SummarizationSettingsInput = { ...a };
    const bCopy: SummarizationSettingsInput = { ...b };
    if (aCopy.customFormattingPrompt === '') {
        aCopy.customFormattingPrompt = undefined;
    }
    if (bCopy.customFormattingPrompt === '') {
        bCopy.customFormattingPrompt = undefined;
    }
    aCopy.changesSummaryFields.sort();
    bCopy.changesSummaryFields.sort();
    return isEqual(aCopy, bCopy);
}

export function CustomSummarizationSettingsView(props: {
    customSettings: SummarizationSettingsInput;
    onCustomSettingsChange: (customSettings: SummarizationSettingsInput) => void;
    dataLoading: boolean;
}): JSX.Element {
    const [changesSummaryEnabled, setChangesSummaryEnabled] = useState(props.customSettings.changesSummaryEnabled);
    const [changesAlsoGroupByItemType, setChangesAlsoGroupByItemType] = useState<boolean>(
        props.customSettings.changesAlsoGroupByItemType,
    );
    const [changesLevelOfDetail, setChangesLevelOfDetail] = useState<number>(props.customSettings.changesLevelOfDetail);
    const [changesSummaryFormat, setChangesSummaryFormat] = useState<SummarizationSettingsFormat>(
        props.customSettings.changesSummaryFormat,
    );
    const [changesSummaryFields, setChangesSummaryFields] = useState(props.customSettings.changesSummaryFields);

    const customFormattingGate = useGate(Gates.CustomFormattingViaPrompt);
    const [customFormattingPrompt, setCustomFormattingPrompt] = useState<string | undefined>(
        props.customSettings.customFormattingPrompt || undefined,
    );

    const [commentsShowSummary, setCommentsShowSummary] = useState<boolean>(
        props.customSettings.commentsSummaryEnabled,
    );
    const [commentsSummaryLength, setCommentsSummaryLength] = useState(props.customSettings.commentsSummaryLength);

    const customSettings = useMemo(() => {
        const r: SummarizationSettingsInput = {
            changesSummaryEnabled: changesSummaryEnabled,
            changesLevelOfDetail: changesLevelOfDetail,
            changesSummaryFields: changesSummaryFields,
            changesSummaryFormat: changesSummaryFormat,
            changesAlsoGroupByItemType: changesAlsoGroupByItemType,

            commentsSummaryEnabled: commentsShowSummary,
            commentsSummaryLength: commentsSummaryLength,

            customFormattingPrompt: customFormattingPrompt,
        };
        return r;
    }, [
        changesAlsoGroupByItemType,
        changesLevelOfDetail,
        changesSummaryEnabled,
        changesSummaryFields,
        changesSummaryFormat,
        commentsShowSummary,
        commentsSummaryLength,
        customFormattingPrompt,
    ]);

    return (
        <Box gap="xsmall">
            <Text size="16px" style={{ fontFamily: FontFamily.Heading }} weight="bold">
                Customize Preset
            </Text>
            <Box overflow={{ vertical: 'auto' }}>
                <Box gap="xsmall" height={{ min: 'max-content' }}>
                    <Box
                        gap="xsmall"
                        border={{ color: Colors.light_5 }}
                        round="8px"
                        background={{ color: Colors.background_back }}
                    >
                        <Box
                            pad={{ horizontal: 'xsmall', vertical: 'xxsmall' }}
                            border={changesSummaryEnabled ? { color: Colors.light_5, side: 'bottom' } : undefined}
                        >
                            <CheckBox
                                label={
                                    <Text size="16px" style={{ fontFamily: FontFamily.Heading }} weight="bold">
                                        ✨ Summarize Changes
                                    </Text>
                                }
                                checked={changesSummaryEnabled}
                                onChange={(event) => {
                                    setChangesSummaryEnabled(event.target.checked);
                                }}
                                disabled={props.dataLoading}
                            />
                        </Box>
                        {changesSummaryEnabled && (
                            <Box pad={{ horizontal: 'xsmall', bottom: 'xsmall' }} gap="xsmall">
                                <Box gap="xxsmall">
                                    <Text
                                        size="14px"
                                        style={{ opacity: props.dataLoading ? 0.5 : undefined }}
                                        weight={'bolder'}
                                    >
                                        Summary Type
                                    </Text>
                                    <RadioButtonGroup
                                        disabled={props.dataLoading}
                                        name="Summary Type"
                                        options={SummarizationSettingsFormatArray.map((o) => ({
                                            value: o,
                                            label: getLabelForSummarizationSettingsFormat(o),
                                        }))}
                                        direction="row"
                                        value={changesSummaryFormat}
                                        onChange={(event) => {
                                            setChangesSummaryFormat(event.target.value as SummarizationSettingsFormat);
                                        }}
                                    />
                                </Box>
                                <Box gap="xxsmall">
                                    <Text
                                        size="14px"
                                        weight={'bolder'}
                                        style={{ opacity: props.dataLoading ? 0.5 : undefined }}
                                    >
                                        Length
                                    </Text>
                                    <Box direction="row" align="center">
                                        <RadioButtonGroup
                                            disabled={props.dataLoading}
                                            name="Length"
                                            options={Object.values(LevelOfDetailOption)}
                                            direction="row"
                                            value={getOptionFromLevelOfDetail(changesLevelOfDetail)}
                                            onChange={(event) => {
                                                setChangesLevelOfDetail(
                                                    getLevelOfDetailFromOption(
                                                        event.target.value as LevelOfDetailOption,
                                                    ),
                                                );
                                            }}
                                        />
                                    </Box>
                                </Box>
                                <Box gap="xxsmall">
                                    <Text
                                        size="14px"
                                        weight={'bolder'}
                                        style={{ opacity: props.dataLoading ? 0.5 : undefined }}
                                    >
                                        Summarize Using
                                    </Text>
                                    <Box direction="row" gap="xsmall">
                                        <CheckBox label="Title" checked={true} disabled={true} />
                                        <CheckBox
                                            label="Description"
                                            checked={changesSummaryFields.includes(ChangesSummaryField.Description)}
                                            onChange={(e) => {
                                                if (e.target.checked) {
                                                    setChangesSummaryFields(
                                                        Array.from(
                                                            new Set([
                                                                ...changesSummaryFields,
                                                                ChangesSummaryField.Description,
                                                            ]),
                                                        ),
                                                    );
                                                } else {
                                                    setChangesSummaryFields(
                                                        changesSummaryFields.filter(
                                                            (f) => f !== ChangesSummaryField.Description,
                                                        ),
                                                    );
                                                }
                                            }}
                                            disabled={props.dataLoading}
                                        />
                                        <CheckBox
                                            label="Changes"
                                            checked={changesSummaryFields.includes(ChangesSummaryField.Changes)}
                                            onChange={(e) => {
                                                if (e.target.checked) {
                                                    setChangesSummaryFields(
                                                        Array.from(
                                                            new Set([
                                                                ...changesSummaryFields,
                                                                ChangesSummaryField.Changes,
                                                            ]),
                                                        ),
                                                    );
                                                } else {
                                                    setChangesSummaryFields(
                                                        changesSummaryFields.filter(
                                                            (f) => f !== ChangesSummaryField.Changes,
                                                        ),
                                                    );
                                                }
                                            }}
                                            disabled={props.dataLoading}
                                        />
                                        <CheckBox
                                            label="Discussions"
                                            checked={changesSummaryFields.includes(ChangesSummaryField.Comments)}
                                            onChange={(e) => {
                                                if (e.target.checked) {
                                                    setChangesSummaryFields(
                                                        Array.from(
                                                            new Set([
                                                                ...changesSummaryFields,
                                                                ChangesSummaryField.Comments,
                                                            ]),
                                                        ),
                                                    );
                                                } else {
                                                    setChangesSummaryFields(
                                                        changesSummaryFields.filter(
                                                            (f) => f !== ChangesSummaryField.Comments,
                                                        ),
                                                    );
                                                }
                                            }}
                                            disabled={props.dataLoading}
                                        />
                                    </Box>
                                </Box>
                                <Box gap="xxsmall">
                                    <Text
                                        size="14px"
                                        weight={'bolder'}
                                        style={{ opacity: props.dataLoading ? 0.5 : undefined }}
                                    >
                                        Structure
                                    </Text>
                                    <CheckBox
                                        disabled={props.dataLoading}
                                        label="Group by item type (e.g. bugs, tasks, docs)"
                                        checked={changesAlsoGroupByItemType}
                                        color={Colors.accent_3}
                                        onChange={(event) => {
                                            setChangesAlsoGroupByItemType(event.target.checked);
                                        }}
                                    />
                                </Box>
                            </Box>
                        )}
                    </Box>
                    <Box
                        gap="xsmall"
                        border={{ color: Colors.light_5 }}
                        round="8px"
                        background={{ color: Colors.background_back }}
                    >
                        <Box
                            pad={{ horizontal: 'xsmall', vertical: 'xxsmall' }}
                            border={commentsShowSummary ? { color: Colors.light_5, side: 'bottom' } : undefined}
                        >
                            <CheckBox
                                label={
                                    <Text size="16px" style={{ fontFamily: FontFamily.Heading }} weight="bold">
                                        💬 Summarize Discussions
                                    </Text>
                                }
                                checked={commentsShowSummary}
                                onChange={(event) => {
                                    setCommentsShowSummary(event.target.checked);
                                }}
                                disabled={props.dataLoading}
                            />
                        </Box>
                        {commentsShowSummary && (
                            <Box gap="xxsmall" pad={{ horizontal: 'xsmall', bottom: 'xsmall' }}>
                                <Text
                                    size="14px"
                                    style={{ opacity: props.dataLoading ? 0.5 : undefined }}
                                    weight={'bolder'}
                                >
                                    Length
                                </Text>
                                <RadioButtonGroup
                                    disabled={props.dataLoading}
                                    name="Display"
                                    options={CommentsSummarizationLengthArray.map((o) => ({
                                        value: o,
                                        label: getLabelForCommentsSummarizationLength(o),
                                    }))}
                                    direction="row"
                                    value={commentsSummaryLength}
                                    onChange={(event) => {
                                        setCommentsSummaryLength(event.target.value as CommentsSummarizationLength);
                                    }}
                                />
                            </Box>
                        )}
                    </Box>
                    {customFormattingGate.value && (
                        <Box
                            gap="xsmall"
                            border={{ color: Colors.light_5 }}
                            round="8px"
                            background={{ color: Colors.background_back }}
                        >
                            <Box
                                pad={{ horizontal: 'xsmall', vertical: 'xxsmall' }}
                                border={{ color: Colors.light_5, side: 'bottom' }}
                            >
                                <Text
                                    size="16px"
                                    style={{
                                        fontFamily: FontFamily.Heading,
                                        opacity: props.dataLoading ? 0.5 : undefined,
                                    }}
                                    weight="bold"
                                >
                                    Custom Formatting Prompt
                                </Text>
                            </Box>
                            <Box gap="xxsmall" pad={{ horizontal: 'xsmall', bottom: 'xsmall' }}>
                                <TextArea
                                    maxLength={500}
                                    disabled={props.dataLoading}
                                    value={customFormattingPrompt}
                                    style={{ minHeight: '100px', borderColor: Colors.light_5 }}
                                    onChange={(event) => {
                                        setCustomFormattingPrompt(event.target.value);
                                    }}
                                    placeholder={`Examples:\nGroup by bugs and features\nRewrite into a short paragraph`}
                                />
                            </Box>
                        </Box>
                    )}
                </Box>
            </Box>

            <Box direction="row" height={{ min: 'max-content' }}>
                <CtaButtonSpinnerV2
                    disabled={props.dataLoading || areSettingsEqual(customSettings, props.customSettings)}
                    style={{ width: '100px' }}
                    label="Apply"
                    onClick={async () => {
                        props.onCustomSettingsChange(customSettings);
                    }}
                    showSpinner={props.dataLoading}
                />
            </Box>
        </Box>
    );
}

export function SummaryStyleButton(props: {
    customSettings: SummarizationSettingsInput;
    onCustomSettingsChange: (customSettings: SummarizationSettingsInput) => void;
    timelineLoading: boolean;
    summaryLoading: boolean;
    disabled: boolean;
}): JSX.Element {
    const [buttonActive, setButtonActive] = useState(false);
    const ref = useRef<HTMLDivElement>(null);

    const styleFromSettings = useMemo(() => {
        if (areSettingsEqual(props.customSettings, getSummarySettingsForSummaryStyle(SummarizationStyle.Highlights))) {
            return SummarizationStyle.Highlights;
        }
        if (
            areSettingsEqual(props.customSettings, getSummarySettingsForSummaryStyle(SummarizationStyle.ReleaseNotes))
        ) {
            return SummarizationStyle.ReleaseNotes;
        }
        if (areSettingsEqual(props.customSettings, getSummarySettingsForSummaryStyle(SummarizationStyle.Discussions))) {
            return SummarizationStyle.Discussions;
        }
        return undefined;
    }, [props.customSettings]);

    return (
        <Box ref={ref}>
            <ToolbarButton
                active={true}
                icon={Palette}
                label="Summary Style"
                onClick={async () => {
                    setButtonActive(!buttonActive);
                }}
                disabled={props.timelineLoading || props.disabled}
            />
            {buttonActive && (
                <Drop
                    plain
                    target={ref.current || undefined}
                    align={{ top: 'bottom', right: 'right' }}
                    pad={'xxsmall'}
                    onClickOutside={() => {
                        setButtonActive(false);
                    }}
                >
                    <Box
                        width={{ width: '500px', max: '700px' }}
                        background={{ color: Colors.background_front }}
                        border={{ color: Colors.border_dark, size: '1px' }}
                        pad={'xsmall'}
                        round={'10px'}
                        elevation="xsmall"
                        gap="xsmall"
                    >
                        <Box direction="row" justify="between" fill="horizontal" height={{ min: 'max-content' }}>
                            <Text size="16px" style={{ fontFamily: FontFamily.Heading }} weight="bold">
                                Summary Style Preset
                            </Text>
                            <IconButtonV2
                                icon={(hover) => <Close size="18px" color={hover ? Colors.brand : undefined} />}
                                reverse
                                onClick={() => setButtonActive(false)}
                                alignSelf="end"
                            />
                        </Box>
                        <Grid columns={{ count: 2, size: '170px' }} gap="xsmall">
                            {SummarizationStyleArray.map((style, si) => (
                                <CtaTextButtonSpinner
                                    key={`style-${si}`}
                                    label={getLabelForSummarizationStyle(style)}
                                    getBoxProps={() => {
                                        return {
                                            pad: { horizontal: 'xsmall' },
                                            border: {
                                                color: Colors.accent_3,
                                                size: '1px',
                                            },
                                            height: '25px',
                                            justify: 'center',
                                            round: '8px',
                                            background: {
                                                color:
                                                    styleFromSettings === style
                                                        ? Colors.background_back
                                                        : Colors.background_front,
                                            },
                                            elevation: 'xsmall',
                                        };
                                    }}
                                    getTextProps={(hover) => {
                                        return {
                                            color: hover
                                                ? Colors.brand
                                                : styleFromSettings === style
                                                ? Colors.black
                                                : Colors.dark_6,
                                            size: '14px',
                                        };
                                    }}
                                    onClick={async () => {
                                        props.onCustomSettingsChange(getSummarySettingsForSummaryStyle(style));
                                    }}
                                />
                            ))}
                        </Grid>
                        <CustomSummarizationSettingsView
                            dataLoading={props.timelineLoading || props.summaryLoading}
                            customSettings={props.customSettings}
                            onCustomSettingsChange={props.onCustomSettingsChange}
                            key={styleFromSettings}
                        />
                    </Box>
                </Drop>
            )}
        </Box>
    );
}
