// Copyright 2021
// ThatWorks.xyz Limited

import { Colors } from '@thatworks/colors';
import {
    ConnectorName,
    ConnectPreAuthInput,
    getFormattedConnectorName,
    PreAuthUserInputFieldType,
} from '@thatworks/connector-api';
import { Events, Gates } from '@thatworks/shared-frontend/metrics';
import { Box, Button, CheckBox, Grid, Image, Layer, Spinner, Text } from 'grommet';
import { Alert, StatusGood, StatusWarning } from 'grommet-icons';
import { useEffect, useMemo, useState } from 'react';
import { useGate, useStatsigLogEffect } from 'statsig-react';
import {
    ConnectorAvailableState,
    ConnectorConfigField,
    ConnectorConfigPreAuthFieldType,
    ConnectorConnectedState,
    ConnectorPermissionStatus,
    OrgWideConnectorConnectedState,
} from '../__generated__/graphql';
import { ConnectedConnectorButton } from '../pages/app/pages/connect/ConnectedConnectorButton';
import { conditionally } from '../shared/conditionals';
import { getGrommetTheme } from '../theme';
import { ReactComponent as AtlassianIcon } from './../icons/atlassian.svg';
import { ReactComponent as ClickupIcon } from './../icons/clickup.svg';
import { ReactComponent as FigmaIcon } from './../icons/figma.svg';
import { ReactComponent as GoogleSigninIcon } from './../icons/google_signin_light_normal_web.svg';
import { ReactComponent as MiroIcon } from './../icons/miro.svg';
import { ReactComponent as MondayIcon } from './../icons/monday.svg';
import { ReactComponent as NotionTextIcon } from './../icons/notion_with_text.svg';
import { ConnectorIconSmall } from './ConnectorIcon';
import CtaButtonSpinner from './CtaButtonSpinner';
import { ErrorMessage, ServerCannotConnectMessage } from './ErrorToast';
import { OrgWideConnectionButton } from './OrgWideConnectionButton';
import { StringEntry, StringEntryCard } from './StringEntryCard';
import { useTelemetryContext } from './TelemetryContext';
import { TwHeading } from './TwHeading';
import { useUserStateContext } from './UserContext';

const theme = getGrommetTheme();

type CombinedConnectorStates =
    | { type: 'connected'; state: ConnectorConnectedState }
    | { type: 'available'; state: ConnectorAvailableState };

export function asConnectedConnector(c: CombinedConnectorStates): ConnectorConnectedState | undefined {
    if (c.type === 'connected') {
        return c.state;
    }
    return undefined;
}

export function asAvailableConnector(c: CombinedConnectorStates): ConnectorAvailableState | undefined {
    if (c.type === 'available') {
        return c.state;
    }
    return undefined;
}

function DisconnectionWarning(props: { connector: ConnectorName; onDisconnect: () => Promise<void> }): JSX.Element {
    const [showSpinner, setShowSpinner] = useState(false);
    useStatsigLogEffect(Events.FrontendRemoveConnectionModalOpened, props.connector);

    return (
        <Box gap="xxsmall">
            <Box direction="row" gap="xxsmall">
                <Text weight="bold">Disconnecting will delete data in your Recaps</Text>
            </Box>
            <Button
                onClick={async () => {
                    setShowSpinner(true);
                    await props.onDisconnect();
                    setShowSpinner(false);
                }}
                plain
                disabled={showSpinner}
            >
                {({ hover, disabled }) => (
                    <Box
                        background="status-warning"
                        pad="xxsmall"
                        round="8px"
                        align="center"
                        border={{ color: 'black', size: hover ? '4px' : '2px' }}
                        margin={hover ? '2px' : '4px'}
                        direction="row"
                        gap="xxsmall"
                        justify="center"
                    >
                        {showSpinner && <Spinner size="small" />}
                        {!showSpinner && <Alert size="16px" color="black" />}
                        <Text size="medium">Disconnect And Delete</Text>
                    </Box>
                )}
            </Button>
        </Box>
    );
}

function getOverlayTitleToMeetBrandGuidelines(connectorName: ConnectorName): string {
    return connectorName === ConnectorName.GOOGLE ? 'Sign in with Google' : getFormattedConnectorName(connectorName);
}

function getConnectText(name: ConnectorName): { text: string; decoration?: 'bold' }[] {
    switch (name) {
        case ConnectorName.GOOGLE:
            return [
                { text: 'When you connect your account, grant permissions by' },
                { text: 'ticking all the checkboxes.', decoration: 'bold' },
            ];
        case ConnectorName.ATLASSIAN:
            return [
                { text: 'When you connect your account,' },
                { text: 'click "Accept"', decoration: 'bold' },
                { text: 'to grant permissions.' },
            ];
        case ConnectorName.CLICKUP:
            return [
                { text: 'When you connect your account,' },
                { text: 'choose the Workspace', decoration: 'bold' },
                { text: 'you want to use.' },
            ];
        case ConnectorName.MONDAY:
            return [];
        case ConnectorName.NOTION:
            return [
                { text: 'When you connect your account,' },
                { text: 'choose your whole Workspace', decoration: 'bold' },
                { text: 'for optimal results.' },
            ];
        case ConnectorName.FIGMA:
            return [
                { text: 'Nice! When you connect your account,' },
                { text: 'click "Allow access"', decoration: 'bold' },
                { text: 'to grant permissions.' },
            ];
        case ConnectorName.MIRO:
            return [
                { text: 'When you connect your account,' },
                { text: 'select the team', decoration: 'bold' },
                { text: 'you want to track.' },
            ];
        case ConnectorName.ASANA:
            return [
                { text: 'When you connect your account,' },
                { text: 'click "Allow"', decoration: 'bold' },
                { text: 'to grant permissions.' },
            ];
        case ConnectorName.TOGGL:
            return [];
        case ConnectorName.LINEAR:
            return [
                { text: 'When you connect your account,' },
                { text: 'click "Authorize"', decoration: 'bold' },
                { text: 'to grant permissions.' },
            ];
        case ConnectorName.SLACK:
            return [
                { text: 'When you connect your account,' },
                { text: 'click "Allow"', decoration: 'bold' },
                { text: 'to grant permissions.' },
            ];
        case ConnectorName.GITHUB:
            return [
                { text: 'When you connect your account,' },
                { text: 'click "Allow"', decoration: 'bold' },
                { text: 'to grant permissions.' },
            ];
        case ConnectorName.BITBUCKET:
            return [
                { text: 'When you connect your account,' },
                { text: 'click "Allow"', decoration: 'bold' },
                { text: 'to grant permissions.' },
            ];
        case ConnectorName.HUBSPOT:
            return [
                { text: 'When you connect your account,' },
                { text: 'click "Connect app"', decoration: 'bold' },
                { text: 'to grant permissions.' },
            ];
        default:
            throw new Error(`No connect text specified ${name}`);
    }
}

function getClickGif(name: ConnectorName) {
    const cdnBase = process.env.REACT_APP_CDN_URL || '';
    switch (name) {
        case ConnectorName.GOOGLE:
            return `${cdnBase}/images/google_click.gif`;
        case ConnectorName.BITBUCKET:
            return `${cdnBase}/images/bitbucket_click.gif`;
        case ConnectorName.ATLASSIAN:
            return `${cdnBase}/images/atlassian_click.gif`;
        case ConnectorName.CLICKUP:
            return `${cdnBase}/images/clickup_click.gif`;
        case ConnectorName.MONDAY:
            return `${cdnBase}/images/google_click.gif`;
        case ConnectorName.NOTION:
            return `${cdnBase}/images/notion_click.gif`;
        case ConnectorName.FIGMA:
            return `${cdnBase}/images/figma_click.gif`;
        case ConnectorName.MIRO:
            return `${cdnBase}/images/miro_click.gif`;
        case ConnectorName.ASANA:
            return `${cdnBase}/images/asana_click.gif`;
        case ConnectorName.LINEAR:
            return `${cdnBase}/images/linear_click.gif`;
        case ConnectorName.SLACK:
            return `${cdnBase}/images/slack_click.gif`;
        case ConnectorName.GITHUB:
            return `${cdnBase}/images/github_click.gif`;
        case ConnectorName.HUBSPOT:
            return `${cdnBase}/images/hubspot_click.gif`;
        default:
            throw new Error(`No click gif specified: ${name}`);
    }
}

function getPreAuthGif(name: ConnectorName) {
    const cdnBase = process.env.REACT_APP_CDN_URL || '';
    switch (name) {
        case ConnectorName.FIGMA:
            return `${cdnBase}/images/figma_team_selection_v2.gif`;
        case ConnectorName.TOGGL:
            return `${cdnBase}/images/toggl_api_token.gif`;
        default:
            return undefined;
    }
}

function OverlayConnectButtonToMeetBrandGuidelines(props: { connectorName: ConnectorName }): JSX.Element {
    // Google:
    // https://about.google/brand-resource-center/guidance/apis/
    // https://developers.google.com/drive/api/guides/branding
    return (
        <Box direction="row" align="center" justify="center" gap="xxsmall" pad="xxsmall">
            {props.connectorName === ConnectorName.GOOGLE && (
                <ConnectorIconSmall name={ConnectorName.GOOGLE} sizePixels="16px" />
            )}
            <Text size="small">Connect to {getFormattedConnectorName(props.connectorName)}</Text>
        </Box>
    );
}

function PreAuthField(props: { field: ConnectorConfigField; onChange: (value: string) => void }): JSX.Element {
    if (props.field.type !== ConnectorConfigPreAuthFieldType.Boolean) {
        throw new Error(`PreAuthField type ${props.field.type} is unsupported`);
    }

    function stringToBool(s: string | null | undefined): boolean {
        if (s == null) {
            return false;
        }
        return s === 'true';
    }

    return (
        <Box
            gap="xxsmall"
            pad="xsmall"
            background="background-back"
            round="5px"
            border={{ color: 'light-3' }}
            elevation="xsmall"
        >
            <CheckBox
                label={
                    <Text weight={'bold'} size="16px">
                        {props.field.label}
                    </Text>
                }
                checked={stringToBool(props.field.value)}
                disabled={props.field.canEdit === false}
                onChange={(e) => props.onChange(e.target.checked ? 'true' : 'false')}
            />
            {props.field.description && (
                <Box margin={{ left: '30px' }}>
                    <Text size="12px" style={{ opacity: props.field.canEdit === false ? 0.5 : undefined }}>
                        {props.field.description}
                    </Text>
                </Box>
            )}
        </Box>
    );
}

// This component and all the logic has gotten really complex with lots of duplicated
// types and inconsistent logic. At some point this would need to be refactored.
function PreAuthUi(props: {
    connectorName: ConnectorName;
    params: ConnectorAvailableState['config']['preAuthUserInput'];
    onComplete: (values: ConnectPreAuthInput) => void;
    onBasicAuth: (values: ConnectPreAuthInput) => Promise<void>;
    connectedConnectorState?: ConnectorConnectedState['config']['preAuthUserInput'];
    defaultSlackUserTokenEnabled?: boolean;
}): JSX.Element {
    if (!props.params) {
        throw new Error(`PreAuthUi params are invalid`);
    }

    const [arrayValues, setArrayValues] = useState<{ userInput: string; validated: string }[]>(
        props.connectedConnectorState?.field?.arrayValues?.map((v) => {
            return {
                userInput: v,
                validated: v,
            };
        }) || [],
    );
    const [fields, setFields] = useState(() => {
        return props.params?.fields?.map((f) => {
            if (props.connectorName === ConnectorName.SLACK && props.defaultSlackUserTokenEnabled && f.key === 'user') {
                return {
                    ...f,
                    value: 'true',
                };
            }
            return f;
        });
    });
    const [entryError, setEntryError] = useState(false);

    return (
        <Box>
            <Text size="16px">
                {props.params.description.map((d, i) => (
                    <span key={`desc-${i}`} dangerouslySetInnerHTML={{ __html: d }} />
                ))}
            </Text>
            {getPreAuthGif(props.connectorName) && (
                <Box margin={{ bottom: 'xsmall' }}>
                    <ImageBox maxWidth="420px" src={getPreAuthGif(props.connectorName) || ''} />
                </Box>
            )}
            {props.params.field && (
                <Box gap="xsmall">
                    <Text weight="bold">{props.params.field.label}</Text>
                    <Box gap="xxsmall" margin={{ bottom: 'xsmall' }} height={{ min: 'unset' }}>
                        {arrayValues.map((v, i) => (
                            <StringEntryCard
                                key={`entry-${i}`}
                                value={v.userInput}
                                onRemove={() => {
                                    const filtered = arrayValues.filter((v, idx) => idx !== i);
                                    setArrayValues(filtered);
                                }}
                            />
                        ))}
                        {(props.params.field.maxLength == null ||
                            arrayValues.length < props.params.field.maxLength) && (
                            <StringEntry
                                onValidation={(userInput, validated) => {
                                    const exists = arrayValues.find((v) => v.validated === validated);
                                    if (exists) {
                                        return 'Already exists.';
                                    }
                                    const copy = [...arrayValues];
                                    copy.push({ userInput, validated });
                                    setArrayValues(copy);
                                    return undefined;
                                }}
                                validationRegex={props.params?.field.validatorRegExp || undefined}
                                validationFailureErrorMessage={
                                    props.params.field.validationFailureErrorMessage || undefined
                                }
                                onErrorChange={(err) => setEntryError(err)}
                            />
                        )}
                    </Box>
                    <CtaButtonSpinner
                        label={props.params.field.isBasicAuth ? 'Connect' : 'Next'}
                        notPrimary
                        onClick={async () => {
                            const data: ConnectPreAuthInput = {
                                field: {
                                    arrayValues: arrayValues.map((v) => v.validated),
                                    type: PreAuthUserInputFieldType.StringArray,
                                },
                            };
                            if (props.params && props.params.field?.isBasicAuth) {
                                await props.onBasicAuth(data);
                            } else {
                                props.onComplete(data);
                            }
                        }}
                        disabled={arrayValues.length === 0 || entryError}
                    />
                </Box>
            )}
            {fields && fields.length > 0 && (
                <Box gap="xsmall">
                    <Box gap="xxsmall">
                        {fields.map((field, fieldIndex) => (
                            <PreAuthField
                                key={`field-${field.key}`}
                                field={field}
                                onChange={(v) => {
                                    setFields((prev) => {
                                        if (!prev) {
                                            return prev;
                                        }

                                        // make a deep copy
                                        const copy = structuredClone(prev);
                                        copy[fieldIndex].value = v;
                                        return copy;
                                    });
                                }}
                            />
                        ))}
                    </Box>

                    <CtaButtonSpinner
                        label={'Next'}
                        notPrimary
                        onClick={async () => {
                            const data: ConnectPreAuthInput = {
                                fields: fields.map((f) => ({
                                    type: f.type,
                                    key: f.key,
                                    label: f.label,
                                    value: f.value || 'false',
                                })),
                            };
                            props.onComplete(data);
                        }}
                    />
                </Box>
            )}
        </Box>
    );
}

function ImageBox(props: { maxWidth: string; src: string }): JSX.Element {
    return (
        <Box background="#FCFCFC" round="5px" pad="xxsmall" border={{ color: 'light-3' }}>
            <Box
                width={{
                    max: props.maxWidth,
                }}
                style={{ minHeight: '150px' }}
                alignSelf="center"
                justify="center"
                overflow={'hidden'}
                round="10px"
            >
                <Image src={props.src} width="100%" />
            </Box>
        </Box>
    );
}

function LinkedAppsCheckbox(props: {
    title: string;
    subtitle?: string;
    linked: { name: string; connected?: boolean }[];
    onSelected: (selected: ConnectorName[]) => void;
}): JSX.Element {
    const [selectedLinkedApps, setSelectedLinkedApps] = useState<ConnectorName[]>(
        props.linked.filter((l) => l.connected === true).map((c) => c.name as ConnectorName),
    );

    return (
        <Box
            gap="xsmall"
            pad="small"
            background="background-back"
            round="5px"
            border={{ color: 'light-3' }}
            elevation="xsmall"
        >
            <TwHeading level="4">{props.title}</TwHeading>
            {props.linked.map((l) => (
                <Box direction="row" key={`linked-app-${l.name}`} align="center" gap="xxsmall">
                    <CheckBox
                        checked={l.connected || selectedLinkedApps.includes(l.name as ConnectorName)}
                        label={
                            <Box direction="row" align="center" gap="2px">
                                <ConnectorIconSmall name={l.name as ConnectorName} sizePixels="18px" />
                                <Text>{getFormattedConnectorName(l.name)}</Text>
                            </Box>
                        }
                        onChange={(e) => {
                            let selected: ConnectorName[] = [];
                            if (e.target.checked) {
                                selected = [...selectedLinkedApps, l.name as ConnectorName];
                            } else {
                                selected = selectedLinkedApps.filter((s) => s !== l.name);
                            }
                            setSelectedLinkedApps(selected);
                            props.onSelected(selected);
                        }}
                        disabled={l.connected}
                    />
                </Box>
            ))}
            <Text size="small">{props.subtitle}</Text>
        </Box>
    );
}

function ConnectOverlay(props: {
    connector: CombinedConnectorStates;
    onConnect?: (
        conn: ConnectorAvailableState,
        state: { preAuthState?: ConnectPreAuthInput; selectedLinkedApps?: ConnectorName[] },
    ) => void;
    onReconnect?: (
        conn: ConnectorConnectedState,
        state: { preAuthState?: ConnectPreAuthInput; selectedLinkedApps?: ConnectorName[] },
    ) => void;
    onCloseOverlay: () => void;
    onError: (err: ErrorMessage) => void;
}): JSX.Element {
    const [preAuthComplete, setPreAuthComplete] = useState(false);
    const [preAuthState, setPreAuthState] = useState<ConnectPreAuthInput | undefined>();
    const [selectedLinkedApps, setSelectedLinkedApps] = useState<ConnectorName[]>([]);
    const { logger } = useTelemetryContext();
    const slackUserTokenEnabled = useGate(Gates.SlackUserTokenConnectEnabledByDefault);
    useStatsigLogEffect(Events.FrontendNewConnectionModalOpened, props.connector.state.connector);

    const defaultText = 'That Works uses read-only access and does not modify your data.';
    const connectText = getConnectText(props.connector.state.connector as ConnectorName);

    const showPreAuthUi = useMemo(() => {
        const configExists =
            props.connector.state.config.preAuthUserInput != null &&
            (props.connector.state.config.preAuthUserInput.field ||
                props.connector.state.config.preAuthUserInput.fields);
        return configExists;
    }, [props.connector]);

    return (
        <Box
            pad={{ horizontal: 'xsmall', top: 'small', bottom: 'xsmall' }}
            overflow={{ vertical: 'auto' }}
            id="connect-overlay-outer-box"
        >
            <Box gap="xsmall" height={{ height: 'max-content', min: 'unset' }}>
                <TwHeading level={3}>
                    {getOverlayTitleToMeetBrandGuidelines(props.connector.state.connector as ConnectorName)}
                </TwHeading>
                {showPreAuthUi && !preAuthComplete && (
                    <PreAuthUi
                        connectorName={props.connector.state.connector as ConnectorName}
                        defaultSlackUserTokenEnabled={slackUserTokenEnabled.value}
                        params={props.connector.state.config.preAuthUserInput}
                        onComplete={(data) => {
                            setPreAuthState(data);
                            setPreAuthComplete(true);
                        }}
                        onBasicAuth={async (data) => {
                            try {
                                const available = asAvailableConnector(props.connector);
                                const connected = asConnectedConnector(props.connector);

                                if (available && props.onConnect) {
                                    props.onConnect(available, {
                                        preAuthState: data,
                                    });
                                } else if (connected && props.onReconnect) {
                                    props.onReconnect(connected, {
                                        preAuthState: data,
                                    });
                                }
                                props.onCloseOverlay();
                            } catch (error) {
                                logger.exception(error);
                                props.onCloseOverlay();
                                props.onError(ServerCannotConnectMessage);
                            }
                        }}
                        connectedConnectorState={
                            asConnectedConnector(props.connector)?.config.preAuthUserInput || undefined
                        }
                    />
                )}

                {(!props.connector.state.config.preAuthUserInput || preAuthComplete) && (
                    <Box gap="xsmall">
                        {props.connector.state.linked && (
                            <LinkedAppsCheckbox
                                title="Select the apps you want to connect"
                                linked={
                                    props.connector.type === 'available'
                                        ? props.connector.state.linked.map((l) => ({ name: l }))
                                        : props.connector.state.linked
                                }
                                onSelected={(sel) => setSelectedLinkedApps(sel)}
                            />
                        )}
                        <Box
                            pad="small"
                            background="background-back"
                            round="5px"
                            border={{ color: 'light-3' }}
                            elevation="xsmall"
                        >
                            <Text size="16px">
                                {connectText.map((conText, conIdx) => (
                                    <span key={`conn-overlay-${conIdx}`}>
                                        {conText.decoration === 'bold' ? <strong>{conText.text}</strong> : conText.text}
                                        &nbsp;
                                    </span>
                                ))}
                                {defaultText}
                            </Text>
                        </Box>

                        <ImageBox
                            maxWidth={props.connector.state.connector === ConnectorName.ATLASSIAN ? '460px' : '280px'}
                            src={getClickGif(props.connector.state.connector as ConnectorName)}
                        />

                        <Button
                            disabled={props.connector.state.linked != null && selectedLinkedApps.length === 0}
                            label={
                                <OverlayConnectButtonToMeetBrandGuidelines
                                    connectorName={props.connector.state.connector as ConnectorName}
                                />
                            }
                            color="background-back"
                            primary
                            onClick={async () => {
                                try {
                                    const available = asAvailableConnector(props.connector);
                                    const connected = asConnectedConnector(props.connector);

                                    if (available && props.onConnect) {
                                        props.onConnect(available, {
                                            preAuthState,
                                            selectedLinkedApps:
                                                selectedLinkedApps.length > 0 ? selectedLinkedApps : undefined,
                                        });
                                    } else if (connected && props.onReconnect) {
                                        props.onReconnect(connected, {
                                            preAuthState,
                                            selectedLinkedApps:
                                                selectedLinkedApps.length > 0 ? selectedLinkedApps : undefined,
                                        });
                                    }
                                } catch (error) {
                                    logger.exception(error);
                                    props.onError(ServerCannotConnectMessage);
                                }
                                props.onCloseOverlay();
                            }}
                        />
                    </Box>
                )}
            </Box>
        </Box>
    );
}

function AlreadyConnectedOverlay(props: {
    status: ConnectorPermissionStatus;
    connector: CombinedConnectorStates;
    onCloseOverlay: () => void;
    onError: (err: ErrorMessage) => void;
    onReconnect?: (
        conn: ConnectorConnectedState,
        state: { preAuthState?: ConnectPreAuthInput; selectedLinkedApps?: ConnectorName[] },
    ) => void;
    onReconnectWithUiInput?: (conn: ConnectorConnectedState) => void;
    onDisconnect?: (conn: ConnectorConnectedState) => Promise<boolean>;
    onConnected: (connected: boolean) => void;
}): JSX.Element {
    function getConnectedLinked() {
        if (!props.connector.state.linked || props.connector.type === 'available') {
            return [];
        }
        const connected = props.connector.state.linked.filter((l) => l.connected === true);
        return connected.map((c) => c.name as ConnectorName);
    }

    const [showDisconnectConfirmation, setShowDisconnectConfirmation] = useState(false);
    const [selectedLinkedApps, setSelectedLinkedApps] = useState<ConnectorName[]>(getConnectedLinked());

    return (
        <Box pad="xsmall">
            <Box pad={{ vertical: 'xsmall' }}>
                <Box direction="row" gap="xsmall" align="center">
                    {props.status === ConnectorPermissionStatus.Ok ? (
                        <StatusGood size="26px" color="status-ok" />
                    ) : (
                        <StatusWarning size="26px" color="status-error" />
                    )}
                    <TwHeading level={3}>{getFormattedConnectorName(props.connector.state.connector)}</TwHeading>
                </Box>
                {props.connector.type === 'connected' && (
                    <Box>
                        <Text size="small" wordBreak="break-all" color={Colors.dark_4}>
                            Account: {props.connector.state.accountDisplayName}
                        </Text>
                    </Box>
                )}
            </Box>
            {props.connector.state.linked && props.connector.type === 'connected' && (
                <LinkedAppsCheckbox
                    title="Connected apps"
                    linked={props.connector.state.linked}
                    onSelected={(sel) => setSelectedLinkedApps(sel)}
                    subtitle="Connect additional apps by selecting them and clicking Reconnect."
                />
            )}
            {props.status !== ConnectorPermissionStatus.Ok && (
                <Box gap="xxsmall">
                    <Text>Click on Reconnect to resolve connection issues</Text>
                </Box>
            )}
            <Box gap="small">
                <Box direction="row" gap="xsmall" pad={{ top: 'small' }}>
                    <Button label="Cancel" onClick={() => props.onCloseOverlay()} />
                    <Button
                        label={
                            props.connector.state.linked && props.connector.state.linked.length > 0
                                ? `Disconnect All`
                                : `Disconnect`
                        }
                        onClick={async () => {
                            setShowDisconnectConfirmation(true);
                        }}
                    />
                    <Button
                        label={`Reconnect`}
                        onClick={async () => {
                            try {
                                const c = asConnectedConnector(props.connector);
                                if (!c) {
                                    throw new Error(`Failed to get as connected connector`);
                                }

                                if (props.onReconnectWithUiInput && c.config.preAuthUserInput) {
                                    props.onReconnectWithUiInput(c);
                                } else if (props.onReconnect) {
                                    props.onReconnect(c, {
                                        selectedLinkedApps:
                                            selectedLinkedApps.length > 0 ? selectedLinkedApps : undefined,
                                    });
                                    props.onCloseOverlay();
                                }
                            } catch (error) {
                                props.onError(ServerCannotConnectMessage);
                                props.onCloseOverlay();
                            }
                        }}
                    />
                </Box>
                {showDisconnectConfirmation && (
                    <DisconnectionWarning
                        connector={props.connector.state.connector as ConnectorName}
                        onDisconnect={async () => {
                            if (props.onDisconnect) {
                                const c = asConnectedConnector(props.connector);
                                if (!c) {
                                    throw new Error(`Failed to get as connected connector`);
                                }
                                const disconnected = await props.onDisconnect(c);
                                props.onCloseOverlay();
                                props.onConnected(!disconnected);
                                setShowDisconnectConfirmation(false);
                            }
                        }}
                    />
                )}
            </Box>
        </Box>
    );
}

export default function ConnectionButton(props: {
    connector: CombinedConnectorStates;
    connected?: boolean;
    status: ConnectorPermissionStatus;
    onConnect?: (
        conn: ConnectorAvailableState,
        state: { preAuthState?: ConnectPreAuthInput; selectedLinkedApps?: ConnectorName[] },
    ) => void;
    onReconnect?: (
        conn: ConnectorConnectedState,
        state: { preAuthState?: ConnectPreAuthInput; selectedLinkedApps?: ConnectorName[] },
    ) => void;
    onDisconnect?: (conn: ConnectorConnectedState) => Promise<boolean>;
    onError: (err: ErrorMessage) => void;
    width?: string;
    firstPollProgress?: number;
}): JSX.Element {
    const [displayState, setDisplayState] = useState<'connected' | 'disconnected' | 'reconnect_ui_input'>(
        props.connected ? 'connected' : 'disconnected',
    );
    const [showOverlay, setShowOverlay] = useState(false);

    function getIcon(name: ConnectorName) {
        switch (name) {
            // ENSURE LOGO SIZES AND THE GOOGLE LOGO MATCHES THIS
            // https://developers.google.com/identity/branding-guidelines
            case ConnectorName.GOOGLE:
                return <GoogleSigninIcon style={{ pointerEvents: 'none' }} />;
            case ConnectorName.ATLASSIAN:
                return <AtlassianIcon height={`18px`} style={{ pointerEvents: 'none', justifySelf: 'center' }} />;
            case ConnectorName.CLICKUP:
                return <ClickupIcon height={`18px`} style={{ pointerEvents: 'none' }} />;
            case ConnectorName.MONDAY:
                return <MondayIcon height={`18px`} style={{ pointerEvents: 'none' }} />;
            case ConnectorName.NOTION:
                return <NotionTextIcon width={100} height={`20px`} style={{ pointerEvents: 'none' }} />;
            case ConnectorName.FIGMA:
                return <FigmaIcon height={`18px`} style={{ pointerEvents: 'none' }} />;
            case ConnectorName.MIRO:
                return <MiroIcon height={`18px`} style={{ pointerEvents: 'none' }} />;
            case ConnectorName.ASANA:
                return (
                    <img
                        src={`${process.env.PUBLIC_URL}/icons/asana.svg`}
                        height="17px"
                        style={{ pointerEvents: 'none' }}
                        alt="Asana Icon"
                    />
                );
            case ConnectorName.TOGGL:
                return (
                    <img
                        src={`${process.env.PUBLIC_URL}/icons/toggl.svg`}
                        height="18px"
                        style={{ pointerEvents: 'none' }}
                        alt="Toggl Icon"
                    />
                );
            case ConnectorName.LINEAR:
                return (
                    <img
                        src={`${process.env.PUBLIC_URL}/icons/linear.svg`}
                        height="18px"
                        style={{ pointerEvents: 'none' }}
                        alt="Linear Icon"
                    />
                );
            case ConnectorName.SLACK:
                return (
                    <img
                        src={`${process.env.PUBLIC_URL}/icons/slack.svg`}
                        height="18px"
                        style={{ pointerEvents: 'none' }}
                        alt="Slack Icon"
                    />
                );
            case ConnectorName.GITHUB:
                return (
                    <img
                        src={`${process.env.PUBLIC_URL}/icons/github.svg`}
                        height="18px"
                        style={{ pointerEvents: 'none' }}
                        alt="GitHub Icon"
                    />
                );
            case ConnectorName.BITBUCKET:
                return (
                    <img
                        src={`${process.env.PUBLIC_URL}/icons/bitbucket.svg`}
                        height="18px"
                        style={{ pointerEvents: 'none' }}
                        alt="Bitbucket Icon"
                    />
                );
            case ConnectorName.HUBSPOT:
                return (
                    <img
                        src={`${process.env.PUBLIC_URL}/icons/hubspot.svg`}
                        height="18px"
                        style={{ pointerEvents: 'none' }}
                        alt="Hubspot Icon"
                    />
                );
            default:
                throw new Error(`No icon specified: ${name}`);
        }
    }

    const ConnectorIconButton = (props: { connectorName: ConnectorName; status: ConnectorPermissionStatus }) => (
        <Button
            onClick={() => setShowOverlay(true)}
            plain
            style={{ padding: '0px', justifyContent: 'center', width: 'max-content', borderRadius: '24px' }}
            justify="center"
        >
            {({ hover }) => (
                <Box
                    pad="xxsmall"
                    direction="row"
                    align="center"
                    gap="xsmall"
                    border={{
                        color:
                            props.status !== ConnectorPermissionStatus.Ok
                                ? 'status-error'
                                : displayState === 'connected'
                                ? 'status-ok'
                                : 'brand',
                        size: 'small',
                    }}
                    style={{
                        boxShadow: hover
                            ? `0px 0px 0px 1px ${
                                  props.status !== ConnectorPermissionStatus.Ok
                                      ? 'status-error'
                                      : displayState === 'connected'
                                      ? theme.global?.colors?.['accent-1']
                                      : theme.global?.colors?.brand
                              }`
                            : undefined,
                        justifyContent: 'center',
                    }}
                    round="small"
                    height="90px"
                    width="300px"
                    background={
                        props.status !== ConnectorPermissionStatus.Ok
                            ? { color: 'status-error', opacity: 'weak' }
                            : '#FFFFFF'
                    }
                >
                    {getIcon(props.connectorName)}
                </Box>
            )}
        </Button>
    );

    function connected() {
        const c = asConnectedConnector(props.connector);
        if (!c) {
            return <></>;
        }
        return <ConnectedConnectorButton state={c} onClick={() => setShowOverlay(true)} />;
    }

    return (
        <Box width={props.width || 'medium'}>
            {!props.connected && (
                <ConnectorIconButton
                    connectorName={props.connector.state.connector as ConnectorName}
                    status={props.status}
                />
            )}
            {connected()}
            {conditionally(
                () => showOverlay,
                <Layer
                    onClickOutside={() => {
                        setShowOverlay(false);
                        if (displayState === 'reconnect_ui_input') {
                            setDisplayState('connected');
                        }
                    }}
                    onEsc={() => {
                        setShowOverlay(false);
                        if (displayState === 'reconnect_ui_input') {
                            setDisplayState('connected');
                        }
                    }}
                    margin={{ vertical: 'small' }}
                >
                    <Box height="100%" overflow={{ vertical: 'auto' }}>
                        <Box
                            pad={{ bottom: 'small', left: 'small', right: 'small' }}
                            width={{ max: '40vw', min: '100%' }}
                            height={{ min: 'max-content' }}
                        >
                            {displayState === 'connected' && (
                                <AlreadyConnectedOverlay
                                    connector={props.connector}
                                    onCloseOverlay={() => setShowOverlay(false)}
                                    onConnected={(b) => setDisplayState('connected')}
                                    onError={props.onError}
                                    status={props.status}
                                    onDisconnect={props.onDisconnect}
                                    onReconnect={props.onReconnect}
                                    onReconnectWithUiInput={() => setDisplayState('reconnect_ui_input')}
                                />
                            )}
                            {displayState === 'disconnected' && (
                                <ConnectOverlay
                                    connector={props.connector}
                                    onCloseOverlay={() => setShowOverlay(false)}
                                    onConnect={props.onConnect}
                                    onError={props.onError}
                                />
                            )}
                            {displayState === 'reconnect_ui_input' && (
                                <ConnectOverlay
                                    connector={props.connector}
                                    onCloseOverlay={() => setShowOverlay(false)}
                                    onConnect={props.onConnect}
                                    onError={props.onError}
                                    onReconnect={props.onReconnect}
                                />
                            )}
                        </Box>
                    </Box>
                </Layer>,
            )}
        </Box>
    );
}

export function ConnectionButtonsGrid(props: {
    availableConnectors: ConnectorAvailableState[];
    connectedConnectors: ConnectorConnectedState[];
    connectedOrgWideConnectors: OrgWideConnectorConnectedState[];
    onConnect: (
        connectorName: ConnectorAvailableState,
        state: { preAuthState?: ConnectPreAuthInput; selectedLinkedApps?: ConnectorName[] },
    ) => void;
    onDisconnect: (name: ConnectorConnectedState) => Promise<boolean>;
    onReconnect: (
        name: ConnectorConnectedState,
        state: { preAuthState?: ConnectPreAuthInput; selectedLinkedApps?: ConnectorName[] },
    ) => void;
    onError: (err: ErrorMessage) => void;
}): JSX.Element {
    const buttonGapWidth = '15px';

    const { organizationDisplayName } = useUserStateContext();
    const [okConnectors, setOkConnectors] = useState<ConnectorConnectedState[]>([]);
    const [problematicConnectors, setProblematicConnectors] = useState<ConnectorConnectedState[]>([]);

    useEffect(() => {
        setOkConnectors(props.connectedConnectors.filter((c) => c.status.permissions === ConnectorPermissionStatus.Ok));
        setProblematicConnectors(
            props.connectedConnectors.filter((c) => c.status.permissions !== ConnectorPermissionStatus.Ok),
        );
    }, [props.connectedConnectors]);

    return (
        <Box gap="small">
            {problematicConnectors.length > 0 && (
                <Box gap="xsmall">
                    <Box direction="row" align="center" gap="xxsmall">
                        <StatusWarning size="24px" color="status-critical" />
                        <TwHeading level={4}>Requires Reconnection</TwHeading>
                    </Box>
                    <Text>
                        {problematicConnectors.length === 1
                            ? `${getFormattedConnectorName(problematicConnectors[0].connector)} needs`
                            : 'These apps need'}
                        &nbsp;to be reconnected to resolve connection issues.
                    </Text>
                    <Grid columns="550px" gap={{ row: '28px', column: '20px' }} justifyContent="center">
                        {problematicConnectors.map((v, i) => (
                            <ConnectionButton
                                width="550px"
                                connector={{ type: 'connected', state: v }}
                                status={v.status.permissions}
                                key={`${v}-${i}`}
                                connected={true}
                                onConnect={props.onConnect}
                                onDisconnect={props.onDisconnect}
                                onReconnect={props.onReconnect}
                                onError={props.onError}
                                firstPollProgress={v.firstPollProgress ?? undefined}
                            />
                        ))}
                    </Grid>
                </Box>
            )}
            {props.availableConnectors.length > 0 && (
                <Box gap="xsmall">
                    <Box direction="row" align="center" gap="xxsmall">
                        <StatusGood size="24px" color="brand" />
                        <TwHeading level={4}>Ready To Connect</TwHeading>
                    </Box>
                    <Grid columns="300px" gap={{ row: buttonGapWidth, column: buttonGapWidth }} justifyContent="center">
                        {props.availableConnectors.map((v, i) => (
                            <ConnectionButton
                                connector={{ type: 'available', state: v }}
                                status={ConnectorPermissionStatus.Ok}
                                key={`${v.connector}-${i}`}
                                onConnect={props.onConnect}
                                onError={props.onError}
                            />
                        ))}
                    </Grid>
                </Box>
            )}
            {okConnectors.length > 0 && (
                <Box gap="xsmall">
                    <Box direction="row" align="center" gap="xxsmall">
                        <StatusGood size="24px" color="status-ok" />
                        <TwHeading level={4}>Already Connected</TwHeading>
                    </Box>
                    <Grid columns="550px" gap={{ row: '28px', column: '20px' }} justifyContent="center">
                        {okConnectors.map((v, i) => (
                            <ConnectionButton
                                width="550px"
                                connector={{ type: 'connected', state: v }}
                                status={v.status.permissions}
                                key={`${v}-${i}`}
                                connected={true}
                                onConnect={props.onConnect}
                                onDisconnect={props.onDisconnect}
                                onReconnect={props.onReconnect}
                                onError={props.onError}
                            />
                        ))}
                    </Grid>
                </Box>
            )}
            {props.connectedOrgWideConnectors.length > 0 && (
                <Box gap="xsmall">
                    <Box direction="row" align="center" gap="xxsmall">
                        <StatusGood size="24px" color="status-ok" />
                        <TwHeading style={{ textTransform: 'capitalize' }} level={4}>
                            Connected Across {organizationDisplayName || 'Your Organization'}
                        </TwHeading>
                    </Box>
                    <Grid columns="550px" gap={{ row: '28px', column: '20px' }} justifyContent="center">
                        {props.connectedOrgWideConnectors.map((v, i) => (
                            <OrgWideConnectionButton width="550px" connector={v} key={`org-connected-${i}`} />
                        ))}
                    </Grid>
                </Box>
            )}
        </Box>
    );
}
