// Copyright 2021
// ThatWorks.xyz Limited

import { Colors } from '@thatworks/colors';
import { ConnectorName } from '@thatworks/connector-api';
import { Box, Button, Spinner, Text, Tip } from 'grommet';
import { CircleAlert, Lock, StatusGood } from 'grommet-icons';
import {
    ConnectorConnectedState,
    ConnectorPermissionStatus,
    UserConnectorDataStatus,
} from '../../../../__generated__/graphql';
import { ConnectorIconSmall } from '../../../../components/ConnectorIcon';
import { tooltipProps } from '../../../../components/Tooltip';

function getColor(permissionsState: ConnectorPermissionStatus, dataState: UserConnectorDataStatus) {
    if (permissionsState !== ConnectorPermissionStatus.Ok) {
        return 'status-critical';
    }
    if (dataState === UserConnectorDataStatus.Processing) {
        return 'accent-4';
    }
    if (dataState !== UserConnectorDataStatus.Ok) {
        return 'status-critical';
    }
    return 'status-ok';
}

function getDataLabel(dataState: UserConnectorDataStatus): string {
    switch (dataState) {
        case UserConnectorDataStatus.Ok:
            return 'Up to date';
        case UserConnectorDataStatus.Processing:
            return 'Processing';
        case UserConnectorDataStatus.Fail:
            return 'Data Fail';
        case UserConnectorDataStatus.Stale:
            return 'Stale Data';
    }
}

function getDataTooltip(dataState: UserConnectorDataStatus): string | undefined {
    switch (dataState) {
        case UserConnectorDataStatus.Processing:
            return 'Usually takes a few minutes to complete';
        case UserConnectorDataStatus.Fail:
            return 'Should resolve itself in a couple of hours';
        case UserConnectorDataStatus.Stale:
            return 'Try reconnecting your account';
    }
}

function getDataIcon(dataState: UserConnectorDataStatus | undefined): JSX.Element {
    if (!dataState) {
        return <Spinner width="14px" height="14px" />;
    }
    switch (dataState) {
        case UserConnectorDataStatus.Ok:
            return <StatusGood size="16px" color="status-ok" />;
        case UserConnectorDataStatus.Processing:
            return <Spinner width="14px" height="14px" />;
        case UserConnectorDataStatus.Fail:
            return <CircleAlert size="16px" color="status-critical" />;
        case UserConnectorDataStatus.Stale:
            return <CircleAlert size="16px" color="status-critical" />;
    }
}

function getDataTooltipProps(dataState: UserConnectorDataStatus) {
    const t = getDataTooltip(dataState);
    if (!t) {
        return { plain: true };
    }
    return tooltipProps(getDataTooltip(dataState));
}

export function ConnectedConnectorButton(props: {
    state: ConnectorConnectedState;
    width?: string;
    onClick?: () => void;
    disableHover?: boolean;
}): JSX.Element {
    return (
        <Button
            onClick={props.state.isConnectedByUser ? props.onClick : undefined}
            plain
            style={{
                justifyContent: 'center',
                cursor: props.disableHover || !props.state.isConnectedByUser ? 'default' : undefined,
                borderRadius: '12px',
            }}
            justify="center"
        >
            {({ hover }) => (
                <Box
                    focusIndicator={false}
                    border={{
                        color: getColor(props.state.status.permissions, props.state.status.data),
                        size: !props.disableHover && hover ? '3px' : '2px',
                    }}
                    background={Colors.white}
                    margin={!props.disableHover && hover ? '0px' : '1px'}
                    round="12px"
                    onClick={props.state.isConnectedByUser ? props.onClick : undefined}
                >
                    <Box align="center" direction="row" gap="xsmall" pad="15px">
                        <ConnectorIconSmall name={props.state.connector as ConnectorName} sizePixels={`35px`} />
                        <Box width="100%" justify="evenly" gap="xxsmall">
                            <Box>
                                <Text size="14px" wordBreak="break-all">
                                    {props.state.accountDisplayName}
                                </Text>
                            </Box>
                            {props.state.isConnectedByUser && (
                                <Box direction="row" align="center" gap="xsmall">
                                    <Box round="8px" direction="row" align="center" gap="6px">
                                        {props.state.status.permissions === ConnectorPermissionStatus.Ok && (
                                            <StatusGood size="16px" color="status-ok" />
                                        )}
                                        {props.state.status.permissions !== ConnectorPermissionStatus.Ok && (
                                            <CircleAlert size="16px" color="status-critical" />
                                        )}
                                        <Text size="14px">Permissions</Text>
                                    </Box>
                                    <Box round="8px">
                                        <Tip {...getDataTooltipProps(props.state.status.data)}>
                                            <Box direction="row" align="center" gap="6px">
                                                {getDataIcon(props.state.status.data)}
                                                {(props.state.firstPollProgress == null ||
                                                    props.state.firstPollProgress === 1) && (
                                                    <Text size="14px">{getDataLabel(props.state.status.data)}</Text>
                                                )}
                                                {props.state.firstPollProgress != null &&
                                                    props.state.firstPollProgress < 1 && (
                                                        <Text size="14px">
                                                            Processing {Math.round(props.state.firstPollProgress * 100)}
                                                            %
                                                        </Text>
                                                    )}
                                            </Box>
                                        </Tip>
                                    </Box>
                                </Box>
                            )}
                        </Box>
                    </Box>
                    <Box
                        direction="row"
                        align="center"
                        pad={{ horizontal: '15px', vertical: '6px' }}
                        fill="horizontal"
                        gap="4px"
                        background={{
                            color: Colors.neutral_3,
                            opacity: 'medium',
                        }}
                        style={{ borderBottomLeftRadius: '12px', borderBottomRightRadius: '12px' }}
                    >
                        <Lock size="10px" />
                        <Text size="10px">This connection is private to your account</Text>
                    </Box>
                </Box>
            )}
        </Button>
    );
}
