// Copyright 2021
// ThatWorks.xyz Limited

import { Colors } from '@thatworks/colors';
import { Box, CheckBox, Drop, Grid, List, Text } from 'grommet';
import { Close } from 'grommet-icons';
import { useMemo, useRef, useState } from 'react';
import { GroupSettingsInput, ItemGroupType } from '../../../../../__generated__/graphql';
import { CtaButtonSpinnerV2, CtaTextButtonSpinner } from '../../../../../components/CtaButtonSpinner';
import { IconButtonV2 } from '../../../../../components/IconButton';
import { ToolbarButton } from '../../../../../components/prosemirror/ToolbarButton';
import { Folder } from '../../../../../icons/Folder';
import { FontFamily } from '../../../../../theme';

export const DEFAULT_GROUP_SETTINGS: GroupSettingsInput = {
    groupType: ItemGroupType.None,
    subgroupOrdering: undefined,
};

const GroupTypeArray = Array.from([
    ItemGroupType.None,
    ItemGroupType.Assignee,
    ItemGroupType.Parent,
    ItemGroupType.Status,
    ItemGroupType.People,
    ItemGroupType.StatusLifecycle,
]);

function getLabelForItemGroupType(groupType: ItemGroupType): string {
    switch (groupType) {
        case ItemGroupType.Assignee:
            return 'Assignee';
        case ItemGroupType.Parent:
            return 'Parent';
        case ItemGroupType.Status:
            return 'Status';
        case ItemGroupType.People:
            return 'People';
        case ItemGroupType.None:
            return 'None';
        case ItemGroupType.StatusLifecycle:
            return 'Status Lifecycle';
    }
}

function GroupSectionOrdering(props: {
    subgroupNames: string[] | undefined;
    group: GroupSettingsInput;
    onOrderingChange: (newOrder: string[] | undefined) => void;
}): JSX.Element {
    const [enabled, setEnabled] = useState<boolean>(
        props.group.subgroupOrdering != null && props.group.subgroupOrdering.length > 0,
    );

    const [subgroupNames, setSubgroupNames] = useState<string[] | undefined>(() => {
        const existingOrdering = new Set(props.group.subgroupOrdering || []);
        props.subgroupNames?.forEach((name) => {
            if (!existingOrdering.has(name)) {
                existingOrdering.add(name);
            }
        });
        return Array.from(existingOrdering);
    });
    const settingsAreEqual = useMemo(() => {
        return props.group.subgroupOrdering?.join('') === subgroupNames?.join('');
    }, [props.group.subgroupOrdering, subgroupNames]);

    if (!subgroupNames || subgroupNames.length === 0) {
        return <></>;
    }

    return (
        <Box>
            <Box>
                <CheckBox
                    label={
                        <Text size="16px" weight={enabled ? 'bold' : undefined}>
                            Customize ordering
                        </Text>
                    }
                    checked={enabled}
                    onChange={(e) => {
                        setEnabled(e.target.checked);
                        if (!e.target.checked) {
                            props.onOrderingChange(undefined);
                        }
                    }}
                />
            </Box>
            {enabled && (
                <Box>
                    <Box direction="row">
                        <List
                            data={subgroupNames}
                            onOrder={(ordered) => setSubgroupNames(ordered)}
                            showIndex={false}
                            defaultItemProps={{
                                pad: '0px',
                                style: { fontSize: '14px' },
                            }}
                            margin={{ top: 'xxsmall' }}
                            key={props.group.groupType}
                        />
                    </Box>

                    {!settingsAreEqual && (
                        <Box direction="row">
                            <CtaButtonSpinnerV2
                                style={{ width: '100px' }}
                                label="Apply"
                                onClick={async () => {
                                    props.onOrderingChange(subgroupNames);
                                }}
                            />
                        </Box>
                    )}
                </Box>
            )}
        </Box>
    );
}

export function GroupToolbarButton(props: {
    group: GroupSettingsInput;
    onChange: (group: ItemGroupType) => void;
    timelineLoading: boolean;
    subgroupNames?: string[];
    onOrderingChange: (newOrder: string[] | undefined) => void;
    disabled: boolean;
}): JSX.Element {
    const [buttonActive, setButtonActive] = useState(false);
    const ref = useRef<HTMLDivElement>(null);

    const subgroupKey = useMemo(() => props.subgroupNames?.join('') || 'none', [props.subgroupNames]);

    return (
        <Box ref={ref}>
            <ToolbarButton
                active={buttonActive || props.group.groupType !== ItemGroupType.None}
                icon={Folder}
                label="Group By"
                onClick={async () => {
                    setButtonActive(!buttonActive);
                }}
                disabled={props.timelineLoading || props.disabled}
            />
            {buttonActive && (
                <Drop
                    plain
                    target={ref.current || undefined}
                    align={{ top: 'bottom', right: 'right' }}
                    pad={'xxsmall'}
                    onClickOutside={() => {
                        setButtonActive(false);
                    }}
                    margin={{ bottom: 'small' }}
                >
                    <Box
                        overflow={{ vertical: 'auto' }}
                        width={{ max: '700px' }}
                        background={{ color: Colors.background_front }}
                        border={{ color: Colors.border_dark, size: '1px' }}
                        pad={'xsmall'}
                        round={'10px'}
                        elevation="xsmall"
                    >
                        <Box gap="xsmall" height={{ min: 'max-content' }}>
                            <Box direction="row" justify="between" fill="horizontal">
                                <Text size="16px" style={{ fontFamily: FontFamily.Heading }} weight="bold">
                                    Group By
                                </Text>
                                <IconButtonV2
                                    icon={(hover) => <Close size="18px" color={hover ? Colors.brand : undefined} />}
                                    reverse
                                    onClick={() => setButtonActive(false)}
                                    alignSelf="end"
                                />
                            </Box>
                            <Grid columns={{ count: 3, size: '170px' }} gap="xsmall">
                                {GroupTypeArray.map((group, gi) => (
                                    <CtaTextButtonSpinner
                                        key={`group-${gi}`}
                                        label={getLabelForItemGroupType(group)}
                                        getBoxProps={() => {
                                            return {
                                                pad: { horizontal: 'xsmall' },
                                                border: {
                                                    color: Colors.accent_3,
                                                    size: '1px',
                                                },
                                                height: '25px',
                                                justify: 'center',
                                                round: '8px',
                                                background: {
                                                    color:
                                                        props.group.groupType === group
                                                            ? Colors.background_back
                                                            : Colors.background_front,
                                                },
                                                elevation: 'xsmall',
                                            };
                                        }}
                                        getTextProps={(hover) => {
                                            return {
                                                color: hover
                                                    ? Colors.brand
                                                    : props.group.groupType === group
                                                    ? Colors.black
                                                    : Colors.dark_6,
                                                size: '14px',
                                            };
                                        }}
                                        onClick={async () => {
                                            props.onChange(group);
                                        }}
                                    />
                                ))}
                            </Grid>
                            <GroupSectionOrdering
                                subgroupNames={props.subgroupNames}
                                key={subgroupKey}
                                group={props.group}
                                onOrderingChange={(newOrder) => {
                                    props.onOrderingChange(newOrder);
                                    setButtonActive(false);
                                }}
                            />
                        </Box>
                    </Box>
                </Drop>
            )}
        </Box>
    );
}
