// Copyright 2021
// ThatWorks.xyz Limited

export function Capitalize(s: string): string {
    if (!s) {
        return s;
    }
    if (s.length < 2) {
        return s[0].toUpperCase();
    }
    return s[0].toUpperCase() + s.slice(1).toLowerCase();
}
