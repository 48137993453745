// Copyright 2021
// ThatWorks.xyz Limited

import { Colors } from '@thatworks/colors';
import { Box, Spinner, Text } from 'grommet';
import { Close } from 'grommet-icons';
import { useEffect, useMemo, useState } from 'react';
import { AutomationScheduleEvery, SlackTeamChannels } from '../../../../../__generated__/graphql';
import { CtaButtonSpinnerV2 } from '../../../../../components/CtaButtonSpinner';
import { IconButtonV2 } from '../../../../../components/IconButton';
import { PlainTextInput } from '../../../../../components/PlainTextInput';
import { useUserStateContext } from '../../../../../components/UserContext';
import { FontFamily } from '../../../../../theme';
import { WorkspacesBrowser } from '../../templates/components/WorkspacesBrowser';
import { TimeInSchedule } from '../helpers/automation-types';
import { EmailList } from './EmailList';
import { ScheduleRow } from './ScheduleRow';
import { SlackChannelsCached } from './SlackChannelCached';

export interface AutomationState {
    every: AutomationScheduleEvery;
    time: TimeInSchedule;
    isoDayOfWeek: number;
    workspace: { id: string; label: string } | undefined;
}

export function MagicComposerAutomate(props: {
    automationState: AutomationState;
    slackNotifications: SlackTeamChannels[];
    emailNotications: string[];
    onUpdateAutomation: (automation: AutomationState) => void;
    onUpdateSlackNotifications: (slackNotifications: SlackTeamChannels[]) => void;
    onUpdateEmailNotifications: (emailList: string[]) => void;
    onCloseModal: () => void;
    onAutomate: (title: string) => Promise<void>;
    label: string;
    title: string;
    loadingSummary: boolean;
    loadingTitle: boolean;
}): JSX.Element {
    const { organizationId } = useUserStateContext();
    const [title, setTitle] = useState(props.title);
    useEffect(() => {
        setTitle(props.title);
    }, [props.title]);
    const loading = useMemo(
        () => props.loadingSummary || props.loadingTitle,
        [props.loadingTitle, props.loadingSummary],
    );

    return (
        <Box background={Colors.light_2} round="15px" pad="xsmall" width="600px">
            {loading && <Spinner alignSelf="center" />}
            {!loading && (
                <Box>
                    {/* Title and close button */}
                    <Box direction="row" justify="between" align="center" pad={{ bottom: 'xxsmall' }}>
                        <Text style={{ fontFamily: FontFamily.Callout }} size="22px" weight={'bold'}>
                            Automate
                        </Text>
                        <IconButtonV2
                            icon={(hover) => <Close size="18px" color={hover ? Colors.brand : undefined} />}
                            reverse
                            onClick={props.onCloseModal}
                        />
                    </Box>

                    <Box overflow={{ vertical: 'auto' }}>
                        <Box height={{ min: 'max-content' }}>
                            {/* Post title */}
                            <Box
                                direction="row"
                                align="baseline"
                                gap="xsmall"
                                border={{ side: 'bottom', size: '1px', color: Colors.border_dark }}
                                pad={{ vertical: 'xsmall' }}
                            >
                                <Text>Title</Text>
                                <PlainTextInput
                                    fontSize="16px"
                                    value={title}
                                    placeholder="Untitled"
                                    autoFocus
                                    style={{
                                        fontFamily: FontFamily.Mono,
                                        borderRadius: '8px',
                                        fontWeight: 'bold',
                                        letterSpacing: '-0.02em',
                                        color: 'unset',
                                        background: 'unset',
                                        border: `1px solid ${Colors.accent_3}`,
                                        padding: '5px 16px',
                                        backgroundColor: 'unset',
                                        width: '100%',
                                    }}
                                    width="inherit"
                                    onChange={(e) => setTitle(e.target.value)}
                                />
                            </Box>

                            {/* Schedule automation */}
                            <Box
                                border={{ side: 'bottom', size: '1px', color: Colors.border_dark }}
                                pad={{ vertical: 'xsmall' }}
                                gap="xsmall"
                            >
                                <Text style={{ fontFamily: FontFamily.Heading }} size="20px" weight={'bold'}>
                                    Schedule
                                </Text>
                                <ScheduleRow
                                    every={props.automationState.every}
                                    onEveryChange={(e) =>
                                        props.onUpdateAutomation({ ...props.automationState, every: e })
                                    }
                                    time={props.automationState.time}
                                    onTimeChange={(t) =>
                                        props.onUpdateAutomation({ ...props.automationState, time: t })
                                    }
                                    isoDayOfWeek={props.automationState.isoDayOfWeek}
                                    onDayChange={(d) =>
                                        props.onUpdateAutomation({ ...props.automationState, isoDayOfWeek: d })
                                    }
                                />
                            </Box>

                            {/* Publish to aka workspace selector */}
                            <Box
                                border={{ side: 'bottom', size: '1px', color: Colors.border_dark }}
                                pad={{ vertical: 'xsmall' }}
                                gap="xsmall"
                            >
                                <Text style={{ fontFamily: FontFamily.Heading }} size="20px" weight={'bold'}>
                                    Where to publish
                                </Text>
                                <Box gap="xsmall">
                                    <Text style={{ fontFamily: FontFamily.Heading }} weight={'bold'}>
                                        Topic
                                    </Text>
                                    {organizationId && (
                                        <WorkspacesBrowser
                                            organizationId={organizationId}
                                            selected={props.automationState.workspace}
                                            onSelected={(s) =>
                                                props.onUpdateAutomation({ ...props.automationState, workspace: s })
                                            }
                                        />
                                    )}
                                </Box>

                                {/* Share to aka Slack notifications */}
                                <Box gap="xxsmall">
                                    <SlackChannelsCached
                                        onSelectionChange={props.onUpdateSlackNotifications}
                                        existingNotifications={props.slackNotifications}
                                    />
                                </Box>
                                <Box gap="xsmall">
                                    <Text style={{ fontFamily: FontFamily.Heading }} weight={'bold'}>
                                        Email
                                    </Text>
                                    <EmailList
                                        emails={props.emailNotications}
                                        onEmailsUpdate={props.onUpdateEmailNotifications}
                                    />
                                </Box>
                            </Box>

                            {/* Save button */}
                            <Box width="max-content" pad={{ top: 'xsmall' }}>
                                <CtaButtonSpinnerV2
                                    label={props.label}
                                    style={{ padding: '1px 14px', height: '27px' }}
                                    onClick={() => props.onAutomate(title)}
                                    disabled={props.automationState.workspace == null || title === ''}
                                />
                            </Box>
                        </Box>
                    </Box>
                </Box>
            )}
        </Box>
    );
}
