// Copyright 2021
// ThatWorks.xyz Limited

import { useQuery } from '@apollo/client';
import { joinPagesPaths, Pages, QueryParams } from '@thatworks/shared-frontend/pages';
import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { BasicPage3, BasicPage3NavFunction } from '../../../../components/BasicPage3';
import Loading from '../../../../components/Loading';
import { getLabel, SidebarNav } from '../../../../components/SidebarNav';
import { useTelemetryContext } from '../../../../components/TelemetryContext';
import { useUserStateContext } from '../../../../components/UserContext';
import { withAuth } from '../../../../components/withAuth';
import { GET_TEMPLATE } from '../templates/components/template-queries';
import { MagicComposer } from './components/MagicComposer';
import { CreationMode } from './components/MagicComposerBuilder';

function MagicComposerPage(props: { onNav: BasicPage3NavFunction }): JSX.Element {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const { logger } = useTelemetryContext();
    const { postErrorMessage } = useUserStateContext();

    // Get magic composer mode
    const mode = searchParams.get(QueryParams.Mode);

    // Check if we received a template ID to edit
    const editTemplateId = searchParams.get(QueryParams.Edit);

    // Check if we received a template ID to used as a base
    const baseTemplateId = searchParams.get(QueryParams.SavedQueries);

    // Validate mode received and redirect if necessary
    useEffect(() => {
        // If we received an unknown mode, redirect the user to the create page without mode
        if (mode && !(Object.values(CreationMode) as string[]).includes(mode)) {
            navigate(joinPagesPaths([Pages.app.root, Pages.app.subs.create]));
        }

        // If we received a template ID and we are in post mode or without mode,
        // we should redirect the user to the create page without mode and without template ID
        if ((!mode || mode === CreationMode.Post) && editTemplateId) {
            navigate(joinPagesPaths([Pages.app.root, Pages.app.subs.create]));
        }

        // If we received a template ID to edit and use as base then redirect the user to the create page without mode
        if (editTemplateId && baseTemplateId) {
            navigate(joinPagesPaths([Pages.app.root, Pages.app.subs.create]));
        }
    }, [baseTemplateId, editTemplateId, mode, navigate]);

    // Get a template if an id is provided
    const { data, loading } = useQuery(GET_TEMPLATE, {
        variables: { workspacePostTemplateId: (editTemplateId ?? baseTemplateId)! },
        onError: (error) => {
            postErrorMessage({ title: 'Error', shortDesc: `Failed to get template ${editTemplateId}` });
            logger.error(error.message);
        },
        skip: !editTemplateId && !baseTemplateId,
        fetchPolicy: 'no-cache',
    });

    // Return
    return (
        <BasicPage3
            browserPageTitle={getLabel(SidebarNav.Create)}
            activeNavButton={SidebarNav.Create}
            onNav={props.onNav}
        >
            {loading && <Loading />}
            {!loading && (
                <MagicComposer
                    mode={mode as CreationMode}
                    edit={editTemplateId != null}
                    data={data?.workspacePostTemplate}
                />
            )}
        </BasicPage3>
    );
}

export default withAuth(MagicComposerPage);
