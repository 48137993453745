// Copyright 2021
// ThatWorks.xyz Limited

import { ApolloClient, ApolloProvider, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { useAuth0 } from '@auth0/auth0-react';
import { makeStatsigUser } from '@thatworks/shared-frontend/metrics';
import { joinPagesPaths, Pages } from '@thatworks/shared-frontend/pages';
import { CustomTokenClaimKeys, parseCustomTokenClaim } from '@thatworks/shared-frontend/token-claims';
import { useCallback, useEffect, useState } from 'react';
import { Navigate, Route, Routes, useNavigate, useParams } from 'react-router-dom';
import { StatsigProvider } from 'statsig-react';
import { BasicPage3NavFunction, BasicPage3NavFunctionProps } from '../../components/BasicPage3';
import Loading from '../../components/Loading';
import { SidebarNav } from '../../components/SidebarNav';
import { UserStateProvider, useUserStateContext } from '../../components/UserContext';
import { withAuth } from '../../components/withAuth';
import Api, { AUTH_HEADER } from '../../shared/Api';
import { AUTH0_AUDIENCE, AUTH0_DOMAIN } from '../../shared/auth0-props';
import { Environment, getEnvironment } from '../../shared/Environment';
import { ParamKey } from '../../shared/param-keys';
import { FrontendStorageKeys } from '../../storage-keys';
import { NotFound } from '../404';
import ConnectStatus from './pages/connect/ConnectStatus';
import ConnectV2 from './pages/connect/ConnectV2';
import Data from './pages/data';
import InternalSettingsV2 from './pages/internal-settings/InternalSettingsV2';
import MagicComposer from './pages/magic-composer';
import SavedQueries from './pages/saved-queries';
import Templates from './pages/templates';
import { OnboardWelcome } from './pages/welcome/OnboardWelcome';
import Ws from './pages/ws';
import WorkspaceOutlet from './pages/ws/WorkspaceOutlet';

function AppRoutes(props: { signupNux: boolean | undefined }): JSX.Element {
    const navigate = useNavigate();
    const { logout } = useAuth0();

    const handleNav: BasicPage3NavFunction = useCallback(
        (to: BasicPage3NavFunctionProps) => {
            switch (to.nav) {
                case SidebarNav.Connect:
                    navigate(joinPagesPaths([Pages.app.root, Pages.app.subs.connect.root]));
                    break;
                case SidebarNav.Data:
                    navigate(joinPagesPaths([Pages.app.root, Pages.app.subs.data]));
                    break;
                case SidebarNav.Automations:
                    navigate(joinPagesPaths([Pages.app.root, Pages.app.subs.automations]));
                    break;
                case SidebarNav.InternalSettings:
                    navigate(joinPagesPaths([Pages.app.root, Pages.app.subs.internalSettings]));
                    break;
                case SidebarNav.Logout:
                    logout();
                    break;
                case SidebarNav.Create:
                    navigate(joinPagesPaths([Pages.app.root, Pages.app.subs.create]));
                    break;
                case SidebarNav.SavedQueries:
                    navigate(joinPagesPaths([Pages.app.root, Pages.app.subs.savedQueries]));
                    break;
            }
        },
        [logout, navigate],
    );

    return (
        <Routes>
            <Route path="/" element={<Navigate to={getLandingPage(props.signupNux)} replace />} />

            <Route path={Pages.app.subs.connect.root}>
                <Route
                    path={Pages.app.subs.connect.subs.status}
                    element={<ConnectStatus onboarding={props.signupNux || false} onNav={handleNav} />}
                />
                <Route index element={<ConnectV2 onboarding={props.signupNux || false} onNav={handleNav} />} />
            </Route>
            <Route path={Pages.app.subs.data} element={<Data onNav={handleNav} />} />
            <Route path={Pages.app.subs.automations} element={<Templates onNav={handleNav} />} />
            <Route path={Pages.app.subs.internalSettings} element={<InternalSettingsV2 onNav={handleNav} />} />
            <Route path={Pages.app.subs.welcome} element={<OnboardWelcome onNav={handleNav} />} />
            <Route path={Pages.app.subs.workspaces}>
                <Route path={`:${ParamKey.WorkspaceUuid}/*`} element={<RedirectToTopic />} />
                <Route index element={<RedirectToTopic />} />
            </Route>
            <Route path={Pages.app.subs.topics.root} element={<Ws onNav={handleNav} />}>
                <Route path={`:${ParamKey.WorkspaceUuid}/*`} element={<WorkspaceOutlet />} />
                <Route index element={<WorkspaceOutlet />} />
            </Route>
            <Route path={Pages.app.subs.create} element={<MagicComposer onNav={handleNav} />} />
            <Route path={Pages.app.subs.savedQueries} element={<SavedQueries onNav={handleNav} />} />
            <Route path="*" element={<NotFound />} />
        </Routes>
    );
}

function getLandingPage(signupNux: boolean | undefined): string {
    if (signupNux) {
        return Pages.app.subs.welcome;
    } else {
        // If we have a topic Id in the local storage, redirect the user to it
        const topicId = localStorage.getItem(FrontendStorageKeys.LastVisitedTopic);
        if (topicId) {
            return joinPagesPaths([Pages.app.root, Pages.app.subs.topics.root, topicId]);
        }

        // Redirect to the magic composer
        return Pages.app.subs.create;
    }
}

function RedirectToTopic(): JSX.Element {
    const navigate = useNavigate();
    const params = useParams();
    useEffect(() => {
        const workspaceId = params[ParamKey.WorkspaceUuid];
        let path = joinPagesPaths([Pages.app.root, Pages.app.subs.topics.root]);
        path = workspaceId != null ? `${path}/${workspaceId}` : path;
        navigate(path);
    });
    return <></>;
}

function UserMetadataWrapper(): JSX.Element {
    const { userMetadata, stateContextInitialized } = useUserStateContext();
    const { isLoading } = useAuth0();

    if (isLoading || !stateContextInitialized) {
        return <Loading />;
    }

    return <AppRoutes signupNux={userMetadata.signupNux} />;
}

const errorLink = onError(({ graphQLErrors }) => {
    const unauth = graphQLErrors?.find((e) => e.extensions && e.extensions.code === 'UNAUTHENTICATED');
    if (unauth) {
        // redirect to login page
        window.location.href = joinPagesPaths([Pages.auth.root, Pages.auth.subs.login]);
    }
});

function ThatWorksApp(): JSX.Element {
    const { getIdTokenClaims, isLoading, user, getAccessTokenSilently } = useAuth0();
    const [userAuthDetails, setUserAuthDetails] = useState<{ emailDomain: string } | undefined>();
    const [organizationId, setOrganizationId] = useState<string | undefined>();

    useEffect(() => {
        if (isLoading) {
            return;
        }

        (async () => {
            const claims = await getIdTokenClaims();
            if (claims) {
                setUserAuthDetails({
                    emailDomain: parseCustomTokenClaim<string>(claims, CustomTokenClaimKeys.EmailDomain) || '',
                });
                setOrganizationId(parseCustomTokenClaim<string>(claims, CustomTokenClaimKeys.OrganizationId));
            }
        })();
    }, [getIdTokenClaims, isLoading]);

    return (
        <StatsigProvider
            sdkKey={process.env.REACT_APP_STATSIG_KEY || ''}
            user={makeStatsigUser({
                userId: user?.sub || '',
                emailDomain: userAuthDetails ? userAuthDetails.emailDomain : '',
                userAgent: window.navigator.userAgent,
                organizationId: organizationId || '',
            })}
            waitForInitialization
            shutdownOnUnmount
            options={{
                disableErrorLogging: true,
                environment: { tier: getEnvironment() as string },
                api: Api.getStatsigProxyEndpoint(),
            }}
        >
            <ApolloProvider
                client={
                    new ApolloClient({
                        link: setContext(async (_, { headers }) => {
                            const tokens = await getAccessTokenSilently({ detailedResponse: true });
                            const newHeaders: Record<string, string> = {
                                ...headers,
                            };
                            newHeaders[AUTH_HEADER] = `Bearer ${tokens.id_token}`;
                            return {
                                headers: newHeaders,
                            };
                        })
                            .concat(errorLink)
                            .concat(
                                createHttpLink({
                                    uri: Api.getGraphQlEndpoint(),
                                }),
                            ),
                        cache: new InMemoryCache(),
                        connectToDevTools: getEnvironment() === Environment.Development,
                    })
                }
            >
                <UserStateProvider
                    auth0={{
                        domain: AUTH0_DOMAIN,
                        audience: AUTH0_AUDIENCE,
                    }}
                >
                    <UserMetadataWrapper />
                </UserStateProvider>
            </ApolloProvider>
        </StatsigProvider>
    );
}

export default withAuth(ThatWorksApp);
