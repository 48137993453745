// Copyright 2021
// ThatWorks.xyz Limited

import { useAuth0 } from '@auth0/auth0-react';
import { Events } from '@thatworks/shared-frontend/metrics';
import { joinPagesPaths, Pages } from '@thatworks/shared-frontend/pages';
import { Box, Grid, Text } from 'grommet';
import { Add } from 'grommet-icons';
import { useEffect, useState } from 'react';
import { useStatsigLogEffect } from 'statsig-react';
import { ConnectorPermissionStatus, UserConnectorDataStatus } from '../../../../__generated__/graphql';
import { BasicPage3, BasicPage3NavFunction, DefaultPagePaddingPixels } from '../../../../components/BasicPage3';
import { CtaButtonSpinnerV2 } from '../../../../components/CtaButtonSpinner';
import { IconButtonV2 } from '../../../../components/IconButton';
import { InfoNotficationCard } from '../../../../components/InfoNotificationCard';
import { PageTitle } from '../../../../components/PageTitle';
import { getNavIcon, SidebarNav } from '../../../../components/SidebarNav';
import { TwHeading } from '../../../../components/TwHeading';
import { useUserStateContext } from '../../../../components/UserContext';
import { withAuth } from '../../../../components/withAuth';
import { useNavNoRerender } from '../../../../shared/UseNavNoRerender';
import { ConnectedConnectorButton } from './ConnectedConnectorButton';

function ConnectStatus(props: { onboarding: boolean; onNav: BasicPage3NavFunction }): JSX.Element {
    const [allAccountsReady, setAllAccountsReady] = useState(false);
    const { userState, patchUserMetadata, pollState } = useUserStateContext();
    const { getAccessTokenSilently } = useAuth0();
    const navigate = useNavNoRerender();
    useStatsigLogEffect(Events.FrontendConnectStatusPageLoaded);

    useEffect(() => {
        if (!userState) {
            return;
        }
        const ok = userState.connectedConnectors.filter(
            (c) =>
                (c.status.data === UserConnectorDataStatus.Ok ||
                    (c.firstPollProgress != null && c.firstPollProgress > 0.1)) &&
                c.status.permissions === ConnectorPermissionStatus.Ok &&
                c.isConnectedByUser,
        );
        setAllAccountsReady(ok.length === userState.connectedConnectors.filter((c) => c.isConnectedByUser).length);
    }, [userState]);

    useEffect(() => {
        pollState(true);
        return () => {
            pollState(false);
        };
    }, [pollState]);

    return (
        <BasicPage3
            activeNavButton={SidebarNav.Connect}
            browserPageTitle={props.onboarding ? 'Setting Up Your Account' : 'Connected Apps Status'}
            onNav={props.onNav}
            onboarding={props.onboarding}
        >
            <Box overflow={{ vertical: 'auto' }} height="100%" width="100%">
                <Box height={{ min: 'max-content' }}>
                    <Box
                        pad={{
                            left: DefaultPagePaddingPixels,
                            right: DefaultPagePaddingPixels,
                            top: 'small',
                            bottom: 'xsmall',
                        }}
                        border={{ side: 'bottom', color: 'border' }}
                    >
                        <PageTitle icon={getNavIcon(SidebarNav.Connect)}>
                            {props.onboarding ? 'Setting Up Your Account' : 'Connected Apps Status'}
                        </PageTitle>
                    </Box>
                    <Box
                        pad={{
                            left: DefaultPagePaddingPixels,
                            right: DefaultPagePaddingPixels,
                            top: 'small',
                            bottom: 'small',
                        }}
                        gap="small"
                    >
                        {props.onboarding && (
                            <Box width="large">
                                {
                                    <InfoNotficationCard
                                        header={
                                            <TwHeading level={4}>
                                                {allAccountsReady ? 'Setup Complete' : 'Setting Things Up'}
                                            </TwHeading>
                                        }
                                    >
                                        {!allAccountsReady && (
                                            <Text>
                                                We're setting up your account and crunching data. This usually takes 1-2
                                                minutes.
                                            </Text>
                                        )}
                                        {allAccountsReady && <Text>🎉 We're done setting up your account!</Text>}

                                        <CtaButtonSpinnerV2
                                            style={{
                                                width: allAccountsReady ? '80px' : '120px',
                                                height: '30px',
                                                padding: '2px 16px 2px 16px',
                                            }}
                                            label={allAccountsReady ? 'Next' : 'Waiting..'}
                                            disabled={!allAccountsReady}
                                            onClick={async () => {
                                                const tokens = await getAccessTokenSilently({ detailedResponse: true });
                                                await patchUserMetadata(tokens.access_token, { signupNux: false });
                                                navigate(joinPagesPaths([Pages.app.root, Pages.app.subs.create]));
                                            }}
                                        />
                                    </InfoNotficationCard>
                                }
                            </Box>
                        )}
                        <IconButtonV2
                            icon={(hover) => <Add color={hover ? 'brand' : undefined} size="16px" />}
                            label={(hover) => (
                                <Text size="16px" color={hover ? 'brand' : undefined}>
                                    Connect More Apps
                                </Text>
                            )}
                            reverse
                            onClick={async () => navigate('../')}
                        />

                        {userState && (
                            <Grid columns="550px" gap={{ row: '28px', column: '20px' }} justifyContent="center">
                                {userState.connectedConnectors.map((v, vi) => (
                                    <ConnectedConnectorButton state={v} key={`status-${vi}`} disableHover />
                                ))}
                            </Grid>
                        )}
                    </Box>
                </Box>
            </Box>
        </BasicPage3>
    );
}

export default withAuth(ConnectStatus);
