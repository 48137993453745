// Copyright 2021
// ThatWorks.xyz Limited

import { useMutation } from '@apollo/client';
import { Colors } from '@thatworks/colors';
import { Events } from '@thatworks/shared-frontend/metrics';
import { joinPagesPaths, Pages } from '@thatworks/shared-frontend/pages';
import { Box, Spinner, Text } from 'grommet';
import { Close } from 'grommet-icons';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Statsig } from 'statsig-react';
import { gql } from '../../../../../__generated__';
import { SlackTeamChannels } from '../../../../../__generated__/graphql';
import { CtaButtonSpinnerV2 } from '../../../../../components/CtaButtonSpinner';
import { IconButtonV2 } from '../../../../../components/IconButton';
import { PlainTextInput } from '../../../../../components/PlainTextInput';
import { useTelemetryContext } from '../../../../../components/TelemetryContext';
import { useUserStateContext } from '../../../../../components/UserContext';
import { FontFamily } from '../../../../../theme';
import { TemplatePreview } from '../../templates/components/TemplatePreview';
import { WorkspacesBrowser } from '../../templates/components/WorkspacesBrowser';
import { EmailList } from './EmailList';
import { SlackChannelsCached } from './SlackChannelCached';
import { TemplateBlockState } from './TemplateBlock';

const SAVE_WORKSPACE_POST = gql(/* GraphQL */ `
    mutation WorkspacePostCreate(
        $organizationId: ID!
        $workspaceId: ID!
        $title: String!
        $content: String!
        $slackNotifications: [SlackNotificationCreate!]!
        $emailNotifications: [String!]!
    ) {
        workspacePostCreate(
            organizationId: $organizationId
            workspaceId: $workspaceId
            title: $title
            content: $content
            slackNotifications: $slackNotifications
            emailNotifications: $emailNotifications
        )
    }
`);

export function MagicComposerShareTo(props: {
    title: string;
    blocks: TemplateBlockState[];
    onCloseModal: () => void;
    loadingSummary: boolean;
    loadingTitle: boolean;
}): JSX.Element {
    const navigate = useNavigate();
    const { logger } = useTelemetryContext();
    const { organizationId, postErrorMessage } = useUserStateContext();
    const [workspace, setWorkspace] = useState<{ id: string; label: string }>();
    const [content, setContent] = useState<string>();
    const [slackNotifications, setSlackNotifications] = useState<SlackTeamChannels[]>([]);
    const [emailNotifications, setEmailNotifications] = useState<string[]>([]);
    const [title, setTitle] = useState(props.title);

    useEffect(() => {
        setTitle(props.title);
    }, [props.title]);

    const goToTopic = useCallback(
        (workspaceId: string) => {
            navigate(`${joinPagesPaths([Pages.app.root, Pages.app.subs.topics.root])}/${workspaceId}`);
        },
        [navigate],
    );

    const [savePost] = useMutation(SAVE_WORKSPACE_POST, {
        onError: (err) => {
            postErrorMessage({ title: `Error`, shortDesc: 'Failed to publish post' });
            logger.error(err.message);
        },
    });

    const sharePost = useCallback(async () => {
        if (organizationId && workspace && content) {
            const result = await savePost({
                variables: {
                    organizationId: organizationId,
                    workspaceId: workspace.id,
                    title: title,
                    content,
                    slackNotifications: slackNotifications.map((n) => ({
                        channelIds: n.channels.map((c) => c.id),
                        teamId: n.teamId,
                    })),
                    emailNotifications: emailNotifications,
                },
            });
            Statsig.logEvent(Events.FrontendComposerPublished);

            if (result && !result.errors) {
                goToTopic(workspace.id);
            }
        }
    }, [organizationId, workspace, content, savePost, title, slackNotifications, emailNotifications, goToTopic]);

    const loading = useMemo(
        () => props.loadingSummary || props.loadingTitle,
        [props.loadingTitle, props.loadingSummary],
    );

    return (
        <Box
            background={Colors.light_2}
            round="15px"
            pad="xsmall"
            width="80vw"
            height="80vh"
            gap="xsmall"
            overflow={{ vertical: 'auto' }}
        >
            {loading && (
                <Box fill justify="center">
                    <Spinner alignSelf="center" />
                </Box>
            )}
            {!loading && (
                <Box height={{ min: 'max-content' }}>
                    <Box gap="xsmall">
                        {/* Title and close button */}
                        <Box direction="row" justify="between">
                            <Text style={{ fontFamily: FontFamily.Callout }} size="22px" weight={'bold'}>
                                Share
                            </Text>
                            <IconButtonV2
                                icon={(hover) => <Close size="18px" color={hover ? Colors.brand : undefined} />}
                                reverse
                                onClick={props.onCloseModal}
                            />
                        </Box>

                        {/* Post title */}
                        <Box direction="row" align="baseline" gap="xsmall">
                            <Text>Title</Text>
                            <PlainTextInput
                                fontSize="16px"
                                value={title}
                                placeholder="Untitled"
                                autoFocus
                                style={{
                                    fontFamily: FontFamily.Mono,
                                    borderRadius: '8px',
                                    fontWeight: 'bold',
                                    letterSpacing: '-0.02em',
                                    color: 'unset',
                                    background: 'unset',
                                    border: `1px solid ${Colors.accent_3}`,
                                    padding: '5px 16px',
                                    width: '80%',
                                }}
                                width="inherit"
                                onChange={(e) => setTitle(e.target.value)}
                            />
                        </Box>

                        {/* Publish to aka workspace selector */}
                        <Box direction="row" align="baseline" gap="xsmall">
                            <Text>Publish to Topic</Text>
                            {organizationId && (
                                <WorkspacesBrowser
                                    organizationId={organizationId}
                                    selected={workspace}
                                    onSelected={(s) => setWorkspace(s)}
                                />
                            )}
                        </Box>

                        {/* Share to aka Slack notifications */}
                        <SlackChannelsCached onSelectionChange={setSlackNotifications} existingNotifications={[]} />
                        <Box gap="xsmall">
                            <Text style={{ fontFamily: FontFamily.Heading }} weight={'bold'}>
                                Email
                            </Text>
                            <EmailList emails={emailNotifications} onEmailsUpdate={setEmailNotifications} />
                        </Box>

                        {/* Share button */}
                        <Box width="max-content">
                            <CtaButtonSpinnerV2
                                label="Share"
                                style={{ padding: '1px 14px' }}
                                onClick={sharePost}
                                disabled={!workspace || title === ''}
                            />
                        </Box>

                        {/* Preview and edit content */}
                        <Box
                            background="white"
                            round={'15px'}
                            pad={{ horizontal: 'xsmall' }}
                            height={{ min: 'max-content' }}
                        >
                            {/* Template title */}
                            <Box pad={{ top: 'xsmall' }}>
                                <Text weight="bold" size="26px">
                                    {title}
                                </Text>
                            </Box>
                            <Box flex>
                                <TemplatePreview
                                    blocks={props.blocks}
                                    onChange={(content) => setContent(content)}
                                    editable={true}
                                />
                            </Box>
                        </Box>
                    </Box>
                </Box>
            )}
        </Box>
    );
}
