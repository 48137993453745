// Copyright 2021
// ThatWorks.xyz Limited

import { Colors } from '@thatworks/colors';
import { FilterHierarchyType } from '@thatworks/shared-frontend/collections';
import { Box, Drop, Text } from 'grommet';
import { Close, Magic, Search } from 'grommet-icons';
import MiniSearch from 'minisearch';
import { useMemo, useRef, useState } from 'react';
import { ActivityItemFilterOperator, ConnectorScopeInput, GraphFilterType } from '../../../../../__generated__/graphql';
import { IconButtonV2 } from '../../../../../components/IconButton';
import { PlainTextInput } from '../../../../../components/PlainTextInput';
import { ToolbarButton } from '../../../../../components/prosemirror/ToolbarButton';
import { TextDropButton } from '../../../../../components/TextDrop';
import { MagicWand } from '../../../../../icons/MagicWand';
import { FontFamily } from '../../../../../theme';
import { getPresetDescription, getPresetTitle, PresetFilter } from '../helpers/preset-filters';
import { FilterToolbarButton } from './filter-toolbar-button/FilterToolbarButton';
import { PropertyFilterGroup } from './filter-toolbar-button/helpers';

enum PresetGroups {
    Updates = 'updates',
    StatusBased = 'statusBased',
    DueBy = 'dueBy',
    Priority = 'priority',
    Visualize = 'visualize',
}

const PresetMapping: Record<
    PresetGroups,
    { label: string; presets: { id: PresetFilter; title: string; description: string }[] }
> = {
    [PresetGroups.StatusBased]: {
        label: 'Status based',
        presets: [
            {
                id: PresetFilter.WorkCompleted,
                title: getPresetTitle(PresetFilter.WorkCompleted),
                description: getPresetDescription(PresetFilter.WorkCompleted),
            },
            {
                id: PresetFilter.InProgress,
                title: getPresetTitle(PresetFilter.InProgress),
                description: getPresetDescription(PresetFilter.InProgress),
            },
            {
                id: PresetFilter.AllToDo,
                title: getPresetTitle(PresetFilter.AllToDo),
                description: getPresetDescription(PresetFilter.AllToDo),
            },
            {
                id: PresetFilter.Created,
                title: getPresetTitle(PresetFilter.Created),
                description: getPresetDescription(PresetFilter.Created),
            },
            {
                id: PresetFilter.ToDoWithUpdates,
                title: getPresetTitle(PresetFilter.ToDoWithUpdates),
                description: getPresetDescription(PresetFilter.ToDoWithUpdates),
            },
        ],
    },
    [PresetGroups.Updates]: {
        label: 'Updates',
        presets: [
            {
                id: PresetFilter.Updates,
                title: getPresetTitle(PresetFilter.Updates),
                description: getPresetDescription(PresetFilter.Updates),
            },
            {
                id: PresetFilter.UpdatesByOthers,
                title: getPresetTitle(PresetFilter.UpdatesByOthers),
                description: getPresetDescription(PresetFilter.UpdatesByOthers),
            },
            {
                id: PresetFilter.ItemsNoChanges,
                title: getPresetTitle(PresetFilter.ItemsNoChanges),
                description: getPresetDescription(PresetFilter.ItemsNoChanges),
            },
        ],
    },
    [PresetGroups.DueBy]: {
        label: 'Due by',
        presets: [
            {
                id: PresetFilter.DueThisWeek,
                title: getPresetTitle(PresetFilter.DueThisWeek),
                description: getPresetDescription(PresetFilter.DueThisWeek),
            },
            {
                id: PresetFilter.OverdueDueSoon,
                title: getPresetTitle(PresetFilter.OverdueDueSoon),
                description: getPresetDescription(PresetFilter.OverdueDueSoon),
            },
        ],
    },
    [PresetGroups.Priority]: {
        label: 'Priority',
        presets: [
            {
                id: PresetFilter.HighPriority,
                title: getPresetTitle(PresetFilter.HighPriority),
                description: getPresetDescription(PresetFilter.HighPriority),
            },
            {
                id: PresetFilter.ItemsBlocked,
                title: getPresetTitle(PresetFilter.ItemsBlocked),
                description: getPresetDescription(PresetFilter.ItemsBlocked),
            },
        ],
    },
    [PresetGroups.Visualize]: {
        label: 'Visualize',
        presets: [
            {
                id: PresetFilter.Indicators,
                title: getPresetTitle(PresetFilter.Indicators),
                description: getPresetDescription(PresetFilter.Indicators),
            },
            {
                id: PresetFilter.Charts,
                title: getPresetTitle(PresetFilter.Charts),
                description: getPresetDescription(PresetFilter.Charts),
            },
        ],
    },
};

const MiniSearchPresets = new MiniSearch({
    fields: ['title', 'description'],
    storeFields: ['id', 'title', 'description'],
    searchOptions: {
        prefix: true,
        boost: { title: 2 },
        fuzzy: 0.2,
    },
});

function filterPresets(
    presets: { id: PresetFilter; title: string; description: string }[],
    textInput: string,
): { id: PresetFilter; title: string; description: string }[] {
    if (!textInput) {
        return presets;
    }

    // Add all presets received to be indexed
    MiniSearchPresets.removeAll();
    MiniSearchPresets.addAll(presets);

    // Search
    let filteredPresets = MiniSearchPresets.search(textInput);

    // Return filtered inputs
    return filteredPresets.map((p) => ({ id: p.id, title: p.title, description: p.description }));
}

export function ActivityPresetsButton(props: {
    preset?: PresetFilter | undefined;
    timelineId: string | undefined;
    dataLoading: boolean;
    onPresetSelection: (p: PresetFilter) => void;
    onChangePropertyFilters: (filters: PropertyFilterGroup[]) => void;
    onChangeFilterOperator: (operator: ActivityItemFilterOperator) => void;
    propertyFilterGroups: PropertyFilterGroup[];
    filtersOperator: ActivityItemFilterOperator;
    selectedConnectorScopes: ConnectorScopeInput[];
    onChangeGraphFilterType: (graphFilterType: GraphFilterType) => void;
    graphFilterType: GraphFilterType;
}): JSX.Element {
    const ref = useRef<HTMLDivElement>(null);
    const [buttonActive, setButtonActive] = useState(false);
    const [textInput, setTextInput] = useState('');

    const presets = useMemo(() => {
        // Get preset values
        let presets = structuredClone(PresetMapping);

        // Remove the charts preset if there isn't any jira board/sprint
        if (
            !props.selectedConnectorScopes.find((connector) => {
                return [FilterHierarchyType.JiraBoard, FilterHierarchyType.JiraSprint].includes(
                    connector.hierarchyType as FilterHierarchyType,
                );
            })
        ) {
            presets[PresetGroups.Visualize].presets = presets[PresetGroups.Visualize].presets.filter(
                (preset) => preset.id !== PresetFilter.Charts,
            );
        }

        // Filter preset groups by text
        (Object.keys(presets) as Array<PresetGroups>).forEach((groupName) => {
            const filteredPresets = filterPresets(presets[groupName].presets, textInput);
            if (filteredPresets.length > 0) {
                presets[groupName].presets = filteredPresets;
            } else {
                delete presets[groupName];
            }
        });

        // Return
        return presets;
    }, [props.selectedConnectorScopes, textInput]);

    return (
        <div>
            <Box ref={ref}>
                <ToolbarButton
                    active={buttonActive || props.preset !== undefined}
                    icon={MagicWand}
                    onClick={async () => {
                        setButtonActive(!buttonActive);
                    }}
                    disabled={props.dataLoading}
                    label={props.preset ? getPresetTitle(props.preset) : 'Choose query'}
                />
                {buttonActive && (
                    <Drop
                        plain
                        target={ref.current || undefined}
                        align={{ top: 'bottom', left: 'left' }}
                        pad={'xxsmall'}
                        onClickOutside={() => setButtonActive(false)}
                    >
                        <Box
                            background={{ color: Colors.background_front }}
                            border={{ color: Colors.border_dark, size: '1px' }}
                            round={'10px'}
                            elevation="xsmall"
                            overflow={{ vertical: 'auto' }}
                            gap="xsmall"
                            pad="xsmall"
                        >
                            {/* Search bar and close button */}
                            <Box height={{ min: 'max-content' }} direction="row" gap="xsmall" pad={{ bottom: '3px' }}>
                                <Box
                                    direction="row"
                                    gap="10px"
                                    align="center"
                                    flex
                                    border={{ side: 'bottom', color: Colors.brand }}
                                >
                                    <Search size="14px" color={Colors.brand} />
                                    <PlainTextInput
                                        value={textInput}
                                        autoFocus
                                        placeholder="Search for preset"
                                        fontSize="14px"
                                        autoComplete="off"
                                        style={{
                                            fontFamily: FontFamily.Mono,
                                            background: 'unset',
                                            width: '100%',
                                        }}
                                        onChange={async (e) => setTextInput(e.target.value)}
                                    />
                                </Box>
                                <IconButtonV2
                                    icon={(hover) => <Close size="18px" color={hover ? Colors.brand : undefined} />}
                                    reverse
                                    onClick={() => setButtonActive(false)}
                                    alignSelf="end"
                                />
                            </Box>

                            {/* Preset groups */}
                            {Object.values(presets).map((g, gi) => (
                                <Box key={`group-${gi}`} height={{ min: 'max-content' }} gap="xxsmall">
                                    <Text
                                        size="14px"
                                        weight={500}
                                        color={Colors.dark_6}
                                        style={{
                                            fontFamily: FontFamily.Callout,
                                            textTransform: 'uppercase',
                                            letterSpacing: '2px',
                                        }}
                                    >
                                        {g.label}
                                    </Text>
                                    {g.presets.map((p, pi) => (
                                        <TextDropButton
                                            size="14px"
                                            pad="2px"
                                            onClick={async () => {
                                                setButtonActive(false);
                                                setTextInput('');
                                                props.onPresetSelection(p.id);
                                            }}
                                            key={`preset-${pi}`}
                                            spinnerColor="background-back"
                                            icon={<Magic size="20px" />}
                                            fontStyle={{ fontFamily: FontFamily.Mono, fontSize: '14px' }}
                                            active={props.preset === p.id}
                                            render={(hover, active) => (
                                                <Box>
                                                    {p.title}
                                                    <Text
                                                        size="12px"
                                                        color={hover || active ? '#ffffff' : Colors.dark_4}
                                                    >
                                                        {p.description}
                                                    </Text>
                                                </Box>
                                            )}
                                        />
                                    ))}
                                </Box>
                            ))}
                        </Box>
                    </Drop>
                )}
            </Box>
            <FilterToolbarButton
                onChangePropertyFilters={(f) => props.onChangePropertyFilters(f)}
                onChangeFilterOperator={(o) => props.onChangeFilterOperator(o)}
                propertyFilterGroups={props.propertyFilterGroups}
                filtersOperator={props.filtersOperator}
                timelineId={props.timelineId}
                dataLoading={props.dataLoading}
                graphFilterType={props.graphFilterType}
                onChangeGraphFilterType={props.onChangeGraphFilterType}
            />
        </div>
    );
}
