// Copyright 2021
// ThatWorks.xyz Limited

import { Blank, IconProps } from 'grommet-icons';

export const VerticalSixDots = (props: IconProps & JSX.IntrinsicElements['svg']) => (
    <Blank {...props}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256">
            <path
                stroke="none"
                d="M104,60A12,12,0,1,1,92,48,12,12,0,0,1,104,60Zm60,12a12,12,0,1,0-12-12A12,12,0,0,0,164,72ZM92,116a12,12,0,1,0,12,12A12,12,0,0,0,92,116Zm72,0a12,12,0,1,0,12,12A12,12,0,0,0,164,116ZM92,184a12,12,0,1,0,12,12A12,12,0,0,0,92,184Zm72,0a12,12,0,1,0,12,12A12,12,0,0,0,164,184Z"
            ></path>
        </svg>
    </Blank>
);
