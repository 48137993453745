// Copyright 2021
// ThatWorks.xyz Limited

import { useQuery } from '@apollo/client';
import { Colors } from '@thatworks/colors';
import * as emailValidator from 'email-validator';
import { Box, Spinner, Tag, Text, TextInput } from 'grommet';
import { Add, Checkmark, Close } from 'grommet-icons';
import { useCallback, useState } from 'react';
import { IconButtonV2 } from '../../../../../components/IconButton';
import { useTelemetryContext } from '../../../../../components/TelemetryContext';
import { useUserStateContext } from '../../../../../components/UserContext';
import { GET_THIS_USER } from '../../ws/WorkspaceCreate';

function AddEmail(props: { onNewEmail: (email: string) => void }): JSX.Element {
    const { postErrorMessage } = useUserStateContext();
    const [state, setState] = useState<'idle' | 'adding'>('idle');
    const [email, setEmail] = useState<string>('');
    const onNewEmail = props.onNewEmail;

    const validateEmailAndNotify = useCallback(
        (email: string) => {
            if (!emailValidator.validate(email)) {
                postErrorMessage({ title: 'Invalid Email', shortDesc: 'Please enter a valid email address' });
                return;
            }

            onNewEmail(email);
            setEmail('');
            setState('idle');
        },
        [onNewEmail, postErrorMessage],
    );

    return (
        <Box direction="row" gap="xxsmall" align="center">
            {state === 'idle' && (
                <IconButtonV2
                    icon={(hover) => <Add size="16px" color={hover ? Colors.brand : undefined} />}
                    label={(hover) => (
                        <Text size="14px" color={hover ? Colors.brand : undefined}>
                            Add An Email
                        </Text>
                    )}
                    reverse
                    onClick={() => setState('adding')}
                />
            )}
            {state === 'adding' && (
                <Box direction="row" gap="xxsmall" border={{ color: Colors.accent_3 }} round="8px">
                    <TextInput
                        placeholder="Enter an email address"
                        size="14px"
                        onChange={(e) => {
                            setEmail(e.target.value);
                        }}
                        style={{
                            borderWidth: '0px',
                            height: '30px',
                        }}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                validateEmailAndNotify(email);
                            }
                        }}
                        value={email}
                    />
                    <IconButtonV2
                        icon={(hover) => <Close size="16px" color={hover ? Colors.brand : undefined} />}
                        onClick={() => {
                            setEmail('');
                            setState('idle');
                        }}
                    />
                    <IconButtonV2
                        icon={(hover) => <Checkmark size="16px" color={hover ? Colors.brand : undefined} />}
                        onClick={() => {
                            validateEmailAndNotify(email);
                        }}
                    />
                    <></>
                </Box>
            )}
        </Box>
    );
}

export function EmailList(props: { emails: string[]; onEmailsUpdate: (emails: string[]) => void }): JSX.Element {
    const { logger } = useTelemetryContext();

    const { loading, data } = useQuery(GET_THIS_USER, {
        onError: (error) => {
            logger.error(error.message);
        },
    });

    if (loading) {
        return <Spinner />;
    }

    return (
        <Box gap="xxsmall">
            {props.emails.length > 0 && (
                <Box direction="row" gap="xxsmall" align="center" wrap margin={{ bottom: 'xxsmall' }}>
                    {props.emails.map((email, ei) => (
                        <Tag
                            key={`email-${ei}`}
                            value={email}
                            size="xsmall"
                            border={{ color: Colors.accent_3 }}
                            round="8px"
                            align="center"
                            onRemove={() => {
                                const newEmails = props.emails.filter((e) => e !== email);
                                props.onEmailsUpdate(newEmails);
                            }}
                        />
                    ))}
                </Box>
            )}
            <Box direction="row" gap="xsmall" align="center">
                <AddEmail
                    onNewEmail={(e) => {
                        // dedupe and update
                        const newEmails = Array.from(new Set([...props.emails, e]));
                        props.onEmailsUpdate(newEmails);
                    }}
                />
                {data && data.user.email && !props.emails.includes(data.user.email) && (
                    <IconButtonV2
                        icon={(hover) => <Add size="16px" color={hover ? Colors.brand : undefined} />}
                        label={(hover) => (
                            <Text size="14px" color={hover ? Colors.brand : undefined}>
                                Add Your Email
                            </Text>
                        )}
                        reverse
                        onClick={() => {
                            // dedupe and update
                            const newEmails = Array.from(new Set([...props.emails, data.user.email]));
                            props.onEmailsUpdate(newEmails);
                        }}
                    />
                )}
            </Box>
        </Box>
    );
}
