// Copyright 2021
// ThatWorks.xyz Limited

import { Fragment } from '@remirror/pm/model';
import { Remirror, useRemirror } from '@remirror/react';
import { useCallback, useEffect } from 'react';
import { CommandFunction, InvalidContentHandler, RemirrorJSON } from 'remirror';
import { useTelemetryContext } from '../../../../../components/TelemetryContext';
import { BlockType, TemplateBlockState } from '../../magic-composer/components/TemplateBlock';
import { FrontendSummarizedActivityNodeParser } from '../../magic-composer/filters/activity-node-parser';
import { ChartNodeReact } from '../../ws/components/pm-nodes/ChartNode';
import { GroupedInsightPillNodeReact } from '../../ws/components/pm-nodes/GroupedInsightNode';
import { InlineInsightPillNodeReact } from '../../ws/components/pm-nodes/InlineInsightPillNode';
import { InsightPillNodeReact } from '../../ws/components/pm-nodes/InsightNode';
import { MetricBoxNodeReact } from '../../ws/components/pm-nodes/MetricBoxNode';
import { TaskPreviewNode } from '../../ws/components/pm-nodes/TaskPreviewNode';
import { getDefaultProseMirrorExtensions, ProseWrapper } from '../../ws/ProseWrapper';

export function TemplatePreview(props: {
    blocks: TemplateBlockState[];
    editable: boolean;
    initialContent?: RemirrorJSON;
    onChange?: (content: string) => void;
}) {
    const { logger } = useTelemetryContext();

    const onError: InvalidContentHandler = useCallback(({ json, invalidContent, transformers }) => {
        // Automatically remove all invalid nodes and marks.
        return transformers.remove(json, invalidContent);
    }, []);

    const { manager, getContext, state } = useRemirror({
        extensions: () => [
            ...getDefaultProseMirrorExtensions(),
            new MetricBoxNodeReact({ disableExtraAttributes: true }),
            new ChartNodeReact({ disableExtraAttributes: true }),
            new InsightPillNodeReact({ disableExtraAttributes: true }),
            new GroupedInsightPillNodeReact({ disableExtraAttributes: true }),
            new InlineInsightPillNodeReact({ disableExtraAttributes: true }),
            new TaskPreviewNode({ disableExtraAttributes: true }),
        ],
        content: props.initialContent,
        onError,
    });

    // Debug: use this to print the schema
    // useEffect(() => {
    //     const spec: {
    //         nodes: Record<string, Schema['nodes']>;
    //         marks: Record<string, Schema['marks']>;
    //     } = {
    //         nodes: {},
    //         marks: {},
    //     };
    //     state.schema.spec.nodes.forEach((key, value) => (spec.nodes[key] = value));
    //     state.schema.spec.marks.forEach((key, value) => (spec.marks[key] = value));
    //     const schemaToSerialize = JSON.stringify(spec);

    //     console.log(schemaToSerialize);
    // }, [state]);

    useEffect(() => {
        const context = getContext();
        if (context && props.blocks.length > 0) {
            const cmd: CommandFunction = (cmdArgs) => {
                if (!cmdArgs.dispatch) {
                    return false;
                }
                const parser = new FrontendSummarizedActivityNodeParser();

                const fragments: Fragment[] = [];
                props.blocks.forEach((block) => {
                    if (block.type === BlockType.Text) {
                        const res = parser.toProseMirrorNodes(
                            undefined,
                            { type: 'markdown', text: block.text || '' },
                            cmdArgs.state.schema,
                            (_nodeName, message) => logger.error(`Failed parsing prosemirror node: ${message}`),
                        );
                        fragments.push(res);
                        return;
                    }

                    if (!block.summary) {
                        return;
                    }

                    const res = parser.toProseMirrorNodes(
                        block.title,
                        block.summary.type === 'activity'
                            ? { type: 'activity', activity: block.summary.activity }
                            : block.summary.type === 'charts'
                            ? { type: 'charts', chart: block.summary.charts }
                            : {
                                  type: 'indicator',
                                  indicator: block.selectedInsightIds
                                      ? {
                                            groupedInsights: block.summary.indicators.groupedInsights.filter((gi) =>
                                                block.selectedInsightIds.includes(gi.identifier),
                                            ),
                                            insights: block.summary.indicators.insights.filter((ind) =>
                                                block.selectedInsightIds.includes(ind.identifier),
                                            ),
                                            items: block.summary.indicators.items,
                                            metricBoxes: block.summary.indicators.metricBoxes.filter((mb) =>
                                                block.selectedInsightIds.includes(mb.identifier),
                                            ),
                                        }
                                      : block.summary.indicators,
                              },
                        cmdArgs.state.schema,
                        (_nodeName, message) => logger.error(`Failed parsing prosemirror node: ${message}`),
                    );
                    fragments.push(res);
                });

                let combinedFragment: Fragment = Fragment.empty;
                fragments.forEach((fragment) => {
                    combinedFragment = combinedFragment.append(fragment);
                });

                cmdArgs.tr.delete(0, cmdArgs.tr.doc.nodeSize - 2);
                cmdArgs.tr.insert(0, combinedFragment);
                cmdArgs.dispatch(cmdArgs.tr);
                return true;
            };
            context.commands.customDispatch(cmd);
        }
    }, [getContext, logger, props.blocks]);

    return (
        <ProseWrapper>
            <Remirror
                manager={manager}
                initialContent={state}
                autoRender={'start'}
                editable={props.editable}
                onChange={(params) =>
                    props.onChange ? props.onChange(JSON.stringify(params.state.doc.toJSON())) : undefined
                }
            />
        </ProseWrapper>
    );
}
