// Copyright 2021
// ThatWorks.xyz Limited

import { URLSearchParams } from 'url';
import { AxiosKeepAlive, defaultAxiosRetryConfig } from '@thatworks/axios-utils';
import { getExceptionMessage, getStatusFromException } from '@thatworks/http-errors';
import { Logger } from '@thatworks/node-logger';
import { ConnectorPermissionStatus } from '@thatworks/shared-frontend/connectors';
import { Capitalize } from '@thatworks/string-utils';
import { AxiosInstance, AxiosRequestHeaders } from 'axios';
import { buildKeyGenerator, setupCache } from 'axios-cache-interceptor';
import axiosRetry from 'axios-retry';

/**
 * Connector names
 * Use linked#parentName#childName to specify the parent linked name of any connector. E.g. linked#atlassian#confluence
 */
export enum ConnectorName {
    GITHUB = 'github',
    BITBUCKET = 'bitbucket',
    GOOGLE = 'google',
    GOOGLE_ANALYTICS = 'linked#google#analytics',
    GOOGLE_DRIVE = 'linked#google#drive',
    ASANA = 'asana',
    MONDAY = 'monday',
    ATLASSIAN = 'atlassian',
    NOTION = 'notion',
    CLICKUP = 'clickup',
    FIGMA = 'figma',
    MIRO = 'miro',
    ATLASSIAN_JIRA = 'linked#atlassian#jira',
    ATLASSIAN_CONFLUENCE = 'linked#atlassian#confluence',
    TOGGL = 'toggl',
    LINEAR = 'linear',
    SLACK = 'slack',
    HUBSPOT = 'hubspot',
}

export interface ConnectorNameWithUserId {
    name: ConnectorName;
    connectorUserId: string;
}

export function getParentLinkedConnector(connectorName: ConnectorName): ConnectorName | undefined {
    const split = connectorName.split('#');
    if (split.length !== 3) {
        return undefined;
    }

    const parent = split[1];
    if (!Object.values<string>(ConnectorName).includes(parent)) {
        return undefined;
    }
    return parent as ConnectorName;
}

export function getChildLinkedConnectors(connectorName: ConnectorName): ConnectorName[] {
    const res: ConnectorName[] = [];

    Object.values(ConnectorName).forEach((c) => {
        if (c === connectorName) {
            return;
        }

        const link = getParentLinkedConnector(c);
        if (link === connectorName) {
            res.push(c);
        }
    });

    return res;
}

export enum PreAuthUserInputFieldType {
    StringArray = 'string_array',
}

export interface ConnectorWebhook {
    userId: string;
    connector: ConnectorName;
    linkedConnector?: ConnectorName;
    connectorUserId: string;
    connectorWebhookId: string;
    connectorWebhookSecret: string;
    date: Date;
}

export function isConnectorNameValid(name?: string): boolean {
    if (!name) {
        return false;
    }
    return Object.values<string>(ConnectorName).includes(name);
}

export function getFormattedConnectorName(name: ConnectorName | string) {
    if (!Object.values<string>(ConnectorName).includes(name)) {
        return Capitalize(name);
    }

    switch (name as ConnectorName) {
        case ConnectorName.GITHUB:
            return 'Github';
        case ConnectorName.BITBUCKET:
            return 'Bitbucket';
        case ConnectorName.GOOGLE:
            return 'Google';
        case ConnectorName.GOOGLE_DRIVE:
            return 'Google Drive';
        case ConnectorName.GOOGLE_ANALYTICS:
            return 'Google Analytics';
        case ConnectorName.ASANA:
            return 'Asana';
        case ConnectorName.MONDAY:
            return 'Monday';
        case ConnectorName.ATLASSIAN:
            return 'Atlassian';
        case ConnectorName.NOTION:
            return 'Notion';
        case ConnectorName.CLICKUP:
            return 'ClickUp';
        case ConnectorName.FIGMA:
            return 'Figma';
        case ConnectorName.MIRO:
            return 'Miro';
        case ConnectorName.ATLASSIAN_CONFLUENCE:
            return 'Confluence';
        case ConnectorName.ATLASSIAN_JIRA:
            return 'Jira';
        case ConnectorName.TOGGL:
            return 'Toggl';
        case ConnectorName.LINEAR:
            return 'Linear';
        case ConnectorName.SLACK:
            return 'Slack';
        case ConnectorName.HUBSPOT:
            return 'HubSpot';
    }
}

export function isTaskManagement(name: ConnectorName): boolean {
    switch (name as ConnectorName) {
        case ConnectorName.GITHUB:
        case ConnectorName.BITBUCKET:
        case ConnectorName.GOOGLE:
        case ConnectorName.NOTION:
        case ConnectorName.FIGMA:
        case ConnectorName.MIRO:
        case ConnectorName.ATLASSIAN_CONFLUENCE:
        case ConnectorName.GOOGLE_ANALYTICS:
        case ConnectorName.GOOGLE_DRIVE:
        case ConnectorName.TOGGL:
        case ConnectorName.SLACK:
        case ConnectorName.HUBSPOT:
            return false;
        case ConnectorName.ASANA:
        case ConnectorName.MONDAY:
        case ConnectorName.ATLASSIAN:
        case ConnectorName.CLICKUP:
        case ConnectorName.ATLASSIAN_JIRA:
        case ConnectorName.LINEAR:
            return true;
    }
}

export function isDocumentStore(name: ConnectorName): boolean {
    switch (name as ConnectorName) {
        case ConnectorName.GOOGLE_DRIVE:
        case ConnectorName.NOTION:
        case ConnectorName.FIGMA:
        case ConnectorName.MIRO:
        case ConnectorName.ATLASSIAN_CONFLUENCE:
            return true;
        case ConnectorName.GITHUB:
        case ConnectorName.BITBUCKET:
        case ConnectorName.ASANA:
        case ConnectorName.MONDAY:
        case ConnectorName.ATLASSIAN:
        case ConnectorName.CLICKUP:
        case ConnectorName.ATLASSIAN_JIRA:
        case ConnectorName.GOOGLE:
        case ConnectorName.GOOGLE_ANALYTICS:
        case ConnectorName.TOGGL:
        case ConnectorName.LINEAR:
        case ConnectorName.SLACK:
        case ConnectorName.HUBSPOT:
            return false;
    }
}

export interface OAuth2Tokens {
    accessTokenType?: string;
    accessToken: string;
    updated: Date;
    refreshToken?: string;
    idToken?: string;
    expiresIn?: number;
    scope?: string;
    data?: unknown;
}

export interface Connector {
    name: ConnectorName;
    connectorUserId: string;
    accountDisplayName: string;
    oauth2: OAuth2Tokens;
    preAuthUserInput?: {
        field?: {
            type: PreAuthUserInputFieldType.StringArray;
            arrayValues: string[];
        };
        fields?: {
            type: string;
            key: string;
            label: string;
            value: string;
        }[];
    };
    linked?: ConnectorName[];
    /**
     * @deprecated use OrgWideConnection
     */
    orgWideId?: string;
}

export interface ConnectorProperties {
    supportsRefreshToken: boolean;
    additionalRequestHeaders?: Record<string, string | number | boolean>;
    supportsWebhooks: boolean;
}

export interface ConnectPreAuthInput {
    field?: {
        type: PreAuthUserInputFieldType.StringArray;
        arrayValues: string[];
    };
    fields?: {
        type: string;
        label: string;
        key: string;
        value: string;
    }[];
}

export interface ConnectorApiParams {
    name: ConnectorName;
    oauth2: OAuth2Tokens;
    props: ConnectorProperties;
    connectorUserId: string;
    accountDisplayName: string;
    preAuthUserInput?: ConnectPreAuthInput;
    error?: {
        code: number;
        message: string;
    };
    linked?: ConnectorName[];
}

export interface ConnectRequestBody {
    preAuthInput?: ConnectPreAuthInput;
}

const AUTH_HEADER_KEY = 'Authorization';

export type RefreshToken = (
    name: ConnectorName,
    connectorUserId: string,
    existingAuth: OAuth2Tokens,
) => Promise<OAuth2Tokens>;

export class ConnectorApi {
    private _name: ConnectorName;
    private _oauth2: OAuth2Tokens;
    private readonly _refreshTokenFunc: RefreshToken;
    private readonly _props: ConnectorProperties;
    private _preAuthUserInput: ConnectorApiParams['preAuthUserInput'];
    private _connectorUserId: string;
    private _axios: AxiosInstance;

    constructor(
        params: ConnectorApiParams,
        refreshTokenFunc: RefreshToken,
        axiosInstance = AxiosKeepAlive.Instance.createAxios(),
        log?: Logger,
    ) {
        this._name = params.name;
        this._oauth2 = params.oauth2;
        this._refreshTokenFunc = refreshTokenFunc;
        this._props = params.props;
        this._preAuthUserInput = params.preAuthUserInput;
        this._connectorUserId = params.connectorUserId;
        this._axios = axiosInstance;

        const cacheTtlMins = 15;
        setupCache(this._axios, {
            ttl: 1000 * 60 * cacheTtlMins,
            interpretHeader: false, // ignore any caching hints by the connector/app
            generateKey: buildKeyGenerator((request) => {
                return {
                    uri: this._axios.getUri(request),
                    method: request.method?.toLowerCase() || 'get',
                    data: request.data,
                    connectorUserId: params.connectorUserId,
                };
            }),
        });

        axiosRetry(this._axios, defaultAxiosRetryConfig);

        if (log) {
            this._axios.interceptors.request.use((request) => {
                const uri = this._axios.getUri(request);
                log.info(`${this._connectorUserId} ${request.method?.toUpperCase()} ${uri}`);
                return request;
            });
        }
    }

    get name(): ConnectorName {
        return this._name;
    }

    get oauth2Tokens(): OAuth2Tokens {
        return this._oauth2;
    }

    get preAuthUserInput(): ConnectorApiParams['preAuthUserInput'] {
        return this._preAuthUserInput;
    }

    get connectorUserId(): string {
        return this._connectorUserId;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async get<T = any>(url: string, params?: URLSearchParams, opts?: { timeoutMs?: number }) {
        await this.refreshTokenIfNeeded();
        return await this._axios.get<T>(url, {
            headers: this.getDefaultHeaders(),
            params,
            timeout: opts?.timeoutMs,
        });
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async post<T = any>(url: string, data: unknown, params?: URLSearchParams) {
        await this.refreshTokenIfNeeded();
        return await this._axios.post<T>(url, data, {
            headers: this.getDefaultHeaders(),
            params,
        });
    }

    async delete(url: string, params?: URLSearchParams, data?: unknown) {
        await this.refreshTokenIfNeeded();
        return await this._axios.delete(url, {
            headers: this.getDefaultHeaders(),
            params,
            data,
        });
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async put<T = any>(url: string, data: unknown, params?: URLSearchParams) {
        await this.refreshTokenIfNeeded();
        return await this._axios.put<T>(url, data, {
            headers: this.getDefaultHeaders(),
            params,
        });
    }

    /**
     * Post a graphql request
     * @param url endpoint
     * @param query GraphQL query starting with "query". Eg: `query { me { id}}`
     * @returns axios response
     * @throws if a graphql error is detected
     */
    async postGraphQL<T extends { data: unknown; errors?: Array<{ message: string }> }>(url: string, query: string) {
        const res = await this.post<T>(url, { query });
        const statusOK = res.status === 200;
        const dataOK = res.data.data !== undefined;
        if (!statusOK || !dataOK) {
            const error = res.data.errors?.[0]?.message || 'GraphQL error';
            throw new Error(error);
        }
        return res;
    }

    static hasTokenExpired(tokens: OAuth2Tokens): boolean {
        if (!tokens.refreshToken || tokens.expiresIn === undefined) {
            return false;
        }

        const bufferSeconds = 5 * 60;
        const expiry = new Date(tokens.updated);
        expiry.setSeconds(expiry.getSeconds() + (tokens.expiresIn - bufferSeconds));
        const now = new Date();
        return now >= expiry;
    }

    async refreshTokenIfNeeded(): Promise<void> {
        if (!this._props.supportsRefreshToken) {
            return;
        }

        if (!ConnectorApi.hasTokenExpired(this._oauth2)) {
            return;
        }

        const refreshedTokens = await this._refreshTokenFunc(this._name, this._connectorUserId, this._oauth2);
        this._oauth2 = refreshedTokens;
    }

    getDefaultHeaders(): AxiosRequestHeaders {
        const auth: AxiosRequestHeaders = {};
        auth[AUTH_HEADER_KEY] = `${Capitalize(this._oauth2.accessTokenType || 'Bearer')} ${this._oauth2.accessToken}`;
        const additional = this._props.additionalRequestHeaders || undefined;
        return { ...auth, ...additional };
    }

    static getApisFromConnectors(params: ConnectorApiParams[], refreshTokenFunc: RefreshToken): ConnectorApi[] {
        const res: ConnectorApi[] = [];
        for (const p of params) {
            const api = new ConnectorApi(p, refreshTokenFunc);
            res.push(api);
        }
        return res;
    }
}

export enum ConnectorApiParamError {
    INVALID_PERMISSIONS,
    GENERIC_FAILURE,
    INVALID_CREDENTIALS,
}

export function parseErrorFromException(error: unknown): {
    paramError: ConnectorApiParamError;
    message: string;
    type: 'warn' | 'error';
    status: number;
} {
    const status = getStatusFromException(error);
    const msg = getExceptionMessage(error);
    let type: 'warn' | 'error' = 'warn';
    let summaryError = ConnectorApiParamError.GENERIC_FAILURE;
    if (status.toString() === '403') {
        summaryError = ConnectorApiParamError.INVALID_PERMISSIONS;
        type = 'warn';
    } else if (status.toString() === '400') {
        // To deal with google's case of an invalid token
        if (msg.includes('invalid_grant')) {
            summaryError = ConnectorApiParamError.INVALID_CREDENTIALS;
            type = 'warn';
        } else {
            summaryError = ConnectorApiParamError.GENERIC_FAILURE;
            type = 'error';
        }
    } else if (status.toString() === '401') {
        summaryError = ConnectorApiParamError.INVALID_CREDENTIALS;
        type = 'warn';
    } else {
        summaryError = ConnectorApiParamError.GENERIC_FAILURE;
        type = 'error';
    }
    return { paramError: summaryError, message: msg, type, status: Number(status) };
}

export function parseErrorFromExceptionAndLog(error: unknown, log: Logger): ConnectorApiParamError {
    const res = parseErrorFromException(error);
    if (res.type === 'error') {
        log.error(res.message);
    } else {
        log.warn(res.message);
    }
    return res.paramError;
}

export function parseParamErrorCodeAndLog(param: ConnectorApiParams, log: Logger): ConnectorApiParamError {
    let error = ConnectorApiParamError.GENERIC_FAILURE;
    if (param.error?.code === 403) {
        error = ConnectorApiParamError.INVALID_PERMISSIONS;
        log.warn(`Summary INVALID_PERMISSIONS error: (${param.name})"${param.error.message}"`);
    } else if (param.error?.code === 401) {
        error = ConnectorApiParamError.INVALID_CREDENTIALS;
        log.warn(`Summary INVALID_CREDENTIALS error: (${param.name})"${param.error.message}"`);
    } else {
        error = ConnectorApiParamError.GENERIC_FAILURE;
        log.error(`Summary GENERIC_FAILURE error: (${param.name})"${param.error?.message}"`);
    }
    return error;
}

export interface ConnectorPermissionStatusResponse {
    status: ConnectorPermissionStatus | undefined;
    connector: ConnectorName | undefined;
    connectorUserId: string | undefined;
}
