// Copyright 2021
// ThatWorks.xyz Limited

import { DOMCompatibleAttributes, DOMOutputSpec, ExtensionTag, NodeExtension, NodeExtensionSpec } from '@remirror/core';
import { Colors } from '@thatworks/colors';
import { getFormattedConnectorName } from '@thatworks/connector-api';
import { ExtensionTagType, ValidOptions } from 'remirror';
import { v4 } from 'uuid';
import { InlineInsightPillNodeDataBase, PmNodeNames } from './prosemirror';

export interface BasePmNodeAttributes {
    uuid: string;
}

export abstract class BasePmNode<
    Attributes extends BasePmNodeAttributes,
    InitOptions extends ValidOptions = {},
> extends NodeExtension<InitOptions> {
    abstract get name(): string;
    abstract getDefaultAttributes(): Attributes;
    abstract get content(): string | undefined;
    nodeSpecOverride?(): NodeExtensionSpec | undefined;
    createNodeSpecBackendOverride?(): NodeExtensionSpec | undefined;

    createNodeSpec(): NodeExtensionSpec {
        const defaultProps = this.getDefaultAttributes();
        const defaults: NodeExtensionSpec['attrs'] = {};
        Object.entries(defaultProps).forEach(([k, v]) => {
            defaults[k] = { default: v };
        });

        return {
            attrs: defaults,
            content: this.content,
            toDOM: (node) => {
                return this.content
                    ? ['div', this.attributesToDom(node.attrs as Attributes), 0]
                    : ['div', this.attributesToDom(node.attrs as Attributes)];
            },
            parseDOM: [
                {
                    tag: `div[${BasePmNode.getDomIdKey(this.name)}]`,
                    getAttrs: (dom) => {
                        const node = dom as HTMLAnchorElement;
                        return this.domToAttributes(node);
                    },
                },
            ],
            ...this.nodeSpecOverride?.(),
        };
    }

    attributesToDom(att: Attributes): DOMCompatibleAttributes {
        const d = BasePmNode.getNodeDataAttribute(this.name, att);
        const res: DOMCompatibleAttributes = {};
        res[d.name] = d.value;
        return res;
    }

    domToAttributes(node: HTMLAnchorElement): Attributes | null {
        const data = node.getAttribute(BasePmNode.getDomDataKey(this.name));
        if (!data) {
            return null;
        }
        try {
            return JSON.parse(data) as Attributes;
        } catch (error) {
            // TODO log
            return null;
        }
    }

    createTags(): ExtensionTagType[] {
        return [ExtensionTag.Block];
    }

    static getDomDataKey(nodeName: string) {
        return `data-${nodeName}-data`;
    }

    static getDomIdKey(nodeName: string) {
        return `data-${nodeName}-uuid`;
    }

    static getNodeDataAttribute(nodeName: string, attr: unknown): { name: string; value: string } {
        return {
            name: BasePmNode.getDomDataKey(nodeName),
            value: JSON.stringify(attr),
        };
    }
}

// ----------------

export interface InlineInsightPillAttributes<TaskPreviewInfoType> extends BasePmNodeAttributes {
    data: InlineInsightPillNodeDataBase<TaskPreviewInfoType> | undefined;
}

export class InlineInsightPillNode<TaskPreviewInfoType> extends BasePmNode<
    InlineInsightPillAttributes<TaskPreviewInfoType>
> {
    static get nodeName() {
        return PmNodeNames.InlineInsightPill;
    }

    get name(): string {
        return PmNodeNames.InlineInsightPill as const;
    }

    getDefaultAttributes(): InlineInsightPillAttributes<TaskPreviewInfoType> {
        return {
            uuid: v4(),
            data: undefined,
        };
    }

    get content(): string | undefined {
        return undefined;
    }

    createTags() {
        return [ExtensionTag.InlineNode];
    }

    createNodeSpecBackendOverride(): NodeExtensionSpec {
        return {
            ...this.createNodeSpec(),
            toDOM: (node) => {
                const att = node.attrs as unknown as InlineInsightPillAttributes<TaskPreviewInfoType>;
                let text = att.data?.value || '';
                if (att.data?.connector) {
                    text = `${getFormattedConnectorName(att.data.connector)} ${text}`;
                }

                const domAtts = this.attributesToDom(
                    node.attrs as unknown as InlineInsightPillAttributes<TaskPreviewInfoType>,
                );
                domAtts.class = this.name;
                // Formatting for this will be handled in the markdown parser
                return ['span', domAtts, `${text}`];
            },
            group: 'inline',
        };
    }

    createNodeSpec(): NodeExtensionSpec {
        const defaultProps = this.getDefaultAttributes();
        const defaults: NodeExtensionSpec['attrs'] = {};
        Object.entries(defaultProps).forEach(([k, v]) => {
            defaults[k] = { default: v };
        });

        return {
            attrs: defaults,
            parseDOM: [
                {
                    tag: `span[${BasePmNode.getDomIdKey(this.name)}]`,
                    getAttrs: (dom) => {
                        const node = dom as HTMLAnchorElement;
                        return this.domToAttributes(node);
                    },
                },
            ],
            toDOM: (node) => {
                return [
                    'span',
                    this.attributesToDom(node.attrs as unknown as InlineInsightPillAttributes<TaskPreviewInfoType>),
                    0,
                ];
            },

            inline: true,
            atom: true,
        };
    }
}

// --------------------

export const PM_NODE_NAME_DOM_ATTRIBUTE = 'pmNodeName';

export class InlineInsightPillNodeEmail<TaskPreviewInfoType> extends InlineInsightPillNode<TaskPreviewInfoType> {
    createNodeSpecBackendOverride(): NodeExtensionSpec {
        return {
            ...this.createNodeSpec(),
            toDOM: (node) => {
                // recreate the react node look but obviously without the hover intractions

                const att = node.attrs as unknown as InlineInsightPillAttributes<TaskPreviewInfoType>;
                const text = att.data?.value || '';
                const iconUrl = att.data?.iconUrl || 'none';

                const imgTdAtts: DOMCompatibleAttributes = { style: 'vertical-align: top; padding-top: 2px;' };
                imgTdAtts[PM_NODE_NAME_DOM_ATTRIBUTE] = this.name;
                const textTdAtts: DOMCompatibleAttributes = { style: 'vertical-align: middle;' };
                textTdAtts[PM_NODE_NAME_DOM_ATTRIBUTE] = this.name;

                // Return a table so it works well with email clients
                return [
                    'table',
                    {
                        style: `background-color: ${Colors.light_6}; border-radius: 6px; padding: 4px 4px; display: inline-flex; align-items: center; font-size: 14px; margin-bottom: 4px;`,
                    },
                    [
                        'tr',
                        {},
                        ['td', imgTdAtts, ['img', { src: iconUrl, width: '14', height: '14' }]],
                        ['td', textTdAtts, ['span', { style: 'font-size: 13px' }, text]],
                    ],
                ];
            },
            group: 'inline',
        };
    }
}

// --------------------

export interface MetricBoxAttributes extends BasePmNodeAttributes {
    /**
     * @deprecated For older schemas that used serialized json
     */
    data: string;
    metrics: {
        connector?: string;
        identifier: string;
        parents: { connectorObjectType: string; url?: string; name: string }[];
        itemUuid?: string;
        title: string;
        value: number;
        valueFormatted: string;
        xAxisValue?: { type: 'date-iso'; value: string };
    }[];
}

export class MetricBoxNode extends BasePmNode<MetricBoxAttributes> {
    static get nodeName() {
        return PmNodeNames.MetricBox;
    }

    get name(): string {
        return PmNodeNames.MetricBox as const;
    }

    getDefaultAttributes(): MetricBoxAttributes {
        return {
            uuid: v4(),
            data: '',
            metrics: [],
        };
    }

    get content(): string | undefined {
        return undefined;
    }

    nodeSpecOverride(): NodeExtensionSpec | undefined {
        return {
            atom: true,
        };
    }

    createNodeSpecBackendOverride(): NodeExtensionSpec {
        return {
            ...this.createNodeSpec(),
            atom: true,
            toDOM: (node) => {
                const att = node.attrs as unknown as MetricBoxAttributes;
                const domAtts = this.attributesToDom(node.attrs as unknown as MetricBoxAttributes);
                domAtts.class = this.name;
                if (att.metrics && Array.isArray(att.metrics)) {
                    const indicators = att.metrics.map((m) => `${m.title}: ${m.valueFormatted}`);
                    const res: DOMOutputSpec = ['p', domAtts];
                    indicators.forEach((i) => {
                        res.push(['span', i]);
                        res.push(['br']);
                    });
                    return res;
                } else {
                    // backward compatibility for older posts that had data as a string
                    const text = '(not supported)';
                    return ['p', domAtts, text];
                }
            },
            group: 'block',
        };
    }
}

// ---------------

export interface ChartNodeAttributes extends BasePmNodeAttributes {
    data: string;
}

export class ChartNode extends BasePmNode<ChartNodeAttributes> {
    static get nodeName() {
        return PmNodeNames.Chart;
    }

    get name(): string {
        return PmNodeNames.Chart as const;
    }

    getDefaultAttributes(): ChartNodeAttributes {
        return {
            uuid: v4(),
            data: '',
        };
    }

    get content(): string | undefined {
        return undefined;
    }

    nodeSpecOverride(): NodeExtensionSpec | undefined {
        return {
            atom: true,
        };
    }

    createNodeSpecBackendOverride(): NodeExtensionSpec {
        return {
            ...this.createNodeSpec(),
            atom: true,
            toDOM: (node) => {
                const att = node.attrs as unknown as ChartNodeAttributes;
                const domAtts = this.attributesToDom(node.attrs as unknown as ChartNodeAttributes);
                domAtts.class = this.name;
                // TODO TWD-802: display charts as images
                const text = '(not supported)';
                return ['p', domAtts, text];
            },
            group: 'block',
        };
    }
}

// ---------------

export interface InsightAttributes<InsightData> extends BasePmNodeAttributes {
    data: InsightData[];
}

export class InsightPillNode<InsightData> extends BasePmNode<InsightAttributes<InsightData>> {
    static get nodeName() {
        return PmNodeNames.InsightPill;
    }

    get name(): string {
        return PmNodeNames.InsightPill as const;
    }

    getDefaultAttributes(): InsightAttributes<InsightData> {
        return {
            uuid: v4(),
            data: [],
        };
    }

    get content(): string | undefined {
        return undefined;
    }

    nodeSpecOverride(): NodeExtensionSpec | undefined {
        return {
            atom: true,
        };
    }

    createNodeSpecBackendOverride(): NodeExtensionSpec {
        return {
            ...this.createNodeSpec(),
            atom: true,
            toDOM: (node) => {
                const att = node.attrs as unknown as InsightAttributes<InsightData>;
                const domAtts = this.attributesToDom(node.attrs as unknown as InsightAttributes<InsightData>);
                domAtts.class = this.name;
                // TODO TWD-802: display charts/insights as images
                const text = '';
                return ['p', domAtts, text];
            },
            group: 'block',
        };
    }
}

// ---------------

export interface GroupedInsightAttributes<InsightData> extends BasePmNodeAttributes {
    data: InsightData | undefined;
}

export class GroupedInsightPillNode<InsightData> extends BasePmNode<GroupedInsightAttributes<InsightData>> {
    static get nodeName() {
        return PmNodeNames.GroupedInsightPill;
    }

    get name(): string {
        return PmNodeNames.GroupedInsightPill as const;
    }

    getDefaultAttributes(): GroupedInsightAttributes<InsightData> {
        return {
            uuid: v4(),
            data: undefined,
        };
    }

    get content(): string | undefined {
        return undefined;
    }

    nodeSpecOverride(): NodeExtensionSpec | undefined {
        return {
            atom: true,
        };
    }

    createNodeSpecBackendOverride(): NodeExtensionSpec {
        return {
            ...this.createNodeSpec(),
            atom: true,
            toDOM: (node) => {
                const att = node.attrs as unknown as GroupedInsightAttributes<InsightData>;
                const domAtts = this.attributesToDom(node.attrs as unknown as GroupedInsightAttributes<InsightData>);
                domAtts.class = this.name;
                // TODO TWD-802: display charts/insights as images
                const text = '';
                return ['p', domAtts, text];
            },
            group: 'block',
        };
    }
}
