// Copyright 2021
// ThatWorks.xyz Limited

import { useLazyQuery, useMutation } from '@apollo/client';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Colors } from '@thatworks/colors';
import { ConnectorName } from '@thatworks/connector-api';
import { Box, Spinner, Text } from 'grommet';
import { Down, FormTrash, Up } from 'grommet-icons';
import objectHash from 'object-hash';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { gql } from '../../../../../__generated__';
import {
    ActivityItemFilterOperator,
    ConnectorScopeInput,
    GetTimelineMetricChartsQuery,
    GraphFilterType,
    GroupSettingsInput,
    ItemGroupType,
    SummarizationSettingsInput,
    SummarizationStyle,
    TimelineActivityQuery,
    TimelineIndicatorsQuery,
} from '../../../../../__generated__/graphql';
import { IconButtonV2 } from '../../../../../components/IconButton';
import { HoverPlainTextInput } from '../../../../../components/PlainTextInput';
import { useTelemetryContext } from '../../../../../components/TelemetryContext';
import { TextEditorMarkdown } from '../../../../../components/TextEditorMarkdown';
import { useUserStateContext } from '../../../../../components/UserContext';
import { VerticalSixDots } from '../../../../../icons/VerticalSixDots';
import { FontFamily } from '../../../../../theme';
import { getActivityFilters } from '../filters/activity-property-helpers';
import { GET_TIMELINE_CHARTS, GET_TIMELINE_INDICATORS } from '../filters/ActivityLists';
import { ActivityPresetsButton } from '../filters/ActivityPresetsButton';
import { PropertyFilterGroup } from '../filters/filter-toolbar-button/helpers';
import { DEFAULT_GROUP_SETTINGS } from '../filters/GroupToolbarButton';
import { SearchItemsToolbarButton } from '../filters/SearchItemsToolbarButton';
import { getSummarySettingsForSummaryStyle } from '../filters/SummaryStyleButton';
import {
    DatePreset,
    TimelineDateSelection,
    timelineDateSelectionToTimelineCreateDate,
} from '../filters/timeline-date-selection';
import { TimeSelectionToolbarButton } from '../filters/TimeSelectionToolbarButton';
import { getActivityQueryVars, getFiltersForPreset, getPresetTitle, PresetFilter } from '../helpers/preset-filters';

const GET_ACTIVITY = gql(/* GraphQL */ `
    query TimelineActivity(
        $idForCache: ID!
        $timelineId: String!
        $sort: ItemSort
        $grouping: GroupSettingsInput
        $filters: ActivityItemFiltersInput
        $summarize: TimelineActivitySummarizationInput
    ) {
        timelineActivity(
            idForCache: $idForCache
            timelineId: $timelineId
            sort: $sort
            grouping: $grouping
            filters: $filters
            summarize: $summarize
        ) {
            title
            groups {
                type
                subgroups {
                    ids
                    name
                    props {
                        ... on ItemSubgroupTaskStatus {
                            isDoneStatus
                            sortOrder
                            status
                        }
                    }
                    summary {
                        summary {
                            sections {
                                markdown
                                pills {
                                    value
                                    color
                                    iconUrl
                                    itemUuids
                                    connector
                                }
                                newRowForPills
                            }
                        }
                    }
                }
            }
            items {
                title
                id
                iconUrl
                connector
                url
                actors {
                    names {
                        color
                        isUser
                        name
                    }
                    hasMore
                }
                parents {
                    readableConnectorObjectType
                    name
                    url
                }
                properties {
                    color
                    name
                    value
                    iconUrl
                    valueType
                    propertyType
                }
                changeDescription {
                    color
                    decoration
                    value
                }
                timeRange {
                    newest
                    oldest
                }
                docDiff {
                    ... on DocDiffSummarized {
                        summary
                    }
                }
                comments {
                    comments {
                        authorDisplayName
                        comment
                        date
                        userMention
                    }
                    more
                    total
                }
            }
        }
    }
`);

const CREATE_TIMELINE_FROM_SCOPES = gql(/* GraphQL */ `
    mutation GetTimeFromScopes(
        $scopes: [ConnectorScopeInput!]!
        $fromDate: TimelineCreateDate!
        $cacheSessionKey: String!
    ) {
        timelineCreateFromScopes(scopes: $scopes, fromDate: $fromDate, cacheSessionKey: $cacheSessionKey)
    }
`);

export type TemplateBlockSummaryData =
    | { type: 'activity'; activity: TimelineActivityQuery['timelineActivity'] }
    | { type: 'indicators'; indicators: TimelineIndicatorsQuery['timelineIndicators'] }
    | { type: 'charts'; charts: GetTimelineMetricChartsQuery['timelineMetricCharts'] };

export enum BlockType {
    Query = 'query_block',
    Text = 'text_block',
}

interface BlockStateBase<T extends string> {
    type: T;
    id: string;
}

export interface TextTemplateBlockState extends BlockStateBase<BlockType.Text> {
    text: string | undefined;
}

export interface QueryTemplateBlockState extends BlockStateBase<BlockType.Query> {
    preset: PresetFilter | undefined;
    propertyFilterGroups: PropertyFilterGroup[];
    filtersOperator: ActivityItemFilterOperator;
    graphFilterType: GraphFilterType;
    groupSettings: GroupSettingsInput;
    title: string;
    selectedScopes: ConnectorScopeInput[];
    dateSelection: TimelineDateSelection;
    summary: TemplateBlockSummaryData | undefined;
    timelineId: string | undefined;
    summarizationCustomSettings: SummarizationSettingsInput;
    selectedInsightIds: string[];
}

export type TemplateBlockState = QueryTemplateBlockState | TextTemplateBlockState;

// '*' is a special value so the ui starts with selecting all indicators by default
export const SHOW_ALL_INSIGHTS_SPECIAL_CASE_ID = '*';

export function getDefaultQueryTemplateBlockProps(props?: Partial<QueryTemplateBlockState>): QueryTemplateBlockState {
    const res: QueryTemplateBlockState = {
        type: BlockType.Query,
        groupSettings: DEFAULT_GROUP_SETTINGS,
        dateSelection: { preset: DatePreset.OneWeek, customDaysStr: '' },
        graphFilterType: GraphFilterType.Full,
        selectedScopes: [],
        ...props,
        preset: undefined,
        propertyFilterGroups: [],
        filtersOperator: ActivityItemFilterOperator.Or,
        id: Math.random().toString(36).substring(7),
        title: '',
        summary: undefined,
        timelineId: undefined,
        summarizationCustomSettings: getSummarySettingsForSummaryStyle(SummarizationStyle.Highlights),
        selectedInsightIds: [SHOW_ALL_INSIGHTS_SPECIAL_CASE_ID],
    };
    return res;
}

export function getDefaultTextTemplateBlockProps(props?: Partial<TextTemplateBlockState>): TextTemplateBlockState {
    const res: TextTemplateBlockState = {
        type: BlockType.Text,
        text: undefined,
        ...props,
        id: Math.random().toString(36).substring(7),
    };
    return res;
}

function TemplateBlockBaseComponent<X extends string, T extends BlockStateBase<X>>(props: {
    sessionCacheId: string;
    state: T;
    onUpdateState: (state: T) => void;
    onDelete: () => void;
    setActiveBlock: (active: boolean) => void;
    onLoadingSummary: (loading: boolean) => void;
    onLoadingTimeline: (loading: boolean) => void;
    headerComponent: JSX.Element;
    bodyComponent: JSX.Element;
    active: boolean;
}): JSX.Element {
    const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
        id: props.state.id,
    });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
        zIndex: isDragging ? '100' : 'auto',
        opacity: isDragging ? 0.3 : 1,
    };

    // Return opened block
    if (props.active) {
        return (
            <Box
                background={{ color: Colors.background_front }}
                round={{ size: '15px' }}
                border={{ color: Colors.accent_3, size: '1px' }}
                gap="xsmall"
                pad="xsmall"
                ref={setNodeRef}
                style={style}
            >
                <Box direction="row" gap="xxsmall">
                    <IconButtonV2
                        icon={(hover) => (
                            <VerticalSixDots
                                size="22px"
                                color={hover ? Colors.brand : undefined}
                                {...listeners}
                                {...attributes}
                            />
                        )}
                    />
                    {props.headerComponent}
                    <IconButtonV2
                        icon={(hover) => (
                            <FormTrash
                                size="22px"
                                color={hover ? Colors.brand : undefined}
                                onClick={() => props.onDelete()}
                            />
                        )}
                    />
                    <IconButtonV2
                        icon={(hover) => (
                            <Up
                                size="22px"
                                color={hover ? Colors.brand : undefined}
                                onClick={() => props.setActiveBlock(false)}
                            />
                        )}
                    />
                </Box>
                {props.bodyComponent}
            </Box>
        );
    }

    // Return closed block
    return (
        <Box
            background={{ color: Colors.background_front }}
            round={{ size: '15px' }}
            pad="xsmall"
            direction="row"
            gap="xxsmall"
            ref={setNodeRef}
            style={style}
        >
            <IconButtonV2
                icon={(hover) => (
                    <VerticalSixDots
                        size="22px"
                        color={hover ? Colors.brand : undefined}
                        {...listeners}
                        {...attributes}
                    />
                )}
            />
            {props.headerComponent}
            <IconButtonV2
                icon={(hover) => (
                    <FormTrash size="22px" color={hover ? Colors.brand : undefined} onClick={() => props.onDelete()} />
                )}
            />
            <IconButtonV2
                icon={(hover) => (
                    <Down
                        size="22px"
                        color={hover ? Colors.brand : undefined}
                        onClick={() => props.setActiveBlock(true)}
                    />
                )}
            />
        </Box>
    );
}

function TextTemplateBlock(props: {
    sessionCacheId: string;
    state: TextTemplateBlockState;
    onUpdateState: (state: TextTemplateBlockState) => void;
    onDelete: () => void;
    setActiveBlock: (active: boolean) => void;
    onLoadingSummary: (loading: boolean) => void;
    onLoadingTimeline: (loading: boolean) => void;
    active: boolean;
}): JSX.Element {
    return (
        <TemplateBlockBaseComponent
            {...props}
            bodyComponent={
                <Box gap="xxsmall">
                    <TextEditorMarkdown
                        markdownContent={props.state.text}
                        onChange={(content) => props.onUpdateState({ ...props.state, text: content })}
                        placeholder="Add custom text with markdown formatting: use * for bullet points, ## for headings, and more."
                    />
                </Box>
            }
            headerComponent={
                <Text
                    color={Colors.black}
                    style={{
                        fontFamily: FontFamily.Callout,
                        fontSize: '16px',
                        background: 'unset',
                        padding: '2px 4px',
                        overflow: 'hidden',
                        resize: 'none',
                        width: '100%',
                    }}
                >
                    Custom Text
                </Text>
            }
        />
    );
}

function SummaryTemplateBlock(props: {
    sessionCacheId: string;
    state: QueryTemplateBlockState;
    onUpdateState: (state: QueryTemplateBlockState) => void;
    onDelete: () => void;
    setActiveBlock: (active: boolean) => void;
    onLoadingSummary: (loading: boolean) => void;
    onLoadingTimeline: (loading: boolean) => void;
    active: boolean;
}): JSX.Element {
    const { logger } = useTelemetryContext();
    const { postErrorMessage } = useUserStateContext();

    // Keep track of the last summarization request.
    // This is to ensure that previous summarization requests (e.g. when the user switches through lots of UI options)
    // don't overwrite the state with the wrong data.
    // Using a ref and not a state because we don't want any updates to the value delayed by a render cycle.
    const asyncSummaryId = useRef<string | undefined>(undefined);

    const [getSummarizedActivity, { loading: activityLoading }] = useLazyQuery(GET_ACTIVITY, {
        onError: ({ message, graphQLErrors }) => {
            if (graphQLErrors) {
                for (let err of graphQLErrors) {
                    switch (err.extensions?.code) {
                        case 'CACHE_INVALID':
                            getTimelineId();
                            return;
                    }
                }
            }

            postErrorMessage({ title: 'Error', shortDesc: `Failed to summarize` });
            logger.error(message);
        },
    });
    const [getIndicators, { loading: indicatorLoading }] = useLazyQuery(GET_TIMELINE_INDICATORS, {
        onError: (error) => {
            postErrorMessage({ title: 'Error', shortDesc: `Failed to get indicators` });
            logger.error(error.message);
        },
    });
    const [getCharts, { loading: chartsLoading }] = useLazyQuery(GET_TIMELINE_CHARTS, {
        onError: (error) => {
            postErrorMessage({ title: 'Error', shortDesc: `Failed to get charts` });
            logger.error(error.message);
        },
    });
    const [getConnectorsScopesTimeline, { loading: createTimelineLoading }] = useMutation(CREATE_TIMELINE_FROM_SCOPES, {
        onError: (error) => {
            props.onLoadingTimeline(false);
            postErrorMessage({ title: 'Error', shortDesc: `Failed to fetch timeline` });
            logger.error(error.message);
        },
    });

    const dataLoading = useMemo(() => {
        return activityLoading || indicatorLoading || chartsLoading;
    }, [activityLoading, indicatorLoading, chartsLoading]);

    const generateSummary = useCallback(
        async (timelineId: string, state: QueryTemplateBlockState) => {
            let summary: TemplateBlockSummaryData | undefined = undefined;
            let indicatorIds: string[] = [SHOW_ALL_INSIGHTS_SPECIAL_CASE_ID];
            let summaryId: string | undefined;
            if (timelineId) {
                // Indicators
                if (state.preset === PresetFilter.Indicators) {
                    // Get indicators
                    const variables = { timelineId: timelineId };
                    summaryId = objectHash(variables);
                    asyncSummaryId.current = summaryId;
                    const d = await getIndicators({ variables });

                    // If we've received indicators data, update the summary
                    if (d.data) {
                        summary = { type: 'indicators', indicators: d.data.timelineIndicators };
                        d.data.timelineIndicators.insights.forEach((insight) => indicatorIds.push(insight.identifier));
                        d.data.timelineIndicators.groupedInsights.forEach((groupedInsight) =>
                            indicatorIds.push(groupedInsight.identifier),
                        );
                        d.data.timelineIndicators.metricBoxes.forEach((metricBox) =>
                            indicatorIds.push(metricBox.identifier),
                        );
                    }
                }
                // Charts
                else if (state.preset === PresetFilter.Charts) {
                    // Get charts
                    const variables = { timelineId: timelineId };
                    summaryId = objectHash(variables);
                    asyncSummaryId.current = summaryId;
                    const d = await getCharts({ variables });

                    // If we've received charts data, update the summary
                    if (d.data) {
                        summary = { type: 'charts', charts: d.data.timelineMetricCharts };
                    }
                }
                // Other presets
                else if (state.propertyFilterGroups.length > 0 || state.preset) {
                    // Get activity filters
                    const f = getActivityQueryVars(
                        state.groupSettings,
                        getActivityFilters(
                            state.propertyFilterGroups,
                            state.filtersOperator,
                            state.graphFilterType,
                            state.dateSelection,
                        ),
                    );
                    // the variables are used to generate a unique id for the cache
                    // this way we can invalidate the cache when the variables change
                    // but also reuse the cache when the variables are the same
                    const variables = {
                        timelineId: timelineId,
                        filters: f.filters,
                        grouping: f.grouping || DEFAULT_GROUP_SETTINGS,
                        sort: f.sort,
                        summarize: {
                            summarize: true,
                            settings: state.summarizationCustomSettings,
                            //itemIds: [...selectedItems],
                        },
                    };
                    const idForCache = objectHash(variables);
                    summaryId = idForCache;
                    asyncSummaryId.current = summaryId;
                    // Get summarized acitivity
                    const d = await getSummarizedActivity({
                        variables: {
                            ...variables,
                            idForCache,
                        },
                    });

                    // If we've received summarized activity data, update the summary
                    if (d.data) {
                        summary = { type: 'activity', activity: d.data.timelineActivity };
                    }
                }
            } else if (state.title) {
                asyncSummaryId.current = summaryId;
                summary = { type: 'activity', activity: { groups: [], items: [] } };
            } else {
                asyncSummaryId.current = summaryId;
            }

            // Return summary
            return { summary, indicatorIds, summaryId };
        },
        [getCharts, getIndicators, getSummarizedActivity],
    );

    const onUpdateState = props.onUpdateState;
    const onLoadingSummary = props.onLoadingSummary;
    const generateSummaryAndNotify = useCallback(
        async (state: QueryTemplateBlockState) => {
            if (!state.timelineId) {
                onUpdateState({ ...state, summary: undefined });
                return;
            }

            onLoadingSummary(true);
            try {
                const summary = await generateSummary(state.timelineId, state);
                if (summary && summary.summaryId === asyncSummaryId.current) {
                    onUpdateState({ ...state, summary: summary.summary, selectedInsightIds: summary.indicatorIds });
                }
                if (summary.summaryId === asyncSummaryId.current) {
                    onLoadingSummary(false);
                }
            } catch (e) {
                onLoadingSummary(false);
            }
        },
        [asyncSummaryId, generateSummary, onLoadingSummary, onUpdateState],
    );

    const updateStateAndNotify = useCallback(
        (state: QueryTemplateBlockState) => {
            onUpdateState(state);
            if (state.timelineId) {
                generateSummaryAndNotify(state);
            }
        },
        [generateSummaryAndNotify, onUpdateState],
    );

    const updatePreset = useCallback(
        (p: PresetFilter) => {
            // Get the filters based on the selected preset and update the propertyFilterGroups with it
            const propertyFilterGroups = getFiltersForPreset(p);

            // Update the state and notify
            updateStateAndNotify({
                ...props.state,
                preset: p,
                title: props.state.title || getPresetTitle(p),
                propertyFilterGroups: propertyFilterGroups,
                filtersOperator: ActivityItemFilterOperator.Or,
            });
        },
        [props.state, updateStateAndNotify],
    );

    const getTimelineId = useCallback(() => {
        if (props.state.selectedScopes.length === 0) {
            return;
        }

        // No additional checks required because graphql will return cached data
        // if the array values are the same
        const items = [...props.state.selectedScopes];
        // stable sort-ish to aid with returning cached values
        items.sort((a, b) => a.id.localeCompare(b.id));
        props.onLoadingTimeline(true);
        getConnectorsScopesTimeline({
            variables: {
                scopes: items.map((item) => ({
                    connector: item.connector,
                    hierarchyType: item.hierarchyType,
                    id: item.id,
                    itemName: item.itemName,
                    itemUuid: item.itemUuid,
                })),
                fromDate: timelineDateSelectionToTimelineCreateDate(props.state.dateSelection),
                cacheSessionKey: props.sessionCacheId,
            },
            onCompleted: (data) => {
                const newState = { ...props.state, timelineId: data.timelineCreateFromScopes };
                props.onUpdateState(newState);
                generateSummaryAndNotify(newState);
                props.onLoadingTimeline(false);
            },
        });
    }, [generateSummaryAndNotify, getConnectorsScopesTimeline, props]);

    useEffect(() => {
        getTimelineId();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.state.selectedScopes, props.state.dateSelection]);

    useEffect(() => {
        generateSummaryAndNotify(props.state);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        props.state.groupSettings.groupType,
        props.state.summarizationCustomSettings,
        props.state.groupSettings.subgroupOrdering,
    ]);

    return (
        <TemplateBlockBaseComponent
            {...props}
            bodyComponent={
                <Box gap="xxsmall">
                    <Box direction="row" justify="between">
                        <Text
                            size="14px"
                            weight="bold"
                            color={Colors.dark_6}
                            style={{
                                fontFamily: FontFamily.Callout,
                                textTransform: 'uppercase',
                                letterSpacing: '2px',
                            }}
                        >
                            Input
                        </Text>
                        {createTimelineLoading && <Spinner size="xsmall" />}
                    </Box>
                    <TimeSelectionToolbarButton
                        onDateSelection={(d) => {
                            props.onUpdateState({ ...props.state, dateSelection: d });
                        }}
                        selection={props.state.dateSelection}
                        disabled={createTimelineLoading}
                    />
                    <SearchItemsToolbarButton
                        scopes={props.state.selectedScopes}
                        onScopesChange={(s) => {
                            const updatedState: TemplateBlockState = { ...props.state, selectedScopes: s };
                            if (
                                props.state.selectedScopes.length === 0 &&
                                s.find((ss) => ss.connector === ConnectorName.SLACK) !== undefined
                            ) {
                                updatedState.summarizationCustomSettings = getSummarySettingsForSummaryStyle(
                                    SummarizationStyle.Discussions,
                                );
                                updatedState.groupSettings = { groupType: ItemGroupType.Parent };
                            }
                            props.onUpdateState(updatedState);
                        }}
                    />
                    <ActivityPresetsButton
                        preset={props.state.preset}
                        timelineId={props.state.timelineId}
                        dataLoading={createTimelineLoading}
                        onPresetSelection={updatePreset}
                        onChangePropertyFilters={(f) =>
                            updateStateAndNotify({ ...props.state, propertyFilterGroups: f })
                        }
                        onChangeFilterOperator={(o) => updateStateAndNotify({ ...props.state, filtersOperator: o })}
                        propertyFilterGroups={props.state.propertyFilterGroups}
                        filtersOperator={props.state.filtersOperator}
                        selectedConnectorScopes={props.state.selectedScopes}
                        graphFilterType={props.state.graphFilterType}
                        onChangeGraphFilterType={(g) => updateStateAndNotify({ ...props.state, graphFilterType: g })}
                    />
                </Box>
            }
            headerComponent={
                <Box
                    border={{ color: Colors.border_dark, size: '1px' }}
                    background={Colors.background_back}
                    round="5px"
                    flex
                >
                    <HoverPlainTextInput
                        hoverBgColor={Colors.background_back}
                        inputProps={{ fontSize: '20px' }}
                        inputAttr={{
                            placeholder: 'Untitled Section',
                            onChange: (e) => {
                                props.onUpdateState({ ...props.state, title: e.target.value });
                            },
                            maxLength: 100,
                        }}
                        inputStyle={{
                            fontFamily: FontFamily.Callout,
                            fontSize: '16px',
                            background: 'unset',
                            padding: '2px 4px',
                            overflow: 'hidden',
                            resize: 'none',
                            width: '100%',
                        }}
                        disableEdit={dataLoading}
                        value={props.state.title}
                    />
                </Box>
            }
        />
    );
}

export function TemplateBlock(props: {
    sessionCacheId: string;
    state: TemplateBlockState;
    onUpdateState: (state: TemplateBlockState) => void;
    onDelete: () => void;
    setActiveBlock: (active: boolean) => void;
    onLoadingSummary: (loading: boolean) => void;
    onLoadingTimeline: (loading: boolean) => void;
    active: boolean;
}): JSX.Element {
    if (props.state.type === BlockType.Query) {
        return (
            <SummaryTemplateBlock
                sessionCacheId={props.sessionCacheId}
                state={props.state}
                onUpdateState={props.onUpdateState}
                onDelete={props.onDelete}
                setActiveBlock={props.setActiveBlock}
                onLoadingSummary={props.onLoadingSummary}
                onLoadingTimeline={props.onLoadingTimeline}
                active={props.active}
            />
        );
    }
    return (
        <TextTemplateBlock
            sessionCacheId={props.sessionCacheId}
            state={props.state}
            onUpdateState={props.onUpdateState}
            onDelete={props.onDelete}
            setActiveBlock={props.setActiveBlock}
            onLoadingSummary={props.onLoadingSummary}
            onLoadingTimeline={props.onLoadingTimeline}
            active={props.active}
        />
    );
}
