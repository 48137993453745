// Copyright 2021
// ThatWorks.xyz Limited

import { useMutation, useQuery } from '@apollo/client';
import { Colors } from '@thatworks/colors';
import { joinPagesPaths, Pages, QueryParams } from '@thatworks/shared-frontend/pages';
import { Box, Spinner, Text } from 'grommet';
import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BasicPage3, BasicPage3NavFunction, DefaultPagePaddingPixels } from '../../../../components/BasicPage3';
import { DeleteConfirmationModal } from '../../../../components/ConfirmationModal';
import { CtaButtonSpinnerV2 } from '../../../../components/CtaButtonSpinner';
import { PageTitle } from '../../../../components/PageTitle';
import { getLabel, getNavIcon, SidebarNav } from '../../../../components/SidebarNav';
import { useTelemetryContext } from '../../../../components/TelemetryContext';
import { useUserStateContext } from '../../../../components/UserContext';
import { withAuth } from '../../../../components/withAuth';
import { CreationMode } from '../magic-composer/components/MagicComposerBuilder';
import { DELETE_TEMPLATE, GET_TEMPLATES } from '../templates/components/template-queries';
import { SavedQueriesSummary } from './components/SavedQueriesSummary';

function SavedQueries(props: { onNav: BasicPage3NavFunction }): JSX.Element {
    const navigate = useNavigate();
    const { logger } = useTelemetryContext();
    const { postErrorMessage } = useUserStateContext();

    const { data, loading, refetch } = useQuery(GET_TEMPLATES, {
        variables: {
            withAutomations: false,
        },
        onError: (error) => {
            postErrorMessage({ title: 'Error', shortDesc: 'Failed to get saved queries' });
            logger.error(error.message);
        },
        fetchPolicy: 'no-cache',
    });

    const [templateToDelete, setTemplateToDelete] = useState<{ templateId: string; templateTitle: string } | undefined>(
        undefined,
    );
    const [deleteTemplate] = useMutation(DELETE_TEMPLATE, {
        onError: (error) => {
            postErrorMessage({ title: 'Error', shortDesc: 'Failed to delete saved queries' });
            logger.error(error.message);
        },
    });

    const goToMagicComposer = useCallback(
        async (templateId?: string) => {
            let path = `${joinPagesPaths([Pages.app.root, Pages.app.subs.create])}?${QueryParams.Mode}=${
                CreationMode.SavedQueries
            }`;
            navigate(templateId != null ? `${path}&edit=${templateId}` : path);
        },
        [navigate],
    );

    return (
        <BasicPage3
            activeNavButton={SidebarNav.SavedQueries}
            browserPageTitle={getLabel(SidebarNav.SavedQueries)}
            onNav={props.onNav}
        >
            <Box overflow={{ vertical: 'auto' }} height="100%" width="100%">
                <Box height={{ min: 'max-content' }}>
                    {/* Page title and create template button */}
                    <Box
                        pad={{
                            left: DefaultPagePaddingPixels,
                            right: DefaultPagePaddingPixels,
                            top: 'small',
                            bottom: 'xsmall',
                        }}
                        border={{ side: 'bottom', color: 'border' }}
                        direction="row"
                        align="center"
                        justify="between"
                        height={{ min: 'max-content' }}
                    >
                        <PageTitle icon={getNavIcon(SidebarNav.SavedQueries)}>
                            {getLabel(SidebarNav.SavedQueries)}
                        </PageTitle>
                        <Box direction="row" gap="xsmall">
                            <CtaButtonSpinnerV2
                                label="New Saved Query"
                                style={{ padding: '1px 14px' }}
                                onClick={goToMagicComposer}
                            />
                        </Box>
                    </Box>
                    <Box
                        pad={{
                            left: DefaultPagePaddingPixels,
                            right: DefaultPagePaddingPixels,
                            top: 'small',
                            bottom: 'small',
                        }}
                        gap="xxsmall"
                    >
                        {/* Spinner */}
                        {loading && <Spinner />}
                        {/* No templates available interaction */}
                        {data && data.workspacePostTemplates.length === 0 && !loading && (
                            <Box
                                pad="small"
                                background={{ color: Colors.background_back }}
                                round={'10px'}
                                direction="row"
                                gap="xxsmall"
                                align="center"
                                elevation="xsmall"
                                width="large"
                            >
                                <Text>No Saved Queries yet. Create a&nbsp;</Text>
                                <CtaButtonSpinnerV2
                                    label="New Saved Query"
                                    style={{ padding: '1px 14px' }}
                                    onClick={goToMagicComposer}
                                />
                            </Box>
                        )}
                        {/* Templates list */}
                        {data && (
                            <Box gap="xsmall">
                                {data.workspacePostTemplates.map((t) => (
                                    <SavedQueriesSummary
                                        key={t.id}
                                        template={t}
                                        onDeleteSavedQueries={() =>
                                            setTemplateToDelete({
                                                templateId: t.id,
                                                templateTitle: t.title,
                                            })
                                        }
                                        onUpdateSavedQueries={() => goToMagicComposer(t.id)}
                                    />
                                ))}
                            </Box>
                        )}
                    </Box>
                </Box>
            </Box>
            <DeleteConfirmationModal
                itemName={templateToDelete?.templateTitle || ''}
                onCancel={() => {}}
                onClose={() => setTemplateToDelete(undefined)}
                onConfirm={async () => {
                    if (templateToDelete) {
                        await deleteTemplate({
                            variables: { workspacePostTemplateDeleteId: templateToDelete.templateId },
                        });
                    }
                    await refetch();
                }}
                showModal={templateToDelete !== undefined}
            />
        </BasicPage3>
    );
}

export default withAuth(SavedQueries);
