// Copyright 2021
// ThatWorks.xyz Limited

import { Colors } from '@thatworks/colors';
import { InlineInsightPillAttributes, InlineInsightPillNode } from '@thatworks/shared-frontend/prosemirror-nodes';
import { Box, Drop, Image } from 'grommet';
import { FunctionComponent, useRef, useState } from 'react';
import { ConnectorIconSmall } from '../../../../../../components/ConnectorIcon';
import {
    BaseReactNodeComponentProps,
    BaseReactNodeInjected,
} from '../../../../../../components/prosemirror/BaseReactNode';
import { FontFamily } from '../../../../../../theme';
import { TaskOverview, TaskPreviewInfo } from './TaskPreviewNode';

export class InlineInsightPillNodeReact extends BaseReactNodeInjected<InlineInsightPillAttributes<TaskPreviewInfo>> {
    _injectedNode = new InlineInsightPillNode<TaskPreviewInfo>();

    ComponentToRender: FunctionComponent<BaseReactNodeComponentProps<InlineInsightPillAttributes<TaskPreviewInfo>>> = (
        props,
    ) => {
        const [modal, setModal] = useState(false);
        const ref = useRef<HTMLSpanElement>(null);

        return (
            <span
                ref={ref}
                onMouseEnter={() => setModal(true)}
                onMouseLeave={() => setModal(false)}
                style={{ cursor: 'default' }}
            >
                {props.currentAttributes.data && (
                    <span
                        style={{
                            backgroundColor: props.currentAttributes.data.color || Colors.light_6,
                            padding: '4px 4px',
                            borderRadius: '6px',
                            marginRight: '4px',
                            display: 'inline-flex',
                            alignItems: 'center',
                            fontSize: '14px',
                            marginBottom: '4px',
                            fontFamily: FontFamily.Mono,
                        }}
                    >
                        {props.currentAttributes.data.iconUrl && (
                            <Image
                                src={props.currentAttributes.data.iconUrl}
                                width={'14px'}
                                height={'14px'}
                                alt="icon"
                                style={{ alignSelf: 'flex-start', marginTop: '3px' }}
                                fallback={process.env.REACT_APP_ITEM_ICON_FALLBACK_URL}
                            />
                        )}
                        {!props.currentAttributes.data.iconUrl && props.currentAttributes.data.connector && (
                            <ConnectorIconSmall
                                name={props.currentAttributes.data.connector}
                                sizePixels="14px"
                                style={{ alignSelf: 'flex-start', marginTop: '3px' }}
                            />
                        )}
                        {(props.currentAttributes.data.iconUrl || props.currentAttributes.data.connector) && (
                            <span style={{ marginRight: '4px' }} />
                        )}
                        {props.currentAttributes.data.value}
                    </span>
                )}
                {modal && props.currentAttributes.data && props.currentAttributes.data.items.length > 0 && (
                    <Drop
                        target={ref}
                        onClickOutside={() => setModal(false)}
                        plain
                        align={{ top: 'bottom', left: 'left' }}
                        pad={{ top: 'xxsmall', horizontal: 'xsmall', bottom: 'xsmall' }}
                        width={{ max: '500px' }}
                        animation={{ type: 'fadeIn', duration: 100 }}
                    >
                        <Box
                            overflow={{ vertical: 'auto' }}
                            pad="xxsmall"
                            background={{ color: Colors.background_front }}
                            border={{ color: Colors.border_light }}
                            elevation="medium"
                            round="4px"
                        >
                            <Box height={{ min: 'max-content' }} gap="xxsmall">
                                {props.currentAttributes.data.items.map((item, i) => (
                                    <TaskOverview
                                        key={`overview-${i}`}
                                        item={item}
                                        cardColor={{ color: Colors.background_back, opacity: 1 }}
                                        borderColor={Colors.border_light}
                                    />
                                ))}
                            </Box>
                        </Box>
                    </Drop>
                )}
            </span>
        );
    };
}
