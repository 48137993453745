// Copyright 2021
// ThatWorks.xyz Limited

import { useMutation, useQuery } from '@apollo/client';
import { useAuth0 } from '@auth0/auth0-react';
import { getFormattedConnectorName } from '@thatworks/connector-api';
import { Gates } from '@thatworks/shared-frontend/metrics';
import { Box, Heading, Select, Spinner, Text, TextArea, TextInput } from 'grommet';
import { useState } from 'react';
import { useGate } from 'statsig-react';
import { gql } from '../../../../__generated__/gql';
import CtaButtonSpinner, { CtaButtonSpinnerV2 } from '../../../../components/CtaButtonSpinner';
import { useTelemetryContext } from '../../../../components/TelemetryContext';
import { ConnectorConnectedWithoutStatus, useUserStateContext } from '../../../../components/UserContext';

const GET_ORGS = gql(/* GraphQL */ `
    query GetOrgs {
        usersOrganizations {
            id
            displayName
        }
    }
`);

const CREATE_USERS = gql(/* GraphQL */ `
    mutation CreateUsers($users: [UserCreateInput!]!) {
        usersCreate(users: $users) {
            email
            error
        }
    }
`);

const CREATE_ORG = gql(/* GraphQL */ `
    mutation CreateOrg($orgNameIdentifer: String!, $orgDisplayName: String!) {
        usersCreateOrganization(orgNameIdentifer: $orgNameIdentifer, orgDisplayName: $orgDisplayName) {
            id
            displayName
        }
    }
`);

const TRIGGER_POLL = gql(/* GraphQL */ `
    mutation TriggerPoll($connector: String!, $connectorUserId: String!) {
        triggerPoll(connector: $connector, connectorUserId: $connectorUserId)
    }
`);

export default function InternalSettingsBody(): JSX.Element {
    const createUserFormGate = useGate(Gates.CreateUserForm);
    const { postErrorMessage, connectedConnectorsWithoutStatus } = useUserStateContext();
    const { logger } = useTelemetryContext();
    const [userEmail, setUserEmail] = useState('');
    const [userName, setUserName] = useState('');
    const { getAccessTokenSilently, getAccessTokenWithPopup } = useAuth0();
    const [apiToken, setApiToken] = useState<string | undefined>();

    const [createUsersResponse, setCreateUsersResponse] = useState<string[]>([]);
    const [orgId, setOrgId] = useState<string | undefined>();

    const [newOrgName, setNewOrgName] = useState<string | undefined>();
    const [newOrgIdentifier, setNewOrgIdentifier] = useState<string | undefined>();
    const [connectorToPoll, setConnectorToPoll] = useState<ConnectorConnectedWithoutStatus | undefined>();

    const { data, loading, refetch } = useQuery(GET_ORGS, {
        onError: (error) => {
            postErrorMessage({ title: 'Error', shortDesc: 'Failed to get organizations' });
            logger.error(error.message);
        },
    });

    const [createUsers] = useMutation(CREATE_USERS, {
        onError: (err) => {
            postErrorMessage({ title: `Error`, shortDesc: 'Failed to create users' });
            logger.error(err.message);
        },
    });

    const [createOrg] = useMutation(CREATE_ORG, {
        onError: (err) => {
            postErrorMessage({ title: `Error`, shortDesc: err.message });
            logger.error(err.message);
        },
    });

    const [triggerPoll] = useMutation(TRIGGER_POLL, {
        onError: (err) => {
            postErrorMessage({ title: `Error`, shortDesc: err.message });
            logger.error(err.message);
        },
    });

    return (
        <Box gap="small">
            {createUserFormGate.value && (
                <Box>
                    {/* <PromptTester /> */}
                    <Box>
                        <Heading level={3}>Create Users</Heading>
                        <Box gap="small">
                            {loading && <Spinner />}
                            {data && (
                                <Box width="large" gap="xxsmall">
                                    <TextInput
                                        placeholder="email e.g. abesh@thatworks.ai"
                                        value={userEmail}
                                        onChange={(e) => setUserEmail(e.target.value)}
                                    />
                                    <TextInput
                                        placeholder="name e.g. Abesh Thakur"
                                        value={userName}
                                        onChange={(e) => setUserName(e.target.value)}
                                    />
                                    <Select
                                        options={data.usersOrganizations}
                                        valueKey={'displayName'}
                                        placeholder="Select organization to add users to"
                                        onChange={(op) => setOrgId(op.value.id)}
                                    />
                                </Box>
                            )}
                            <Box width="small">
                                <CtaButtonSpinner
                                    label="Submit"
                                    disabled={!userEmail || !userName || !orgId}
                                    onClick={async () => {
                                        if (!userEmail || !userName || !orgId) {
                                            return;
                                        }

                                        const res = await createUsers({
                                            variables: {
                                                users: [{ email: userEmail, name: userName, organizationId: orgId }],
                                            },
                                        });

                                        if (res.data) {
                                            res.data.usersCreate.sort((a, b) => {
                                                if (a.error && !b.error) {
                                                    return 1;
                                                }
                                                if (!a.error && b.error) {
                                                    return -1;
                                                }
                                                return 0;
                                            });
                                            setCreateUsersResponse(
                                                res.data.usersCreate.map(
                                                    (u) => `${u.email}: ${u.error ? `❌ ${u.error}` : '✅'}`,
                                                ),
                                            );
                                        }
                                    }}
                                />
                            </Box>
                            {createUsersResponse.length > 0 && (
                                <Box>
                                    {createUsersResponse.map((u, ui) => (
                                        <Text size="small" key={`response-${ui}`}>
                                            {u}
                                        </Text>
                                    ))}
                                </Box>
                            )}
                        </Box>
                    </Box>
                    <Box>
                        <Heading level={3}>Create Organization</Heading>
                        <Box gap="small">
                            <Box width="large" gap="xxsmall">
                                <TextInput
                                    placeholder="Organization identifer WITH UNDERSCORES as a separator (e.g. that_works)"
                                    value={newOrgIdentifier}
                                    onChange={(event) => {
                                        const v = event.target.value;
                                        if (v.length === 0) {
                                            setNewOrgIdentifier(undefined);
                                            return;
                                        }

                                        // underscore separators only
                                        const reg = /^[a-zA-Z_]+$/g;
                                        if (reg.test(v)) {
                                            setNewOrgIdentifier(event.target.value);
                                        }
                                    }}
                                />
                                <TextInput
                                    placeholder="Organization Display Name (e.g. That Works)"
                                    value={newOrgName}
                                    onChange={(event) => setNewOrgName(event.target.value)}
                                />
                            </Box>

                            <Box width="small">
                                <CtaButtonSpinner
                                    label="Submit"
                                    disabled={!newOrgIdentifier || !newOrgName}
                                    onClick={async () => {
                                        if (!newOrgIdentifier || !newOrgName) {
                                            return;
                                        }

                                        await createOrg({
                                            variables: {
                                                orgDisplayName: newOrgName.trim(),
                                                orgNameIdentifer: newOrgIdentifier.trim(),
                                            },
                                        });
                                        await refetch();
                                    }}
                                />
                            </Box>
                        </Box>
                    </Box>
                    <Box gap="xsmall">
                        <Heading level={3}>API Access Token</Heading>
                        <CtaButtonSpinnerV2
                            label="Get Token"
                            onClick={async () => {
                                let token: string | undefined;
                                try {
                                    token = await getAccessTokenSilently({
                                        cacheMode: 'off',
                                        authorizationParams: {
                                            audience: 'https://api.thatworks.ai',
                                            scope: 'read:all write:all',
                                        },
                                    });
                                } catch (error) {
                                    if (
                                        error instanceof Error &&
                                        error.message.toLowerCase().includes('consent required')
                                    ) {
                                        token = await getAccessTokenWithPopup({
                                            cacheMode: 'off',
                                            authorizationParams: {
                                                audience: 'https://api.thatworks.ai',
                                                scope: 'read:all write:all',
                                            },
                                        });
                                    }
                                }
                                setApiToken(token);
                            }}
                            style={{ width: '150px' }}
                        />
                        {apiToken && (
                            <Box width="medium">
                                {' '}
                                <TextArea value={apiToken} />
                            </Box>
                        )}
                    </Box>
                    <Box gap="xsmall">
                        <Heading level={3}>Trigger Poll</Heading>
                        <Box width="medium" gap="xsmall">
                            <Select
                                options={connectedConnectorsWithoutStatus.map((c) => ({
                                    ...c,
                                    label: `${getFormattedConnectorName(c.connector)} | ${c.accountDisplayName}`,
                                }))}
                                valueKey={'label'}
                                placeholder="Select app"
                                onChange={(op) => {
                                    setConnectorToPoll(op.value);
                                }}
                            />
                            <Text size="small">
                                You can use this in prod but try using it in staging if you can. Just out of safety.
                            </Text>
                            <Box width="small">
                                <CtaButtonSpinner
                                    label="Trigger Poll"
                                    disabled={!connectorToPoll}
                                    onClick={async () => {
                                        if (!connectorToPoll) {
                                            return;
                                        }

                                        const res = await triggerPoll({
                                            variables: {
                                                connector: connectorToPoll.connector,
                                                connectorUserId: connectorToPoll.connectorUserId,
                                            },
                                        });

                                        if (res.data && res.data.triggerPoll) {
                                            postErrorMessage(
                                                { title: 'Success', shortDesc: 'Triggered, give it 2-3 mins' },
                                                'info',
                                            );
                                        }
                                    }}
                                />
                            </Box>
                        </Box>
                    </Box>
                </Box>
            )}
            <Box>
                <Heading level={3}>Build Info</Heading>
                <Text size="small">
                    Date:&nbsp;
                    {process.env.REACT_APP_WEBPACK_BUILD_DATE
                        ? new Date(process.env.REACT_APP_WEBPACK_BUILD_DATE).toLocaleString(undefined, {
                              day: '2-digit',
                              month: 'short',
                              year: 'numeric',
                              hour: '2-digit',
                              minute: '2-digit',
                              second: '2-digit',
                          })
                        : 'undefined'}
                    <br />
                    Version:&nbsp;
                    {process.env.REACT_APP_WEBPACK_VCS_HASH}
                </Text>
            </Box>
        </Box>
    );
}
