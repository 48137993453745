// Copyright 2021
// ThatWorks.xyz Limited

import { useQuery } from '@apollo/client';
import { useAuth0 } from '@auth0/auth0-react';
import { ConnectorName, getFormattedConnectorName } from '@thatworks/connector-api';
import {
    CustomTokenClaimKeys,
    parseCustomTokenClaim,
    TokenClaimUserMetadata,
} from '@thatworks/shared-frontend/token-claims';
import auth0 from 'auth0-js';
import { Anchor, Box, Notification, StatusType, Text } from 'grommet';
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { gql } from '../__generated__';
import { ConnectorConnectedState, ConnectorPermissionStatus, UserState as UserStateQl } from '../__generated__/graphql';
import Api from '../shared/Api';
import { AUTH0_SCOPES } from '../shared/auth0-props';
import { useNavNoRerender } from '../shared/UseNavNoRerender';
import useCheckForBuildUpdate from './CheckForBuildUpdate';
import { CriticalPageErrorToast, ErrorMessage, GenericErrorToast, ServerCannotConnectMessage } from './ErrorToast';
import { useTelemetryContext } from './TelemetryContext';

interface ProblematicConnectorsError {
    message: string;
    ctaLabel: string;
    connectors: { name: ConnectorName; connectorUserId: string }[];
}

export type ConnectorConnectedWithoutStatus = Omit<ConnectorConnectedState, 'status'>;

export interface SlackTeamUserState {
    teamId: string;
    teamName: string;
    channels: { id: string; name: string; private: boolean }[];
}

export interface UserAppState {
    problematicConnectorsError: ProblematicConnectorsError | undefined;
    userState: UserStateQl | undefined;
    stateContextInitialized: boolean;
    userEmailDomain: string | undefined;
    userMetadata: TokenClaimUserMetadata;
    organizationDisplayName: string | undefined;
    organizationId: string | undefined;

    // A copy of the subset of userState for cases (E.g. digest creation) where only a subset of data is required
    // and those components should avoid being re-rendered when other params in userState are updated
    connectedConnectorsWithoutStatus: ConnectorConnectedWithoutStatus[];

    pollState: (poll: boolean) => void;

    patchUserMetadata: (accessToken: string, newMetadata: TokenClaimUserMetadata) => Promise<void>;

    _errorMessage: { messg: ErrorMessage; type?: StatusType } | undefined;
    postErrorMessage: (msg: ErrorMessage | undefined, type?: StatusType) => void;
}

const defaultUserAppState: UserAppState = {
    problematicConnectorsError: undefined,
    stateContextInitialized: false,
    userState: undefined,
    connectedConnectorsWithoutStatus: [],
    userEmailDomain: undefined,
    userMetadata: {},
    organizationDisplayName: undefined,
    organizationId: undefined,

    pollState: () => {
        throw new Error(`Unimplemented`);
    },

    patchUserMetadata: () => {
        throw new Error(`Unimplemented`);
    },

    _errorMessage: undefined,
    postErrorMessage: () => {
        throw new Error('Not implemented');
    },
};
export const UserStateContext = createContext<UserAppState>(defaultUserAppState);
export const useUserStateContext = () => useContext(UserStateContext);

function getErrorMessageForProblematicConnectors(
    connectors: { connector: ConnectorName; connectorUserId: string }[],
): ProblematicConnectorsError | undefined {
    if (connectors.length === 0) {
        return undefined;
    }

    const allNames = connectors.map((v) => getFormattedConnectorName(v.connector));
    let messg = '';
    if (allNames.length === 1) {
        messg = `${allNames[0]} needs to be reconnected.`;
    } else if (allNames.length === 2) {
        messg = `${allNames[0]} and ${allNames[1]} need to be reconnected.`;
    } else if (allNames.length === 3) {
        messg = `${allNames[0]}, ${allNames[1]}, ${allNames[2]} need to be reconnected.`;
    } else if (allNames.length > 3) {
        messg = `${allNames[0]}, ${allNames[1]}, and more need to be reconnected.`;
    }
    return {
        message: `Data may be out of date: ${messg}`,
        ctaLabel: 'Fix now',
        connectors: connectors.map((v) => {
            return {
                connectorUserId: v.connectorUserId,
                name: v.connector,
            };
        }),
    };
}

const GET_USER_STATE = gql(/* GraphQL */ `
    query GetUserState {
        userState {
            id
            connectedConnectors {
                connector
                connectorUserId
                accountDisplayName
                config {
                    preAuthUserInput {
                        description
                        field {
                            arrayValues
                            label
                            maxLength
                            validatorRegExp
                            validationFailureErrorMessage
                            isBasicAuth
                        }
                        fields {
                            type
                            label
                            key
                            description
                            value
                            canEdit
                        }
                    }
                }
                status {
                    permissions
                    data
                }
                linked {
                    name
                    connected
                }
                connectedByDisplayName
                isConnectedByUser
                firstPollProgress
                exampleDataSearchQueries
            }
            availableConnectors {
                connector
                linked
                config {
                    preAuthUserInput {
                        description
                        field {
                            arrayValues
                            label
                            maxLength
                            validatorRegExp
                            validationFailureErrorMessage
                            isBasicAuth
                        }
                        fields {
                            type
                            key
                            label
                            description
                            value
                            canEdit
                        }
                    }
                }
            }
            connectedOrgWideConnectors {
                connector
                accountDisplayName
                permissionStatus
                description
                deletionInstructions
                connectorUserId
            }
        }
    }
`);

export function UserStateProvider(props: {
    children: React.ReactNode;
    auth0: { domain: string; audience: string };
}): JSX.Element {
    const { getIdTokenClaims, isAuthenticated } = useAuth0();

    const [userEmailDomain, setUserEmailDomain] = useState<string | undefined>();
    const [userMetadata, setUserMetadata] = useState<TokenClaimUserMetadata>({});
    const [organizationDisplayName, setOrganizationDisplayName] = useState<string | undefined>();
    const [organizationId, setOrganizationId] = useState<string | undefined>();

    const { data, startPolling, stopPolling } = useQuery(GET_USER_STATE, {
        onError: (error) => {
            postErrorMessage({ title: 'Error', shortDesc: 'Failed to get state' });
            logger.error(error.message);
        },
        // to ensure onCompleted is called with every poll
        notifyOnNetworkStatusChange: true,
        onCompleted: (d) => {
            const problematicConnectors = d.userState.connectedConnectors.filter(
                (v) => v.status.permissions !== ConnectorPermissionStatus.Ok,
            );

            setProblematicConnectorsError(
                getErrorMessageForProblematicConnectors(
                    problematicConnectors.map((v) => ({
                        connector: v.connector as ConnectorName,
                        connectorUserId: v.connectorUserId,
                    })),
                ),
            );

            setConnectedConnectorsWithoutStatus(d.userState.connectedConnectors);
            setStateContextInitialized(true);
        },
    });

    const [stateContextInitialized, setStateContextInitialized] = useState(false);
    const [connectedConnectorsWithoutStatus, setConnectedConnectorsWithoutStatus] = useState<
        ConnectorConnectedWithoutStatus[]
    >([]);

    const [problematicConnectorsError, setProblematicConnectorsError] = useState<ProblematicConnectorsError>();
    const [criticalErrorMessage, setErrorMessage] = useState<ErrorMessage | undefined>(undefined);
    const { logger } = useTelemetryContext();
    const navigate = useNavNoRerender();

    const [errorMsg, setErrorMsg] = useState<{ messg: ErrorMessage; type?: StatusType } | undefined>();

    const { isUpdateAvailable } = useCheckForBuildUpdate();

    const patchUserMetadata = useCallback(
        async (accessToken: string, newMetadata: TokenClaimUserMetadata): Promise<void> => {
            if (!data) {
                logger.error(`Tried to update user metadata but failed because userState is invalid`);
                return;
            }
            const userProfile = await new Promise<auth0.Auth0UserProfile>((resolve, reject) => {
                const mgmt = new auth0.Management({
                    domain: props.auth0.domain,
                    audience: props.auth0.audience,
                    token: accessToken,
                    scope: AUTH0_SCOPES,
                });
                mgmt.patchUserMetadata(data.userState.id, newMetadata, (error, res) => {
                    if (error) {
                        return reject(error);
                    }
                    return resolve(res);
                });
            });
            setUserMetadata(userProfile.user_metadata);
        },
        [data, logger, props.auth0.audience, props.auth0.domain],
    );

    const pollState = useCallback(
        (poll: boolean) => {
            if (poll) {
                startPolling(2000);
            } else {
                stopPolling();
            }
        },
        [startPolling, stopPolling],
    );

    useEffect(() => {
        (async () => {
            if (!isAuthenticated) {
                // Might return early if auth still needs to be kicked off
                return;
            }
            try {
                const [claims] = await Promise.all([getIdTokenClaims()]);
                if (claims) {
                    setUserEmailDomain(
                        parseCustomTokenClaim<string>(claims, CustomTokenClaimKeys.EmailDomain) || undefined,
                    );
                    setUserMetadata(
                        parseCustomTokenClaim<TokenClaimUserMetadata>(claims, CustomTokenClaimKeys.UserMetadata) || {},
                    );
                    setOrganizationDisplayName(
                        parseCustomTokenClaim<string>(claims, CustomTokenClaimKeys.OrganizationDisplayName),
                    );
                    setOrganizationId(parseCustomTokenClaim<string>(claims, CustomTokenClaimKeys.OrganizationId));
                }
            } catch (error) {
                Api.handleException(error, logger, navigate);
                setErrorMessage(ServerCannotConnectMessage);
            }
        })();
    }, [getIdTokenClaims, isAuthenticated, logger, navigate]);

    //---

    const postErrorMessage = useCallback((msg: ErrorMessage | undefined, type?: StatusType) => {
        setErrorMsg(msg ? { messg: msg, type: type || 'critical' } : undefined);
    }, []);

    const contextValue = useMemo(() => {
        return {
            problematicConnectorsError,
            userState: data?.userState,
            stateContextInitialized,
            connectedConnectorsWithoutStatus,
            userEmailDomain,
            userMetadata,
            organizationDisplayName,
            organizationId,

            _errorMessage: errorMsg,
            postErrorMessage,

            pollState,

            patchUserMetadata,
        };
    }, [
        problematicConnectorsError,
        data,
        stateContextInitialized,
        connectedConnectorsWithoutStatus,
        userEmailDomain,
        userMetadata,
        organizationDisplayName,
        organizationId,

        pollState,

        patchUserMetadata,

        errorMsg,
        postErrorMessage,
    ]);

    return (
        <UserStateContext.Provider value={contextValue}>
            {isUpdateAvailable && (
                <Notification
                    title={'New version available'}
                    message={
                        <Box direction="row" wrap>
                            <Text wordBreak="break-word">
                                <Anchor onClick={() => window.location.reload()}>Refresh</Anchor> to get the latest
                                version.
                            </Text>
                        </Box>
                    }
                    status="info"
                    toast={{ autoClose: false, position: 'bottom-right' }}
                />
            )}
            {criticalErrorMessage && <CriticalPageErrorToast error={criticalErrorMessage} />}
            {errorMsg && (
                <GenericErrorToast
                    error={errorMsg.messg}
                    status={errorMsg.type}
                    onClose={() => postErrorMessage(undefined)}
                />
            )}
            {props.children}
        </UserStateContext.Provider>
    );
}
