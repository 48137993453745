// Copyright 2021
// ThatWorks.xyz Limited

import { Colors as TwColors } from '@thatworks/colors';
import { base, ThemeType } from 'grommet';
import { Colors } from 'grommet/themes/base';
import { deepMerge } from 'grommet/utils';
import { css } from 'styled-components';

const edgeSmall = 24;

export enum FontFamily {
    Standard = 'iAWriterQuattro',
    Mono = 'iAWriterMono',
    Heading = 'iAWriterDuoS',
    Callout = 'GintoNord',
}

const colors: Colors = {
    brand: TwColors.brand as string,
    'accent-1': TwColors.accent_1 as string,
    'accent-2': TwColors.accent_2 as string,
    'accent-3': TwColors.accent_3 as string,
    'accent-4': TwColors.accent_4 as string,
    'neutral-1': TwColors.neutral_1 as string,
    'neutral-2': TwColors.neutral_2 as string,
    'neutral-3': TwColors.neutral_3 as string,
    'neutral-4': TwColors.neutral_4 as string,
    'status-critical': TwColors.status_critical as string,
    'status-error': TwColors.status_error as string,
    'status-warning': TwColors.status_warning as string,
    'status-ok': TwColors.status_ok as string,
    'status-unknown': TwColors.status_unknown as string,
    'status-disabled': TwColors.status_disabled as string,
    'light-1': TwColors.light_1 as string,
    'light-2': TwColors.light_2 as string,
    'light-3': TwColors.light_3 as string,
    'light-4': TwColors.light_4 as string,
    'light-5': TwColors.light_5 as string,
    'light-6': TwColors.light_6 as string,
    'dark-1': TwColors.dark_1 as string,
    'dark-2': TwColors.dark_2 as string,
    'dark-3': TwColors.dark_3 as string,
    'dark-4': TwColors.dark_4 as string,
    'dark-5': TwColors.dark_5 as string,
    'dark-6': TwColors.dark_6 as string,
    'background-back': TwColors.background_back as string,
    'background-front': TwColors.background_front as string,
    black: TwColors.black as string,
    white: TwColors.white as string,
    focus: 'brand',
    border: {
        light: TwColors.border_light as string,
        dark: TwColors.border_dark as string,
    },
};

const custom: ThemeType = {
    global: {
        colors: colors,
        font: {
            family: FontFamily.Standard,
            variant: 'regular',
            size: '14px',
            height: '20px',
        },
        focus: {
            shadow: {
                size: '0px',
            },
        },
        control: {
            border: {
                radius: '8px',
            },
        },
        edgeSize: {
            xxsmall: `${edgeSmall / 4}px`,
            xsmall: `${edgeSmall / 1.5}px`,
            small: `${edgeSmall}px`,
            medium: `${edgeSmall * 2.5}px`,
            large: `${edgeSmall * 4}px`,
            xlarge: `${edgeSmall * 8}px`,
        },
        breakpoints: {
            small: {
                value: edgeSmall * 32, // 768
                borderSize: {
                    xsmall: '1px',
                    small: '2px',
                    medium: `${edgeSmall / 6}px`, // 4
                    large: `${edgeSmall / 4}px`, // 6
                    xlarge: `${edgeSmall / 2}px`, // 12
                },
                edgeSize: {
                    none: '0px',
                    hair: '1px', // for Chart
                    xxsmall: `${edgeSmall / 4}px`,
                    xsmall: `${edgeSmall / 1.5}px`,
                    small: `${edgeSmall}px`,
                    medium: `${edgeSmall * 2.5}px`,
                    large: `${edgeSmall * 4}px`,
                    xlarge: `${edgeSmall * 8}px`,
                },
                size: {
                    xxsmall: `${edgeSmall}px`, // 24
                    xsmall: `${edgeSmall * 2}px`, // 48
                    small: `${edgeSmall * 4}px`, // 96
                    medium: `${edgeSmall * 8}px`, // 192
                    large: `${edgeSmall * 16}px`, // 384
                    xlarge: `${edgeSmall * 32}px`, // 768
                    full: '100%',
                },
            },
        },
    },
    heading: {
        color: 'black',
        level: {
            '2': {
                font: {
                    weight: 500,
                },
                medium: {
                    size: '32px',
                },
            },
        },
    },
    card: {
        container: {
            background: 'background-front',
            elevation: 'xsmall',
            round: 'xsmall',
        },
    },
    tab: {
        active: {
            color: 'brand',
        },
        color: 'dark-4',
        border: {
            color: 'dark-6',
            active: {
                color: 'brand',
            },
        },
        margin: {
            left: '0px',
        },
    },
    button: {
        border: {
            radius: '8px',
        },
        extend: (p) => {
            if (p.primary) {
                return `font-family: ${FontFamily.Mono};
                font-size: 16px; &:hover { box-shadow: 0px 0px 0px 2px ${colors['accent-3']}; }`;
            }
            return `font-family: ${FontFamily.Mono};
            font-size: 16px;`;
        },
        primary: {
            color: 'accent-3',
            extend: css`
                border-color: ${colors['accent-3']};
            `,
        },
        gap: 'xsmall',
    },
    spinner: {
        container: {
            pad: 'xxsmall',
        },
    },
    notification: {
        title: {
            color: 'black',
        },
        message: {
            color: 'black',
        },
        toast: {
            container: { pad: 'small' },
            time: 4000,
        },
        critical: {
            background: { color: 'status-error' },
        },
    },
    anchor: {
        extend: css`
            border-bottom-width: 1px;
            border-bottom-style: solid;
            border-bottom-color: ${colors['accent-3']};
        `,
        textDecoration: 'none',
        hover: {
            textDecoration: 'none',
            extend: css`
                border-bottom-width: 2px;
                margin-bottom: -1px;
            `,
        },
    },
    checkBox: {
        gap: '10px',
        size: '20px',
    },
    radioButton: {
        gap: 'xxsmall',
        size: '15px',
    },
    tip: {
        content: {
            border: { color: 'light-4' },
            background: 'background-front',
            round: '4px',
            width: 'max-content',
            pad: 'xxsmall',
        },
        drop: {
            margin: 'xsmall',
        },
    },
    rangeInput: {
        thumb: {
            extend: css`
                width: 15px;
                height: 15px;
                top: 100%;
            `,
        },
    },
};

const theme = deepMerge(base, custom);

export function getThemeColors() {
    return colors;
}

export function getGrommetTheme() {
    return theme;
}
