// Copyright 2021
// ThatWorks.xyz Limited

import { LinkExtension, TableExtension, wysiwygPreset } from 'remirror/extensions';
import styled from 'styled-components';

export const ProseWrapper = styled.div`
    .ProseMirror:focus {
        outline: none;
    }
    .remirror-is-empty:first-of-type::before {
        position: absolute;
        color: #aaa;
        pointer-events: none;
        content: attr(data-placeholder);
    }
    .placeholder-fixed-width:first-of-type::after {
        position: relative;
        color: #aaa;
        pointer-events: none;
        content: attr(data-placeholder);
        top: -20px;
    }
    .ProseMirror table {
        border-collapse: collapse;
        width: 100%;
        margin-top: 8px;
        border-bottom: 1px solid #f2eeeb;
    }
    .ProseMirror th,
    .ProseMirror td {
        padding-right: 4px;
        padding-bottom: 0px;
        text-align: left;
        vertical-align: top;
    }
`;

export function getDefaultProseMirrorExtensions() {
    // remove the link extension so we can manually add it with custom settings
    // because the wysiwygPreset function does not forward all settings to the link extension
    const extensions = wysiwygPreset().filter((ext) => ext.name !== 'link');
    return [...extensions, new TableExtension({}), new LinkExtension({ autoLink: true, defaultTarget: '_blank' })];
}
