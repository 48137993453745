// Copyright 2021
// ThatWorks.xyz Limited

import { Colors } from '@thatworks/colors';
import { ConnectorName } from '@thatworks/connector-api';
import { ItemPropertyType } from '@thatworks/shared-backend/activity-filters';
import { ChartData, ChartOptions } from 'chart.js/auto';
import { DateTime } from 'luxon';

export type FrontendInsightTimeSeriesDataValue = number | string;

export interface InsightResultProperty {
    type: InsightPropertyType;
    name: string;
    value: string;
    itemPropertyType?: ItemPropertyType;
}

export interface InsightTimeSeriesDataDiplayProps {
    type: 'percentage' | 'number' | 'yyyy-MM-dd' | 'yyyyMMdd' | 'string' | 'date-iso' | 'none' | 'ms-epoch';
    chart: 'area' | 'line' | 'bar' | 'point';
    connector?: ConnectorName;
}

export enum InsightResultType {
    Ratio = 'ratio',
    Progress = 'progress',
    LabelNumberList = 'label_number_list',
    MultiProgress = 'multi_progress',
    Number = 'number',
    Label = 'label',
    TimeSeries = 'time_series',
    Table = 'table',
}

export enum InsightPropertyType {
    Date = 'date',
}

export interface ChartLegend {
    label: string;
    color: string;
    connector: ConnectorName | undefined;
    displayType: InsightTimeSeriesDataDiplayProps['type'];
}

export interface InsightResultTimeSeries {
    type: InsightResultType.TimeSeries;
    title?: string;
    subtitle?: { text: string; type: 'plain' | 'info' };
    id?: string;
    series: Array<{
        [key: string]: FrontendInsightTimeSeriesDataValue;
    }>;
    yAxisProps: {
        [key: string]: InsightTimeSeriesDataDiplayProps & {
            title: string;
            hide?: boolean;
            fillMode?: number | string;
            color?: string;
            grace?: string;
        };
    };
    xAxisProps: { fieldName: string; props: InsightTimeSeriesDataDiplayProps; maxMumTicks?: number };
    axisGuides: { showX: boolean; showY: boolean };
    showHoverLegend: boolean;
    itemUuids: string[] | undefined;
    properties: InsightResultProperty[];
}

export function getDateTimeFromDisplayType(
    v: string | number,
    displayType: InsightTimeSeriesDataDiplayProps['type'],
): DateTime {
    switch (displayType) {
        case 'yyyy-MM-dd':
        case 'yyyyMMdd':
            return DateTime.fromFormat(v.toString(), displayType);
        case 'date-iso':
            return DateTime.fromISO(v.toString());
        case 'ms-epoch':
            return DateTime.fromMillis(Number(v));
        default:
            throw new Error(`${displayType} is not a date type`);
    }
}

export function roundToDecimalsIfHasDecimalPlaces(value: number, numDecimalPlaces: number): string {
    if (value % 1 !== 0) {
        return value.toFixed(numDecimalPlaces);
    } else {
        return value.toString();
    }
}

export function formatAxis(
    v: string,
    displayType: InsightTimeSeriesDataDiplayProps['type'],
    dateFormatOveride?: string,
): string {
    if (displayType === 'yyyy-MM-dd' || displayType === 'date-iso' || displayType === 'yyyyMMdd') {
        const d = getDateTimeFromDisplayType(v, displayType);
        if (dateFormatOveride) {
            return d.toFormat(dateFormatOveride);
        }
        return d.toLocaleString();
    } else if (displayType === 'percentage') {
        return `${roundToDecimalsIfHasDecimalPlaces(Number(v), 2)}%`;
    } else if (displayType === 'none') {
        return '';
    } else {
        return v;
    }
}

export function roundIfRequired(num: number) {
    return Math.round(num * 100) / 100;
}

export function getOptions<TType extends 'bar' | 'line'>(
    insight: InsightResultTimeSeries,
    includeXAxisTickRenderer?: boolean,
): ChartOptions<TType> {
    let yAxisPropType: InsightTimeSeriesDataDiplayProps | undefined;
    let grace: string | undefined;
    const allYAxis = Object.keys(insight.yAxisProps);
    if (allYAxis.length > 0) {
        yAxisPropType = insight.yAxisProps[allYAxis[0]];
        grace = insight.yAxisProps[allYAxis[0]].grace;
    }
    const xAxisPropType = insight.xAxisProps.props.type;

    const r: ChartOptions<'bar'> | ChartOptions<'line'> = {
        interaction: {
            intersect: false,
            mode: 'index',
        },
        plugins: {
            legend: {
                position: 'bottom',
                labels: {
                    usePointStyle: true,
                    boxWidth: 8,
                    boxHeight: 8,
                },
                display: false,
            },
        },
        scales: {
            x: {
                grid: {
                    display: true,
                    drawTicks: true,
                    drawOnChartArea: false,
                },
                display: insight.axisGuides.showX,
                ticks: includeXAxisTickRenderer
                    ? {
                          callback: function (value) {
                              // Default for email/backend. This will be overriden by the frontend to match user locale settings
                              return formatAxis(this.getLabelForValue(value as number), xAxisPropType, 'dd-LLL');
                          },
                          maxTicksLimit: insight.xAxisProps.maxMumTicks,
                      }
                    : {
                          maxTicksLimit: insight.xAxisProps.maxMumTicks,
                      },
                type: xAxisPropType === 'ms-epoch' ? 'time' : undefined,
                time:
                    xAxisPropType === 'ms-epoch'
                        ? {
                              minUnit: 'day',
                          }
                        : undefined,
            },
            y: {
                border: {
                    dash: [4, 8],
                },
                display: insight.axisGuides.showY,
                ticks: {
                    callback: function (value) {
                        if (!yAxisPropType) {
                            return value;
                        }
                        return formatAxis(value.toString(), yAxisPropType.type);
                    },
                },
                grace: grace || '10%',
            },
        },
    };
    return r as ChartOptions<TType>;
}
export function getData<TType extends 'bar' | 'line'>(
    insight: InsightResultTimeSeries,
): {
    chartData: ChartData<TType, number[], string>;
    legend: ChartLegend[];
    xAxis?: { displayType: InsightTimeSeriesDataDiplayProps['type'] };
} {
    const dataMap: Map<
        string,
        {
            data: number[];
            title: string;
            connector?: ConnectorName;
            color: string;
            displayType: InsightTimeSeriesDataDiplayProps['type'];
            hide?: boolean;
            fillMode?: string | number;
        }
    > = new Map();
    insight.series.forEach((v) => {
        Object.entries(v).forEach(([k, v]) => {
            if (k === insight.xAxisProps.fieldName) {
                return;
            }

            const found = dataMap.get(k);
            if (found) {
                found.data.push(roundIfRequired(Number(v)));
            } else {
                dataMap.set(k, {
                    data: [roundIfRequired(Number(v))],
                    title: insight.yAxisProps[k].title,
                    connector: insight.yAxisProps[k].connector,
                    color: insight.yAxisProps[k].color || '',
                    displayType: insight.yAxisProps[k].type,
                    fillMode: insight.yAxisProps[k].fillMode,
                    hide: insight.yAxisProps[k].hide,
                });
            }
        });
    });

    const r: ChartData<'bar', number[], string | number> | ChartData<'line', number[], string | number> = {
        labels: insight.series.map((s) => {
            return s[insight.xAxisProps.fieldName];
        }),
        datasets: Array.from(dataMap).map(([, v], dataIdx) => {
            const colors = [
                Colors.neutral_1,
                Colors.neutral_2,
                Colors.dark_2,
                Colors.accent_3,
                Colors.dark_6,
                Colors.status_error,
                Colors.status_warning,
            ];
            v.color = v.color || (colors[dataIdx % colors.length] as string);
            return {
                label: v.title,
                data: v.data,
                borderColor: v.color,
                backgroundColor: v.color,
                pointStyle: false,
                tension: 0.1,
                fill: v.fillMode,
                hidden: v.hide,
            };
        }),
    };
    return {
        chartData: r as ChartData<TType, number[], string>,
        legend: Array.from(dataMap.values()).map((v) => {
            return {
                label: v.title,
                color: v.color,
                connector: v.connector,
                displayType: v.displayType,
            };
        }),
        xAxis: { displayType: insight.xAxisProps.props.type },
    };
}

export interface LinearBarChartProps {
    options: ReturnType<typeof getOptions>;
    data: ReturnType<typeof getData>;
    type: 'bar' | 'line';
}
