// Copyright 2021
// ThatWorks.xyz Limited

import {
    EditorComponent,
    OnChangeJSON,
    PlaceholderExtension,
    Remirror,
    useActive,
    useCommands,
    useHelpers,
    useRemirror,
} from '@remirror/react';
import { Colors } from '@thatworks/colors';
import { Box, Text } from 'grommet';
import { Bold, Italic, Underline } from 'grommet-icons';
import { useCallback } from 'react';
import { InvalidContentHandler } from 'remirror';
import { MarkdownExtension } from 'remirror/extensions';
import { getDefaultProseMirrorExtensions, ProseWrapper } from '../pages/app/pages/ws/ProseWrapper';
import { LinkToolbarButton } from './prosemirror/LinkToolbarButton';
import { ToolbarButton } from './prosemirror/ToolbarButton';

function ToMarkdown(props: { onChange: (content: string) => void }): JSX.Element {
    const { getMarkdown } = useHelpers();
    return (
        <OnChangeJSON
            onChange={() => {
                props.onChange(getMarkdown());
            }}
        />
    );
}

function Toolbar(): JSX.Element {
    const { toggleBold, toggleItalic, toggleUnderline } = useCommands();
    const { bold, italic, underline } = useActive();

    return (
        <Box pad={{ vertical: '4px', horizontal: 'xxsmall' }} direction="row" align="center" gap="2px">
            <ToolbarButton
                icon={Bold}
                onClick={async () => {
                    toggleBold();
                }}
                active={bold()}
                iconSize="14px"
                boxProps={{ pad: '4px', border: { color: bold() ? Colors.accent_3 : Colors.border_dark } }}
            />
            <ToolbarButton
                icon={Italic}
                onClick={async () => {
                    toggleItalic();
                }}
                active={italic()}
                iconSize="14px"
                boxProps={{ pad: '4px', border: { color: italic() ? Colors.accent_3 : Colors.border_dark } }}
            />
            <ToolbarButton
                icon={Underline}
                onClick={async () => {
                    toggleUnderline();
                }}
                active={underline()}
                iconSize="14px"
                boxProps={{ pad: '4px', border: { color: underline() ? Colors.accent_3 : Colors.border_dark } }}
            />
            <Text size="12px" color={Colors.dark_6}>
                •
            </Text>
            <LinkToolbarButton />
        </Box>
    );
}

export function TextEditorMarkdown(props: {
    markdownContent: string | undefined;
    placeholder: string;
    onChange: (markdownContent: string) => void;
}) {
    const onError: InvalidContentHandler = useCallback(({ json, invalidContent, transformers }) => {
        // Automatically remove all invalid nodes and marks.
        return transformers.remove(json, invalidContent);
    }, []);

    const { manager, state } = useRemirror({
        extensions: () => [
            ...getDefaultProseMirrorExtensions(),
            new MarkdownExtension({}),
            new PlaceholderExtension({ placeholder: props.placeholder, emptyNodeClass: 'placeholder-fixed-width' }),
        ],
        content: props.markdownContent,
        stringHandler: 'markdown',
        onError,
    });

    return (
        <ProseWrapper>
            <Remirror manager={manager} initialContent={state}>
                <Box border={{ color: Colors.border_dark, size: '1px' }} round="5px">
                    <Toolbar />
                    <Box
                        background={Colors.background_back}
                        pad={{ horizontal: 'xxsmall' }}
                        style={{ borderRadius: '0px 0px 5px 5px' }}
                        flex
                    >
                        <EditorComponent />
                    </Box>
                </Box>
                <ToMarkdown onChange={props.onChange} />
            </Remirror>
        </ProseWrapper>
    );
}
